import { Grid2, List, Popover, Typography } from "@mui/material";
import { useMemo } from "react";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { getIcon } from "../../fields/FieldItem.helper";

interface Props {
  dimensions: DimensionDescriptor[];
  anchorEl: HTMLElement | null;
  onClose: (field?: DimensionDescriptor) => void;
}
export default function CustomMeasureDimensionSelection(props: Props) {
  const { dimensions, anchorEl, onClose } = props;
  const maxHeight = useMemo(() => getContainerHeight(dimensions), [dimensions]);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
            maxHeight: maxHeight,
            overflow: "hidden",
          },
        },
      }}
    >
      <Grid2
        sx={(theme) => ({
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "1rem",
          height: maxHeight,
          width: "100%",
          "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.light,
          },
        })}
      >
        <Grid2 container sx={{ height: "100%", width: "100%", overflow: "auto", px: 1 }}>
          <List sx={{ width: "100%" }}>
            {dimensions.map((item) => (
              <MenuItemStyled key={`${item.name}`} sx={{ pl: 1, gap: 1.5 }} onClick={() => onClose(item)}>
                {getIcon(item)}
                <Typography>{item.caption}</Typography>
              </MenuItemStyled>
            ))}
          </List>
        </Grid2>
      </Grid2>
    </Popover>
  );
}

function getContainerHeight(dimensions: DimensionDescriptor[]) {
  const listYasixPaddingsSum = 16;
  const listItemsHeight = 32;
  const maxAvailableItemsNumber = 4;
  return `${Math.min(dimensions.length, maxAvailableItemsNumber) * listItemsHeight + listYasixPaddingsSum}px`;
}
