import { Divider, Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../hooks/useLocalization";
import CheckboxField from "../../../common/CheckboxField";
import SwitchField from "../../../common/SwitchField";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import GroupSummaryPlace from "./GroupSummaryPlace";

export default function GeneralSettings() {
  const { settingsArea } = useFieldsStateContext();
  const { tabular: locale } = useLocalization();
  const hideBlankRows = settingsArea.settings.hideBlankRows === true;

  return (
    <Stack pt={1} spacing={1.5}>
      <Stack spacing={1.5}>
        <Typography variant="subtitle1">General</Typography>
        <Stack>
          <SwitchField
            label="Show Grouped Columns"
            checked={settingsArea.settings.showGroupedColumns === true}
            onChange={(value) => settingsArea.update({ showGroupedColumns: value })}
          />
          <SwitchField
            label="Hide Blank Rows"
            checked={hideBlankRows}
            onChange={(hideBlankRows) => {
              const resetHideZeroRows = hideBlankRows === false ? false : settingsArea.settings.hideZeroRows;
              settingsArea.update({ hideBlankRows, hideZeroRows: resetHideZeroRows });
            }}
            tooltipText={locale.hide_blank_rows_explanation}
            containerSx={{ py: 0.75 }}
          />
          {hideBlankRows && (
            <CheckboxField
              checked={settingsArea.settings.hideZeroRows === true}
              onChange={(value) => settingsArea.update({ hideZeroRows: value })}
              label="Treat Zeros as Empty"
              formControlLabelSx={{ ml: 1.25 }}
            />
          )}
        </Stack>
      </Stack>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography variant="subtitle1">Totals</Typography>
      <SwitchField
        label="Show Grand Total"
        checked={settingsArea.settings.showGrandTotal === true}
        onChange={(showGrandTotal) => settingsArea.update({ showGrandTotal })}
      />
      <GroupSummaryPlace
        value={settingsArea.settings.groupSummaryPlace}
        onChange={(groupSummaryPlace) => settingsArea.update({ groupSummaryPlace })}
      />
    </Stack>
  );
}
