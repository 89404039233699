import { Grid2, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../../../shared/reporting/utils/checkUnitTable";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import {
  CellDrillDownInfoType,
  CellDrillDownInfoTypes,
  ChartOfAccountsDataItem,
  ChartOfAccountsLedgerItem,
  DrillDownInfo,
} from "../../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { drillDownActions } from "../../../../../store/drilldownSlice";
import { PersistedTabPanel } from "../../../../common/PersistedTabPanel";
import { useChartOfAccountsContext } from "../../chartOfAccounts/contexts/ChartOfAccountsContext";
import LedgerAccountsList from "./LedgerAccountsList";

interface Props {
  configuration: DrillDownConfiguration;
  info: DrillDownInfo;
  chartOfAccounts: ChartOfAccountsLedgerItem[];
}

export default function ChartOfAccountsListContainer({ configuration, info, chartOfAccounts }: Props) {
  const dispatch = useDispatch();
  const { selectedAccounts, scrollTargetAccount } = useChartOfAccountsContext();
  const glAccounts = chartOfAccounts.find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Gl));
  const memoAccounts = chartOfAccounts.find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Memo));

  const hasGl = glAccounts && glAccounts.items.length > 0;
  const hasMemo = memoAccounts && memoAccounts.items.length > 0;

  const glInfo = info.items.find((item) => checkUnitTable(item.table, MeasureUnitTable.Gl));
  const memoInfo = info.items.find((item) => checkUnitTable(item.table, MeasureUnitTable.Memo));

  const [selectedTab, setSelectedTab] = useState<MeasureUnitTable>(hasGl ? MeasureUnitTable.Gl : MeasureUnitTable.Memo);
  useEffect(() => {
    if (selectedAccounts.length > 0) {
      const isGl = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Gl))
        ?.items.some((account) => selectedAccounts.includes(account.accountNo));
      if (isGl) {
        setSelectedTab(MeasureUnitTable.Gl);
        return;
      }
      const isMemo = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Memo))
        ?.items.some((account) => selectedAccounts.includes(account.accountNo));
      if (isMemo) {
        setSelectedTab(MeasureUnitTable.Memo);
      }
    }
  }, [chartOfAccounts, selectedAccounts]);

  useEffect(() => {
    if (scrollTargetAccount?.accountNo) {
      const isGl = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Gl))
        ?.items.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isGl) {
        setSelectedTab(MeasureUnitTable.Gl);
        return;
      }
      const isMemo = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Memo))
        ?.items.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isMemo) {
        setSelectedTab(MeasureUnitTable.Memo);
      }
    }
  }, [chartOfAccounts, scrollTargetAccount?.accountNo]);

  const handleSubDrillDown = useCallback(
    (itemInfo: CellDrillDownInfoTypes | undefined, row: ChartOfAccountsDataItem) => {
      const newConfiguration = createSubDrillDown(row, configuration, itemInfo || info.combined);
      if (newConfiguration !== undefined) dispatch(drillDownActions.add(newConfiguration));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid2 container sx={{ py: 2.5, pr: 3, pl: 1.5, gap: 2, flexDirection: "column", width: "100%" }}>
      <Tabs value={selectedTab} sx={{ pl: 2.5 }} onChange={(_, value: MeasureUnitTable) => setSelectedTab(value)}>
        {hasGl && <Tab key={MeasureUnitTable.Gl} label="General Ledger Accounts" value={MeasureUnitTable.Gl} />}
        {hasMemo && <Tab key={MeasureUnitTable.Memo} label="Memo Ledger Accounts" value={MeasureUnitTable.Memo} />}
      </Tabs>
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        <PersistedTabPanel key={MeasureUnitTable.Gl} value={MeasureUnitTable.Gl} selectedTab={selectedTab}>
          <LedgerAccountsList
            configuration={configuration}
            info={glInfo}
            items={glAccounts?.items || []}
            ledger={MeasureUnitTable.Gl}
            onDrillDown={handleSubDrillDown}
          />
        </PersistedTabPanel>
        <PersistedTabPanel key={MeasureUnitTable.Memo} value={MeasureUnitTable.Memo} selectedTab={selectedTab}>
          <LedgerAccountsList
            configuration={configuration}
            info={memoInfo}
            items={memoAccounts?.items || []}
            ledger={MeasureUnitTable.Memo}
            onDrillDown={handleSubDrillDown}
          />
        </PersistedTabPanel>
      </Grid2>
    </Grid2>
  );
}

function createSubDrillDown(
  row: ChartOfAccountsDataItem,
  configuration: DrillDownConfiguration,
  info: CellDrillDownInfoTypes
) {
  const measure = getSubDrillDownMeasure(configuration, row);

  const newConfiguration: DrillDownConfiguration = {
    id: generateGuid(),
    measure,
    cell: {
      value: row.amount,
      formattedValue: row.amountFormatted || "",
    },
    info: {
      combined: { ...info, drillDownInfoType: CellDrillDownInfoType.Default, conditions: row.conditions },
      items: [],
    },
    onlyLedger: true,
    chartOfAccounts: false,
  };

  return newConfiguration;
}

function getSubDrillDownMeasure(configuration: DrillDownConfiguration, row: ChartOfAccountsDataItem) {
  const measure = cloneDeep(configuration.measure);
  if (measure !== undefined) {
    measure.config.customLabel = row.accountName;
  }

  return measure;
}
