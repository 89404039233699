import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  AreaItemType,
  DimensionField,
  DimensionFieldType,
  FieldConfiguration,
  MeasureField,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import DimensionItem from "../../../common/fields/DimensionItem";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { ConditionFieldsArea } from "../../../common/fields/FieldArea";
import MeasureItem from "../../../common/fields/MeasureItem";
import { FieldItemProps } from "../../../common/fields/types/areaFiledItem.types";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createValueField } from "../../../common/utilities/createFields";
import { extractGuid, extractMeta } from "../../../common/utilities/dropFieldContainerHelper";
import DimensionFieldOptions from "../../../tabular/table/DimensionFieldOptions";
import FieldOptionPopupContainer from "../../../tabular/table/FieldOptionPopupContainer";
import MeasureFieldOptions from "../../../tabular/table/MeasureFieldOptions";
import { ShowFieldOptionsSettings } from "../../../Types";
import { isDimensionBased, isMeasure } from "../../../utils/fieldsHelper";
import {
  formatDimensionFieldCaption,
  formatDimensionInFieldsCaption,
} from "../../../utils/formatDimensionFieldCaptions";
import { isMeasureConfigurationValid } from "../../../utils/isConfigurationValid";
import { ValueFieldsArea } from "../../types";
import AggregationOptionsComponent from "./AggregationOptionsComponent";
import { formatMeasureCaption } from "./helper";
import OtherOptionsComponent from "./OtherOptionsComponent";

interface Props {
  conditionsArea: ConditionFieldsArea;
  fieldsArea: ValueFieldsArea;
  fields: DimensionField[];
  canDropItem?: () => boolean;
}

export const ValuesDropFieldsContainer = ({ conditionsArea, fieldsArea, fields, canDropItem }: Props) => {
  const dimensions = useSelector(selectDimensions);
  const dimensionsRef = React.useRef(dimensions);
  dimensionsRef.current = dimensions;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentFieldId, setCurrentFieldId] = useState<string>();

  const fieldsAreaRef = React.useRef(fieldsArea);
  fieldsAreaRef.current = fieldsArea;

  const currentField = useMemo(() => {
    if (!currentFieldId) {
      return undefined;
    }
    return fieldsArea.values.find((f) => f.config.guid === currentFieldId);
  }, [currentFieldId, fieldsArea.values]);

  const showFieldOptions = useCallback((settings: ShowFieldOptionsSettings<ReportField>) => {
    setAnchorEl(settings.ref);
    setCurrentFieldId(settings.field.config.guid);
  }, []);

  const measureCaptionColor = useCallback(
    (value: ReportField) => {
      const valid = isMeasureConfigurationValid(conditionsArea.values, fields, dimensionsRef.current, value);
      return valid.valid ? undefined : "error.main";
    },
    [conditionsArea.values, fields]
  );
  const handleMeasureFormatCaption = React.useCallback(
    (measure: MeasureField) => formatMeasureCaption(measure, measureCaptionColor(measure)),
    [measureCaptionColor]
  );

  const formatCaption = useCallback(
    (field: ReportField) => {
      if (isMeasure(field)) {
        return formatMeasureCaption(field, measureCaptionColor(field));
      } else if (isDimensionBased(field)) {
        return formatDimensionFieldCaption(field);
      }
      return undefined;
    },
    [measureCaptionColor]
  );

  const handleCanDropItem = useCallback(() => {
    return canDropItem ? canDropItem() : true;
  }, [canDropItem]);

  const handleRemove = React.useCallback(() => {
    setAnchorEl(null);
    if (currentField) {
      fieldsAreaRef.current.removeItem(currentField);
    }
  }, [currentField]);
  const closePopup = useCallback(() => {
    setCurrentFieldId(undefined);
    setAnchorEl(null);
  }, []);

  const handleUpdateConfiguration = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      if (!currentField) {
        return;
      }
      fieldsAreaRef.current.updateItemConfig(currentField, changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [currentField]
  );

  const handleDimensionFieldCustomLabelChange = useCallback(
    (name: string | undefined) => {
      if (!isDimensionBased(currentField)) {
        return;
      }
      fieldsAreaRef.current.updateItemConfig(currentField, { customLabel: name });
    },
    [currentField]
  );

  const createItem = React.useCallback(
    (props: FieldItemProps<ReportField>) => {
      if (isMeasure(props.field)) {
        const field = props.field;
        return (
          <MeasureItem
            field={field}
            fields={dimensionsRef.current}
            globalConditions={conditionsArea.values}
            formatCaption={handleMeasureFormatCaption}
            canBeRemoved
            onRemoveItem={fieldsAreaRef.current.removeItem}
            updateMeasure={fieldsAreaRef.current.updateItemConfig}
            onShowOptions={showFieldOptions}
          />
        );
      } else {
        const field = props.field;
        return (
          <DimensionItem
            field={field}
            fields={dimensionsRef.current}
            globalConditions={conditionsArea.values}
            canBeRemoved
            onRemoveItem={fieldsAreaRef.current.removeItem}
            formatCaption={formatDimensionInFieldsCaption}
            updateField={fieldsAreaRef.current.updateItemConfig}
            onShowOptions={showFieldOptions}
          />
        );
      }
    },
    [conditionsArea.values, handleMeasureFormatCaption, showFieldOptions]
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Values
      </Typography>
      <DropFieldContainer
        areaFieldType="VALUES"
        acceptedDropTypes={[
          AreaItemType.MEASURE,
          AreaItemType.DIMENSION,
          AreaItemType.COLUMNS,
          AreaItemType.ROWS,
          AreaItemType.CONDITIONS,
        ]}
        fields={fieldsArea.values}
        canDropItem={handleCanDropItem}
        renderListItem={createItem}
        getKeyValue={getKeyValue}
        formatCaption={formatCaption}
        onAddItem={fieldsArea.addItem}
        onRemoveItem={fieldsArea.removeItem}
        onMoveItem={fieldsArea.moveItem}
        createListItem={createValueField}
      />
      <FieldOptionPopupContainer anchorEl={anchorEl} cancel={closePopup}>
        {currentField?.type === DimensionFieldType.DIMENSION && (
          <DimensionFieldOptions
            field={currentField}
            AggregationOptions={
              <AggregationOptionsComponent field={currentField} saveChanges={handleUpdateConfiguration} />
            }
            cancel={closePopup}
            onRemove={handleRemove}
            onUpdateConfiguration={handleUpdateConfiguration}
            onUpdateCustomLabel={handleDimensionFieldCustomLabelChange}
          />
        )}
        {currentField?.type === DimensionFieldType.MEASURE && (
          <MeasureFieldOptions
            measure={currentField}
            dimensionFields={fields}
            conditions={conditionsArea.values}
            otherOptions={<OtherOptionsComponent measure={currentField} saveChanges={handleUpdateConfiguration} />}
            cancel={closePopup}
            saveChanges={handleUpdateConfiguration}
            onRemove={handleRemove}
          />
        )}
      </FieldOptionPopupContainer>
    </Box>
  );
};

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  const guid = extractGuid(field);
  if (guid) {
    return guid;
  }
  const meta = extractMeta(field);
  if (meta) {
    return meta.name;
  }
  return "";
}

export default ValuesDropFieldsContainer;
