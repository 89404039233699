import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Button, Stack, TextField } from "@mui/material";
import { memo } from "react";
import { DragLayerMonitor, useDragLayer } from "react-dnd";
import { makeLighterBackgroundFromColor } from "../../../../../../../shared/utilities/colorHelper";
import { FieldWithSorting } from "../../../../../../hooks/FieldWithOrder";
import SortAscIcon from "../../../../../../icons/SortAscIcon";
import SortDescIcon from "../../../../../../icons/SortDescIcon";
import { DraggingBox } from "../../../dragLayer/DraggingBox";
import { OptionField } from "../ColumnsOptions.types";

const CustomDragLayerInternal = () => {
  const { isDragging, currentOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem<{ field: FieldWithSorting; options: OptionField[] }>(),
    };
  });

  return isDragging && currentOffset ? (
    <DraggingBox
      sx={{
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        height: "37px",
        width: "283px",
        backgroundColor: "#FFF",
      }}
    >
      <CustomDragLayerBody field={item.field} options={item.options} />
    </DraggingBox>
  ) : null;
};

const CustomDragLayerBody = memo((props: { field: FieldWithSorting; options: OptionField[] }) => {
  return (
    <Stack direction="row" sx={{ alignItems: "center", gap: 0.7, borderRadius: "4px" }}>
      <DragIndicatorIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      <TextField
        value={props.options.find((o) => o.id === props.field.field.name)?.label}
        slotProps={{
          input: {
            endAdornment: <ArrowDropDownRoundedIcon color="secondary" />,
          },
        }}
        sx={{ borderColor: "#DDDFE2", flex: 1 }}
      />
      <Button
        variant="outlined"
        sx={(theme) => ({
          minWidth: "30px",
          px: "11px",
          py: "7px",
          ".MuiButton-startIcon": {
            mx: "-4px",
          },
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
        })}
        startIcon={props.field.sortAsc ? <SortAscIcon /> : <SortDescIcon />}
      />
    </Stack>
  );
});

CustomDragLayerBody.displayName = "CustomDragLayerBody";

export default memo(CustomDragLayerInternal, () => true);
