import { Select, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import CubeIcon from "../../../../../shared/icons/CubeIcon";
import { DataSetInfo } from "../../../../api/biApi.types";
import { selectDataSetSettings } from "../../../../store/clientSettingsSlice";
import { metaDataActions, selectCurrentDataSet } from "../../../../store/metaDataSlice";

export default function DataSetSelector() {
  const dispatch = useDispatch();
  const dataSets = useSelector(selectDataSetSettings);
  const selectedDataSet = useSelector(selectCurrentDataSet);

  const handleChange = useCallback(
    (id: string) => {
      const ds = dataSets.find((ds) => ds.id === id);
      if (ds?.id) {
        dispatch(metaDataActions.preSetDataSet(ds.id));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSets]
  );

  useEffect(() => {
    if (!selectedDataSet && dataSets.length > 0) {
      const dsId = dataSets[0]?.id;
      if (dsId) {
        dispatch(metaDataActions.preSetDataSet(dsId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedDataSet) {
    return null;
  }

  return (
    <Select
      fullWidth
      value={selectedDataSet}
      sx={{ ".MuiSelect-select": { py: 1 } }}
      onChange={(evt) => handleChange(evt.target.value)}
    >
      {dataSets.map((value) => (
        <MenuItemStyled key={value.id} value={value.id}>
          <DataSetItem value={value} />
        </MenuItemStyled>
      ))}
    </Select>
  );

  function DataSetItem({ value }: { value: DataSetInfo }) {
    return (
      <Stack sx={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 1.5 }}>
        <CubeIcon sx={(theme) => ({ color: theme.palette.action.active })} />
        <Typography variant="body1">{value.name}</Typography>
      </Stack>
    );
  }
}
