import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientSettings } from "../api/biApi.types";
import { RootState } from "./RootState";

export interface ClientSettingsState {
  settings?: ClientSettings;
}

const initialState: ClientSettingsState = {};

const clientSettingsSlice = createSlice({
  name: "clientSettings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<ClientSettings>) => {
      state.settings = action.payload;
    },
  },
});

export const clientSettingsActions = clientSettingsSlice.actions;

export const selectClientSettings = (state: RootState) => state.clientSettings?.settings;
export const selectAccountBookSettings = (state: RootState) => state.clientSettings?.settings?.accountingBookSettings;
export const selectDataSetSettings = (state: RootState) => state.clientSettings?.settings?.dataSets ?? [];

export default clientSettingsSlice.reducer;
