import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { NumberFormatterOptions } from "../../../../../../../shared/reporting/api/biClient.types";

enum SeparatorStyletype {
  SpaceAndComma = " ,",
  SpaceAndDot = " .",
  CommaAndDot = ",.",
  DotAndComma = ".,",
}

const predefinedSeparatorStyles: { [key: string]: { thousand: string; decimal: string } } = {
  [SeparatorStyletype.SpaceAndComma]: { thousand: " ", decimal: "," },
  [SeparatorStyletype.SpaceAndDot]: { thousand: " ", decimal: "." },
  [SeparatorStyletype.CommaAndDot]: { thousand: ",", decimal: "." },
  [SeparatorStyletype.DotAndComma]: { thousand: ".", decimal: "," },
};

export const Decimals = (props: {
  formatOptions: NumberFormatterOptions | undefined;
  applyFormat: (formatOptons: NumberFormatterOptions) => void;
}) => {
  const { formatOptions, applyFormat } = props;

  const changeDecimals = (decimals: number) => {
    const copiedObject = {
      ...formatOptions,
      decimals,
    } as NumberFormatterOptions;
    applyFormat(copiedObject);
  };

  const changeSeparatorStyles = (newStyle: string) => {
    const style = predefinedSeparatorStyles[newStyle];
    if (!style) {
      return;
    }
    const copiedObject = {
      ...formatOptions,
      thousandSeparator: style.thousand,
      decimalSeparator: style.decimal,
    } as NumberFormatterOptions;

    applyFormat(copiedObject);
  };

  const renderGroupStyleItem = (title: string, separatorType: SeparatorStyletype) => {
    return (
      <MenuItem value={separatorType}>
        <Typography>{title}</Typography>
      </MenuItem>
    );
  };

  return (
    <Stack direction={"row"} flex={1} gap={2}>
      <TextField
        defaultValue={formatOptions?.decimals || 0}
        type="number"
        label="Decimals"
        sx={{
          flex: 1,
          fieldset: {
            borderColor: "#DDDFE2",
          },
        }}
        slotProps={{
          htmlInput: {
            min: 0,
            max: 5,
            style: {},
          },
          inputLabel: {
            shrink: true,
          },
        }}
        onChange={(evt) => changeDecimals(parseInt(evt.target.value))}
      ></TextField>
      <FormControl sx={{ flex: 1 }}>
        <InputLabel id="select-label">Separator styles</InputLabel>
        <Select
          value={`${formatOptions?.thousandSeparator || ","}${formatOptions?.decimalSeparator || "."}`}
          labelId="select-label"
          label="Separator styles"
          onChange={(evt) => changeSeparatorStyles(evt.target.value)}
          sx={{
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
            },
            fieldset: {
              borderColor: "#DDDFE2",
            },
          }}
        >
          {renderGroupStyleItem("100 000,00", SeparatorStyletype.SpaceAndComma)}
          {renderGroupStyleItem("100 000.00", SeparatorStyletype.SpaceAndDot)}
          {renderGroupStyleItem("100,000.00", SeparatorStyletype.CommaAndDot)}
          {renderGroupStyleItem("100.000,00", SeparatorStyletype.DotAndComma)}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default Decimals;
