import { Grid2 } from "@mui/material";
import { useCallback, useMemo } from "react";
import { AmountType, FieldConfiguration, MeasureField } from "../../../../../../shared/reporting/api/biClient.types";
import { getMeasureAmountTypeField } from "../../../utils/isConfigurationValid";
import AmountTypeFieldControl from "../../fields/AmountTypeFieldControl";

interface Props {
  measure: MeasureField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
  disabled?: boolean;
}

const AmountTypeMenuItem = ({ measure, disabled, saveChanges }: Props) => {
  const amountField = useMemo(() => getMeasureAmountTypeField(measure), [measure]);

  const handleOnChange = useCallback(
    (amountType: AmountType) => {
      saveChanges({ amountType: amountType }, false);
    },
    [saveChanges]
  );

  if (!amountField) return null;

  return (
    <Grid2 container width={"100%"}>
      <AmountTypeFieldControl
        amountType={amountField}
        disabled={!measure.meta.capabilities.changeAmountField || disabled}
        onChange={handleOnChange}
      />
    </Grid2>
  );
};

export default AmountTypeMenuItem;
