import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ClientInfo, ReportTemplate } from "../../../../shared/reporting/api/biClient.types";
import SaveTemplateDialog from "../../../../shared/reporting/components/reportTemplates/dialogs/SaveTemplateDialog";
import { useClientContext } from "../../../contexts/ClientContext";
import ReportingIcon from "../../../icons/ReportingIcon";
import {
  selectCurrentReport,
  selectIsReportChanged,
  selectReportConfiguration,
} from "../../../store/currentReportSlice";
import UnsavedChangesDialog from "../../builder/backToReports/UnsavedChangesDialog";
import { getEditTemplateUrl, getOrganizationReportTemplatesUrl } from "../../builder/common/utilities/editReportUrl";
import BackToButton from "../../common/BackToButton";

interface Props {
  companies: ClientInfo[];
}
export default function BackToTemplates({ companies }: Props) {
  const { clientInfo } = useClientContext();
  const navigate = useNavigate();

  const isReportChanged = useSelector(selectIsReportChanged);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const template = useSelector(selectCurrentReport) as ReportTemplate;

  const [over, setOver] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);

  const navigateToTemplates = useCallback(() => {
    window.location.href = getOrganizationReportTemplatesUrl(clientInfo.organization);
  }, [clientInfo.organization]);

  const onBackButtonClicked = useCallback(() => {
    if (isReportChanged === true) {
      setShowUnsavedDialog(true);
    } else {
      navigateToTemplates();
    }
  }, [isReportChanged, navigateToTemplates, setShowUnsavedDialog]);

  const handleOnCancel = useCallback(() => setShowUnsavedDialog(false), [setShowUnsavedDialog]);
  const handleOnContinue = useCallback(() => navigateToTemplates(), [navigateToTemplates]);
  const handleOnSave = useCallback(() => {
    setShowSaveDialog(true);
    setShowUnsavedDialog(false);
  }, [setShowSaveDialog, setShowUnsavedDialog]);

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          flexBasis: 1,
          gap: 1.4,
          width: "100%",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
        onClick={onBackButtonClicked}
      >
        <BackToButton over={over} ProductIcon={ReportingIcon} />
      </Stack>
      {showUnsavedDialog && (
        <UnsavedChangesDialog
          onCancel={handleOnCancel}
          onContinue={handleOnContinue}
          onSave={handleOnSave}
          name={template.name}
          type="template"
        />
      )}
      {showSaveDialog && template && (
        <SaveTemplateDialog
          title="Save Template"
          template={template}
          templateConfiguration={reportConfiguration}
          onClose={() => setShowSaveDialog(false)}
          onSaved={(rt) => {
            setShowSaveDialog(false);
            navigate(getEditTemplateUrl(rt.dataSource || clientInfo.clientCode, rt.reportId, rt.organization));
          }}
          companies={companies}
        />
      )}
    </>
  );
}
