import { Divider, FormControl, InputLabel, Select, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { MenuItemStyled } from "../../../../shared/components/MenuItemStyled";
import { FormatType, NumberFormatterOptions } from "../../../../shared/reporting/api/biClient.types";
import { getAllNumberFormattersDescription } from "../../../formatters/NumberFormatter";
import MeasureCustomFormatPopup from "../pivot/table/format/custom/MeasureCustomFormatPopup";

const formatterTypes = {
  Custom: "Custom",
  Default: "Default",
};

interface Props {
  format: NumberFormatterOptions | undefined | null;
  label?: string;
  onUpdateFormatter: (formatter: NumberFormatterOptions | undefined) => void;
}

export const FormatSelect = ({ format, label, onUpdateFormatter }: Props) => {
  const [selectedOptionsType, setSelectedOptionsType] = useState(format?.type || "Default");
  const [openCustomFormat, setOpenCustomFormat] = useState(false);
  const [customFormatOptions, setCustomFormatOptions] = useState<NumberFormatterOptions>({} as NumberFormatterOptions);

  const allFormatters = useMemo(() => getAllNumberFormattersDescription(), []);

  const onFormatChanged = (value: string) => {
    if (value === formatterTypes.Custom) {
      return;
    }
    const options = getFormatOptionsByType(value);
    const format = options?.options as NumberFormatterOptions;
    setSelectedOptionsType(value);
    onUpdateFormatter(format && { ...format });
  };

  const renderSelectedValue = (value: string) => {
    let valueToRender = value;
    if (value !== formatterTypes.Custom && value !== formatterTypes.Default) {
      const description = allFormatters.find((formatter) => formatter.options.type === value);
      if (description) {
        valueToRender = description.title;
      }
    }

    return (
      <Typography variant="caption" sx={{ flex: 1 }}>
        {valueToRender}
      </Typography>
    );
  };

  const getFormatOptionsByType = (type: string) => {
    return allFormatters.find((formatter) => formatter.options.type === type);
  };

  const openCustomFormatPopup = () => {
    if (format?.type === formatterTypes.Custom) {
      setCustomFormatOptions({ ...format });
    } else {
      setCustomFormatOptions({ type: formatterTypes.Custom, formatType: FormatType.Numeric } as NumberFormatterOptions);
    }
    setOpenCustomFormat(true);
  };

  const applyCustomFormat = (options: NumberFormatterOptions) => {
    setSelectedOptionsType(formatterTypes.Custom);
    onUpdateFormatter({ ...options, formatType: FormatType.Numeric });
  };

  useEffect(() => {
    if (format?.type) {
      setSelectedOptionsType(format.type);
    }
  }, [format?.type]);

  return (
    <>
      <FormControl sx={{ flex: 1 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          sx={{
            display: "flex",
            flex: 1,
            "& .MuiTypography-root": {
              fontSize: 13,
            },
          }}
          renderValue={renderSelectedValue}
          value={selectedOptionsType}
          onChange={(evt) => onFormatChanged(evt.target.value)}
        >
          <MenuItemStyled
            value={formatterTypes.Custom}
            selected={selectedOptionsType === formatterTypes.Custom}
            onClick={() => openCustomFormatPopup()}
          >
            <Typography>Custom Format</Typography>
          </MenuItemStyled>
          <Divider />
          <MenuItemStyled selected={selectedOptionsType === formatterTypes.Default} value={formatterTypes.Default}>
            <Typography>Default</Typography>
          </MenuItemStyled>
          {allFormatters.map((description) => (
            <MenuItemStyled
              key={description.title}
              sx={{ display: "flex", flex: 1 }}
              selected={selectedOptionsType === description.options.type}
              value={description.options.type}
            >
              <Typography sx={{ flex: 1 }}>{description.title}</Typography>
              <Typography color="rgba(33, 33, 33, 0.38)" sx={{ minWidth: "120px", textAlign: "end", pl: "1rem" }}>
                {description.subTitle}
              </Typography>
            </MenuItemStyled>
          ))}
        </Select>
      </FormControl>
      {openCustomFormat && (
        <MeasureCustomFormatPopup
          formatOptions={customFormatOptions}
          applyFormat={applyCustomFormat}
          close={() => setOpenCustomFormat(false)}
        />
      )}
    </>
  );
};

export default FormatSelect;
