import { Grid2, SxProps, Theme } from "@mui/material";
import React from "react";
import { stringifyValue } from "../../../shared/utilities/stringHelper";

export interface TabPanelProps<T> {
  children?: React.ReactNode;
  selectedTab: T;
  value: T;
  sx?: SxProps<Theme>;
}

export function PersistedTabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, selectedTab, sx, ...other } = props;
  const [rendered, setRendered] = React.useState(false);

  React.useEffect(() => {
    if (value === selectedTab) {
      setRendered(true);
    }
  }, [value, selectedTab]);

  if (!rendered) {
    return null;
  }

  return (
    <Grid2
      container
      display={value === selectedTab ? "flex" : "none"}
      id={`dd-tabpanel-${stringifyValue(value)}`}
      width="100%"
      sx={sx}
      {...other}
    >
      {children}
    </Grid2>
  );
}
