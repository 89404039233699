import { List } from "@mui/material";
import objectHash from "object-hash";
import { JSX } from "react";
import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { DimensionGroup, DimensionsStructure } from "../../../../../api/biApi.types";
import FieldItemContainer from "../FieldItem";
import GroupListItem from "../GroupListItem";
import DimensionFieldItem from "./DimensionFieldItem";

const buildTreeGroup = (fields: DimensionDescriptor[], group: DimensionGroup) => {
  const items: JSX.Element[] = [];
  const dimensionItems: JSX.Element[] = [];

  const dimensionsIdGroup = group.dimensions
    .map((name) => fields.find((field) => field.name === name))
    .filter((dimension): dimension is DimensionDescriptor => !!dimension);

  if (dimensionsIdGroup.length > 0) {
    dimensionItems.push(
      <List key={objectHash(group.dimensions)} sx={{ p: 0 }}>
        {dimensionsIdGroup.map((dimension, i) => {
          return (
            <FieldItemContainer key={`${dimension.name}-${i}}`} field={dimension}>
              <DimensionFieldItem field={dimension} />
            </FieldItemContainer>
          );
        })}
      </List>
    );
  }

  group.groups?.forEach((g) => {
    dimensionItems.push(...buildTreeGroup(fields, g));
  });

  if (dimensionItems.length > 0) {
    items.push(
      <GroupListItem key={group.caption} caption={group.caption}>
        {dimensionItems}
      </GroupListItem>
    );
  }
  return items;
};

export const buildTree = (fields: DimensionDescriptor[], structure: DimensionsStructure) => {
  const items: JSX.Element[] = [];
  structure.groups.forEach((group) => {
    items.push(...buildTreeGroup(fields, group));
  });
  return items;
};
