import axios, { AxiosError } from "axios";
import { clientCodeHeader, getClientCode } from "../../shared/api/clientApiContext";
import { ApiResponse } from "../../shared/api/types";
import sharedBiClient, { apiReports, uiReports } from "../../shared/reporting/api/biClient";
import {
  DimensionDictionary,
  MeasureDescriptor,
  Report,
  ReportResponse,
  ReportUsage,
} from "../../shared/reporting/api/biClient.types";
import { getBackendBaseUrl } from "../../shared/variables";
import {
  ApiReportResponse,
  BuildChartReportRequest,
  BuildExportRequest,
  BuildPivotReportRequest,
  BuildTabularReportRequest,
  CashFlowExportRequest,
  ChartOfAccounts,
  ChartOfAccountsDataResponse,
  ChartOfAccountsExportRequest,
  ChartResponse,
  ClientSettings,
  CrossFilterRequest,
  CrossFilterResponse,
  CustomMeasureDescription,
  DrillDownCacheValidationResponse,
  DrillDownDataRequest,
  DrillDownDataResponse,
  DrillDownInfoResult,
  ExportResponse,
  LedgerBasisRequest,
  LedgerBasisResponse,
  MeasureDataset,
  MetaDataResponse,
  PivotDataResponse,
  RunChartReportRequest,
  RunExportRequest,
  RunPivotReportRequest,
  RunTabularReportRequest,
  TabularDataResponse,
  TabularGroupTotalsResponse,
  UsedByReportsResponse,
} from "../api/biApi.types";
import { dataSetHeader } from "./dataSetApiContext";

export const uiTabularEndpoint = "UiTabular";
export const apiTabularEndpoint = "ApiTabular";

export interface CancellationToken {
  cancel: () => void;
}

const baseUri = getBackendBaseUrl("reporting");

const defaultHeaders = () => {
  return {
    ...clientCodeHeader(),
    ...dataSetHeader(),
  };
};

const loadMeta = async () => {
  const { data } = await axios.get<ApiResponse<MetaDataResponse>>(`${baseUri}/MetaData`, {
    headers: defaultHeaders(),
  });
  return data;
};

const loadDimensionDictionaries = async (...dimensions: string[]) => {
  const queryParams = new URLSearchParams();
  dimensions.map((d) => queryParams.append("d", d));
  const { data } = await axios.get<ApiResponse<{ [key: string]: DimensionDictionary }[]>>(
    `${baseUri}/MetaData/dictionaries?${queryParams.toString()}`,
    {
      headers: defaultHeaders(),
    }
  );
  return data;
};

const requestBuildPivotExport = async (body: BuildExportRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/Pivot/buildExport`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const requestRunPivotExport = async (body: RunExportRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/Pivot/runExport`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const runPivotReport = (
  body: RunPivotReportRequest,
  done: (data: ApiResponse<PivotDataResponse>) => void,
  error: (reason: string | undefined) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<PivotDataResponse>>(`${baseUri}/Pivot/run`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => {
      if (reason.message === "canceled") {
        error("canceled");
        return;
      }
      const axiosError = reason as AxiosError<ApiResponse<unknown>>;
      const errorMessage = axiosError.response?.data?.error?.message;
      error(errorMessage);
    });
  return { cancel: () => controller.abort() };
};

const buildPivotReport = (
  body: BuildPivotReportRequest,
  done: (data: ApiResponse<PivotDataResponse>) => void,
  error: (reason: string | undefined) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<PivotDataResponse>>(`${baseUri}/Pivot/build`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => {
      if (reason.message === "canceled") {
        error("canceled");
        return;
      }
      const axiosError = reason as AxiosError<ApiResponse<unknown>>;
      const errorMessage = axiosError.response?.data?.error?.message;
      error(errorMessage);
    });
  return { cancel: () => controller.abort() };
};

const runChartReport = (
  body: RunChartReportRequest,
  done: (data: ApiResponse<ChartResponse>) => void,
  error: (reason: string | undefined) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<ChartResponse>>(`${baseUri}/Chart/run`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => {
      if (reason.message === "canceled") {
        error("canceled");
        return;
      }
      const axiosError = reason as AxiosError<ApiResponse<unknown>>;
      const errorMessage = axiosError.response?.data?.error?.message;
      error(errorMessage);
    });
  return { cancel: () => controller.abort() };
};

const buildChartReport = (
  body: BuildChartReportRequest,
  done: (data: ApiResponse<ChartResponse>) => void,
  error: (reason: string | undefined) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<ChartResponse>>(`${baseUri}/Chart/build`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => {
      if (reason.message === "canceled") {
        error("canceled");
        return;
      }
      const axiosError = reason as AxiosError<ApiResponse<ChartResponse>>;
      const errorMessage = axiosError.response?.data?.error?.message;
      error(errorMessage);
    });
  return { cancel: () => controller.abort() };
};

const getDrillDownData = async (body: DrillDownDataRequest, signal?: AbortSignal) => {
  const { data } = await axios.post<ApiResponse<DrillDownDataResponse>>(`${baseUri}/DrillDown`, body, {
    headers: defaultHeaders(),
    signal: signal,
  });
  return data;
};

const getDrillDownItem = async (sessionId: string, itemId: string) => {
  const { data } = await axios.get<ApiResponse<DrillDownInfoResult>>(
    `${baseUri}/DrillDown/item?sessionId=${sessionId}&itemId=${itemId}`,
    {
      headers: defaultHeaders(),
    }
  );
  return data;
};

const getChartOfAccountsDrillDown = async (sessionId: string, itemId: string) => {
  const { data } = await axios.get<ApiResponse<ChartOfAccountsDataResponse>>(
    `${baseUri}/DrillDown/chartofaccounts?sessionId=${sessionId}&itemId=${itemId}`,
    {
      headers: defaultHeaders(),
    }
  );
  return data;
};

const isDrillDownCacheValid = async (sessionId: string, etag: string) => {
  const { data } = await axios.get<ApiResponse<DrillDownCacheValidationResponse>>(
    `${baseUri}/DrillDown/validatecache?sessionId=${sessionId}&etag=${etag}`,
    {
      headers: defaultHeaders(),
    }
  );
  return data;
};

const requestDrillDownExport = async (body: DrillDownDataRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/DrillDown/export`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const requestCashflowExport = async (body: CashFlowExportRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/DrillDown/export/cashflow`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const requestChartOfAccountsExport = async (body: ChartOfAccountsExportRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/DrillDown/export/chartofaccounts`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const requestRunTabularExport = async (body: RunExportRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/${uiTabularEndpoint}/runExport`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const requestBuildTabularExport = async (body: BuildExportRequest) => {
  const { data } = await axios.post<ApiResponse<ExportResponse>>(`${baseUri}/${uiTabularEndpoint}/buildExport`, body, {
    headers: defaultHeaders(),
  });
  return data;
};

const checkExportReady = async (token: string) => {
  const { data } = await axios.get<ApiResponse<ExportResponse>>(`${baseUri}/ReportFiles/export?token=${token}`, {
    headers: defaultHeaders(),
  });
  return data;
};

const generateExportDownloadLink = (token: string) => {
  return `${baseUri}/ReportFiles/export/download?token=${token}&clientCode=${getClientCode()}`;
};

const addCustomMeasure = async (measure: CustomMeasureDescription) => {
  const { data } = await axios.post<ApiResponse<MeasureDescriptor>>(`${baseUri}/CustomMeasure`, measure, {
    headers: defaultHeaders(),
  });
  return data;
};

const updateCustomMeasure = async (measure: CustomMeasureDescription) => {
  const { data } = await axios.put<ApiResponse<MeasureDescriptor>>(`${baseUri}/CustomMeasure`, measure, {
    headers: defaultHeaders(),
  });
  return data;
};

const deleteCustomMeasure = async (id: string) => {
  const { data } = await axios.delete<ApiResponse<boolean>>(`${baseUri}/CustomMeasure/${id}`, {
    headers: defaultHeaders(),
  });
  return data;
};

const getUsedByReports = async (id: string) => {
  const { data } = await axios.get<ApiResponse<UsedByReportsResponse>>(`${baseUri}/CustomMeasure/${id}/used-by`, {
    headers: defaultHeaders(),
  });
  return data;
};

const getMeasureDataSets = async () => {
  const { data } = await axios.get<ApiResponse<MeasureDataset[]>>(`${baseUri}/CustomMeasure/datasets`, {
    headers: defaultHeaders(),
  });
  return data;
};

const getLedgerBasis = (
  body: LedgerBasisRequest,
  done: (data: ApiResponse<LedgerBasisResponse>) => void,
  error: (reason: string) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<LedgerBasisResponse>>(`${baseUri}/${uiReports}/ledger-basis`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => error(reason.message));
  return { cancel: () => controller.abort() };
};

const crossFilter = (
  body: CrossFilterRequest,
  done: (data: ApiResponse<CrossFilterResponse>) => void,
  error: (reason: string) => void
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<CrossFilterResponse>>(`${baseUri}/CrossFiltering`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data))
    .catch((reason: Error) => error(reason.message));
  return { cancel: () => controller.abort() };
};

const getChartOfAccounts = async () => {
  const { data } = await axios.get<ApiResponse<ChartOfAccounts>>(`${baseUri}/ChartOfAccounts`, {
    headers: defaultHeaders(),
  });
  return data;
};

const getClientSettings = async () => {
  const { data } = await axios.get<ApiResponse<ClientSettings>>(`${baseUri}/Clients/settings`, {
    headers: defaultHeaders(),
  });
  return data;
};

const buildTabularReportBase = <
  TBody extends BuildTabularReportRequest,
  TResponse extends TabularDataResponse | ApiReportResponse,
>(
  body: TBody,
  done: (data: ApiResponse<TResponse>) => void,
  error: (reason: string | undefined) => void,
  endpoint: typeof uiTabularEndpoint | typeof apiTabularEndpoint
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<TabularDataResponse>>(`${baseUri}/${endpoint}/build`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data as ApiResponse<TResponse>))
    .catch((reason: Error) => {
      if (reason.message === "canceled") {
        error("canceled");
        return;
      }
      const axiosError = reason as AxiosError<ApiResponse<unknown>>;
      const errorMessage = axiosError.response?.data?.error?.message;
      error(errorMessage);
    });
  return { cancel: () => controller.abort() };
};

const runTabularReportBase = <
  TBody extends RunTabularReportRequest,
  TResponse extends TabularDataResponse | ApiReportResponse,
>(
  body: TBody,
  done: (data: ApiResponse<TResponse>) => void,
  error: (reason: string | undefined) => void,
  endpoint: typeof uiTabularEndpoint | typeof apiTabularEndpoint
): CancellationToken => {
  const controller = new AbortController();
  axios
    .post<ApiResponse<TabularDataResponse>>(`${baseUri}/${endpoint}/run`, body, {
      headers: defaultHeaders(),
      signal: controller.signal,
    })
    .then((res) => done(res.data as ApiResponse<TResponse>))
    .catch((reason: Error) => {
      if (reason.message === "canceled") {
        error("canceled");
        return;
      }
      const axiosError = reason as AxiosError<ApiResponse<unknown>>;
      const errorMessage = axiosError.response?.data?.error?.message;
      error(errorMessage);
    });
  return { cancel: () => controller.abort() };
};

const getReportsApi = (usage: ReportUsage) => {
  const reportsEndpoint = usage === "Api" ? apiReports : uiReports;
  const tabularStructuredReportEndpoint = usage === "Api" ? apiTabularEndpoint : uiTabularEndpoint;

  const buildReport = <
    TBody extends BuildTabularReportRequest,
    TResponse extends TabularDataResponse | ApiReportResponse,
  >(
    body: TBody,
    done: (data: ApiResponse<TResponse>) => void,
    error: (reason: string | undefined) => void
  ): CancellationToken => {
    if (usage === "Api") {
      return buildTabularReportBase(body, done, error, apiTabularEndpoint);
    }
    return buildTabularReportBase(body, done, error, uiTabularEndpoint);
  };

  const runReport = <TBody extends RunTabularReportRequest, TResponse extends TabularDataResponse | ApiReportResponse>(
    body: TBody,
    done: (data: ApiResponse<TResponse>) => void,
    error: (reason: string | undefined) => void
  ): CancellationToken => {
    if (usage === "Api") {
      return runTabularReportBase(body, done, error, apiTabularEndpoint);
    }

    return runTabularReportBase(body, done, error, uiTabularEndpoint);
  };

  const getReport = async (reportId: string, mode: "run" | "edit") => {
    const { data } = await axios.get<ApiResponse<Report>>(`${baseUri}/${reportsEndpoint}/${reportId}?mode=${mode}`, {
      headers: defaultHeaders(),
    });
    return data;
  };

  const updateReport = async (report: Report) => {
    const { data } = await axios.put<ApiResponse<ReportResponse>>(`${baseUri}/${reportsEndpoint}`, report, {
      headers: defaultHeaders(),
    });
    return data;
  };

  const addReport = async (report: Report) => {
    const { data } = await axios.post<ApiResponse<ReportResponse>>(`${baseUri}/${reportsEndpoint}`, report, {
      headers: defaultHeaders(),
    });
    return data;
  };

  const calculateTabularGroupTotalsData = (
    body: BuildTabularReportRequest,
    done: (data: ApiResponse<TabularGroupTotalsResponse>) => void,
    error: (reason: string | undefined) => void
  ): CancellationToken => {
    const controller = new AbortController();
    axios
      .post<ApiResponse<TabularGroupTotalsResponse>>(
        `${baseUri}/${tabularStructuredReportEndpoint}/groupTotals`,
        body,
        {
          headers: defaultHeaders(),
          signal: controller.signal,
        }
      )
      .then((res) => done(res.data))
      .catch((reason: Error) => {
        if (reason.message === "canceled") {
          error("canceled");
          return;
        }
        const axiosError = reason as AxiosError<ApiResponse<unknown>>;
        const errorMessage = axiosError.response?.data?.error?.message;
        error(errorMessage);
      });
    return { cancel: () => controller.abort() };
  };

  return {
    getReport,
    updateReport,
    addReport,
    runReport,
    calculateTabularGroupTotalsData,
    buildReport,
  };
};

const biClient = {
  loadMeta,
  loadDimensionDictionaries,
  requestBuildPivotExport,
  requestRunPivotExport,
  runPivotReport,
  buildPivotReport,
  runChartReport,
  buildChartReport,
  getDrillDownData,
  getDrillDownItem,
  getChartOfAccountsDrillDown,
  isDrillDownCacheValid,
  requestDrillDownExport,
  requestCashflowExport,
  requestChartOfAccountsExport,
  requestRunTabularExport,
  requestBuildTabularExport,
  checkExportReady,
  generateExportDownloadLink,
  addCustomMeasure,
  updateCustomMeasure,
  deleteCustomMeasure,
  getUsedByReports,
  getMeasureDataSets,
  crossFilter,
  getLedgerBasis,
  getChartOfAccounts,
  getClientSettings,
  addReportGroup: sharedBiClient.reportGroup.addReportGroup,
  updateReportGroup: sharedBiClient.reportGroup.updateReportGroup,
  deleteReportGroup: sharedBiClient.reportGroup.deleteReportGroup,
  replaceReportGroup: sharedBiClient.reportGroup.replaceReportGroup,
  reorderReportGroups: sharedBiClient.reportGroup.reorderReportGroups,
  getReportGroups: sharedBiClient.reportGroup.getReportGroups,
  uiReports: {
    getReportAuthorizationInfo: sharedBiClient.uiReports.getReportAuthorizationInfo,
    validateName: sharedBiClient.uiReports.validateReportName,
    renameReport: sharedBiClient.uiReports.renameReport,
    changeReportGroup: sharedBiClient.uiReports.changeReportGroup,
    deleteReports: sharedBiClient.uiReports.deleteReports,
    ...getReportsApi("Ui"),
  },
  apiReports: {
    getReportAuthorizationInfo: sharedBiClient.apiReports.getReportAuthorizationInfo,
    validateName: sharedBiClient.apiReports.validateReportName,
    renameReport: sharedBiClient.apiReports.renameReport,
    changeReportGroup: sharedBiClient.apiReports.changeReportGroup,
    deleteReports: sharedBiClient.apiReports.deleteReports,
    ...getReportsApi("Api"),
  },
};

export default biClient;
