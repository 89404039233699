import { Divider, Grid2 } from "@mui/material";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";
import StyleGroup from "../StyleGroup";
import SerieItem from "./SerieItem";

export default function SeriesStyles() {
  const { valuesArea, settingsArea } = useFieldsStateContext();

  return (
    <StyleGroup caption="Series">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        {settingsArea.settings.series?.map((serie, index) => {
          const lastSerie = settingsArea.settings.series?.length === index + 1;
          const serieItem = <SerieItem key={serie.name} area={settingsArea} values={valuesArea.values} serie={serie} />;
          if (!lastSerie) {
            return [serieItem, <Divider key={`Divider_${serie.name}`} sx={{ borderStyle: "dashed" }} />];
          }
          return serieItem;
        })}
      </Grid2>
    </StyleGroup>
  );
}
