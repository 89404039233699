import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DimensionField, FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { insertItemAt } from "../../../../utilities/Utilities";
import { updateItemV2 } from "../../common/utilities/fieldsState";

export type FieldsState = {
  rows: DimensionField[];
};

const initialState: FieldsState = { rows: [] };

const fieldsStateRowsSlice = createSlice({
  name: "fieldsStateRows",
  initialState: initialState,
  reducers: {
    resetToDefaultAction: () => initialState,
    setRowsAction: (state, action: PayloadAction<DimensionField[]>) => {
      state.rows = action.payload;
    },
    addRowAction: (state, action: PayloadAction<{ field: DimensionField; index: number }>) => {
      state.rows = insertItemAt(state.rows, action.payload.field, action.payload.index);
    },
    removeRowAction: (state, action: PayloadAction<DimensionField>) => {
      const rows = state.rows.filter((row) => row.meta.name !== action.payload.meta.name);
      const lastRow = rows[rows.length - 1];
      if (lastRow !== undefined) {
        lastRow.config.style = undefined;
      }
      state.rows = rows;
    },
    moveRowAction: (state, action: PayloadAction<{ field: DimensionField; newIndex: number }>) => {
      const { field, newIndex } = action.payload;
      const oldIndex = state.rows.findIndex((row) => row.meta.name === field.meta.name);
      if (oldIndex === -1) {
        return;
      }
      const copy = [...state.rows];
      copy.splice(oldIndex, 1);
      copy.splice(newIndex, 0, field);
      state.rows = copy;
    },
    updateRowAction: (state, action: PayloadAction<{ field: DimensionField; changes: Partial<DimensionField> }>) => {
      state.rows = updateItemV2(action.payload.field, action.payload.changes, state.rows);
    },
    updateRowConfigAction: (
      state,
      action: PayloadAction<{ field: ReportField; changes: Partial<FieldConfiguration> }>
    ) => {
      const row = state.rows.find((r) => r.meta.name === action.payload.field.meta.name);
      if (row) {
        const index = state.rows.indexOf(row);
        row.config = { ...row.config, ...action.payload.changes };
        state.rows[index] = row;
      }
    },
  },
});

export default fieldsStateRowsSlice;

export const rowsActions = fieldsStateRowsSlice.actions;
