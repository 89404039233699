import { useParams } from "react-router";

interface UseReportModeResult {
  mode: "build" | "run";
}

const useReportMode = (): UseReportModeResult => {
  const starParam = useParams()["*"] || "";
  const mode = starParam.startsWith("builder") ? "build" : "run";

  return { mode };
};
export default useReportMode;
