import { Button, Grid2, Typography } from "@mui/material";
import { useParams } from "react-router";
import StyledPage from "../../../shared/components/infoPage/StyledPage";
import { useLocalization } from "../../hooks/useLocalization";

interface Props {
  getReportsUrl: (clientCode: string) => string;
}

const ReportNotFoundOrAccessForbidden = ({ getReportsUrl }: Props) => {
  const { common: locale } = useLocalization();
  const { client } = useParams<{ client: string }>();

  return (
    <StyledPage containerProps={{ alignItems: "center", justifyContent: "center", direction: "column", gap: 3 }}>
      <Grid2>
        <svg width="55" height="74" viewBox="0 0 55 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M39.2499 71.7H1.6499V2.30005H53.3499V57.6L48.1499 62.8"
            stroke="#BDBDBD"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.3499 20.6001H41.5499"
            stroke="#BDBDBD"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M13.3499 32H41.5499" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M13.3499 43.5H41.5499"
            stroke="#BDBDBD"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Grid2>
      <Typography variant="h6">{locale.report_not_found}</Typography>
      {client && (
        <Button variant="outlined" color="secondary" onClick={() => (window.location.href = getReportsUrl(client))}>
          Back to Report list
        </Button>
      )}
    </StyledPage>
  );
};

export default ReportNotFoundOrAccessForbidden;
