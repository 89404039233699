import { Box, Collapse, Grid2 } from "@mui/material";
import { JSX, PropsWithChildren, useState } from "react";
import ExpandCollapseButton from "../builder/ExpandCollapseButton";

interface Props {
  collapseElement?: JSX.Element | JSX.Element[] | undefined;
  expandCollapsePaddingTop?: number;
}

export const CollapseContainer = (props: PropsWithChildren<Props>) => {
  const { collapseElement, children, expandCollapsePaddingTop } = props;

  const [showFields, setShowFields] = useState(true);

  return (
    <Grid2 container sx={{ position: "relative", width: "unset" }}>
      <Collapse
        orientation="horizontal"
        in={showFields}
        sx={{
          ".MuiCollapse-wrapperInner": {
            display: "grid",
            flex: 1,
          },
        }}
      >
        {children}
      </Collapse>
      <Collapse
        orientation="horizontal"
        in={!showFields}
        sx={{
          ".MuiCollapse-wrapperInner": {
            display: "grid",
            flex: 1,
          },
        }}
      >
        <Box
          sx={{
            borderRight: "1px solid #E0E0E0",
            pt: 5,
            width: "63px",
          }}
        >
          <Box sx={{ opacity: showFields ? 0 : 1, transition: "1s ease" }}>{!showFields && collapseElement}</Box>
        </Box>
      </Collapse>
      <ExpandCollapseButton
        variant={showFields ? "collapse" : "expand"}
        paddingTop={expandCollapsePaddingTop}
        onClick={() => setShowFields(!showFields)}
      />
    </Grid2>
  );
};

export default CollapseContainer;
