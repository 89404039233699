import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ListItemIcon, Menu, Typography } from "@mui/material";
import { useCallback } from "react";
import AuthorizedBox from "../../../../../../shared/components/AuthorizedBox";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import EditReportIcon from "../../../../../../shared/icons/EditReportIcon";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  x: number;
  y: number;
  measure: MeasureDescriptor;
  onExplain: (measure: MeasureDescriptor) => void;
  onChartOfAccounts: (measure: MeasureDescriptor) => void;
  onEdit: (measure: MeasureDescriptor) => void;
  onDelete: (measure: MeasureDescriptor) => void;
  onClose: () => void;
  onUsedByReports: (measure: MeasureDescriptor) => void;
}

const MeasureMenu = ({
  x,
  y,
  measure,
  onExplain,
  onChartOfAccounts,
  onEdit,
  onDelete,
  onClose,
  onUsedByReports,
}: Props) => {
  const handleExplain = useCallback(() => {
    onExplain(measure);
  }, [measure, onExplain]);

  const handleChartOfAccounts = useCallback(() => {
    onChartOfAccounts(measure);
  }, [measure, onChartOfAccounts]);

  const handleEdit = useCallback(() => {
    onEdit(measure);
  }, [measure, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete(measure);
  }, [measure, onDelete]);

  const handleUsedByReports = useCallback(() => {
    onUsedByReports(measure);
  }, [onUsedByReports, measure]);

  return (
    <Menu
      open
      anchorReference="anchorPosition"
      anchorPosition={{ top: y + 15, left: x }}
      onClose={onClose}
      slotProps={{ paper: { sx: { width: 200 } } }}
    >
      <MenuItemStyled selected={false} onClick={handleExplain}>
        <ListItemIcon>
          <InfoOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography>Explain...</Typography>
      </MenuItemStyled>
      {measure.capabilities.chartOfAccountsAllowed && (
        <MenuItemStyled onClick={handleChartOfAccounts}>
          <ListItemIcon>
            <AccountTreeOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
          </ListItemIcon>
          <Typography>Chart of Accounts...</Typography>
        </MenuItemStyled>
      )}
      {measure.custom && (
        <>
          <AuthorizedBox permissions={["ManageCustomMeasures"]}>
            <MenuItemStyled onClick={handleEdit}>
              <ListItemIcon>
                <EditReportIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
              </ListItemIcon>
              <Typography>Edit</Typography>
            </MenuItemStyled>
          </AuthorizedBox>

          <AuthorizedBox permissions={["ManageReports"]}>
            <MenuItemStyled onClick={handleUsedByReports}>
              <ListItemIcon>
                <FormatListBulletedRoundedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
              </ListItemIcon>
              <Typography>Where-Used List</Typography>
            </MenuItemStyled>
          </AuthorizedBox>

          <AuthorizedBox permissions={["ManageCustomMeasures"]}>
            <MenuItemStyled onClick={handleDelete}>
              <ListItemIcon>
                <DeleteOutlineIcon sx={(theme) => ({ color: theme.palette.error.light })} />
              </ListItemIcon>
              <Typography>Delete</Typography>
            </MenuItemStyled>
          </AuthorizedBox>
        </>
      )}
    </Menu>
  );
};

export default MeasureMenu;
