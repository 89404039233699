import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TuneIcon from "@mui/icons-material/Tune";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid2, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import biClient from "../../../../api/biApi";
import { useDrillDownExport } from "../../../../hooks/drillDownExport";
import { DrillDownState } from "../../../../hooks/useDrillDownState";
import { useLocalization } from "../../../../hooks/useLocalization";
import WarningIcon from "../../../../icons/WarningIcon";
import { selectDrillDownItems } from "../../../../store/drilldownSlice";

interface Props {
  state: DrillDownState;
  onShowColumnsOptions: () => void;
}

export default function ToolBar({ state, onShowColumnsOptions }: Props) {
  const exporting = useDrillDownExport(biClient.requestDrillDownExport);
  const { drilldown: locale } = useLocalization();
  const configuration = useSelector(selectDrillDownItems);

  const exportAllowed = state.rowsTotal <= 50000;

  const exportNotAllowedTooltipMessage = React.useMemo(() => {
    return exportAllowed ? "" : locale.export_max_count_exceeded;
  }, [exportAllowed, locale.export_max_count_exceeded]);

  const exportToExcel = () => {
    if (exportAllowed) {
      exporting.requestExport(state.drillDownConfig);
    }
  };

  if (!configuration || configuration.length === 0) {
    return <></>;
  }

  return (
    <Grid2 container sx={{ flexDirection: "row", alignItems: "center", gap: 1.5, width: "100%" }}>
      <HorizontalFill />
      <Tooltip
        title={exportNotAllowedTooltipMessage}
        arrow
        slotProps={{
          popper: {
            sx: (theme) => ({
              [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
              },
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
              },
            }),
          },
        }}
      >
        <Box>
          <LoadingButton
            variant="outlined"
            color="secondary"
            disabled={exporting.exporting || state.loading}
            startIcon={exportAllowed ? <ExitToAppIcon /> : <WarningIcon />}
            loading={exporting.exporting}
            onClick={exportToExcel}
          >
            Export
          </LoadingButton>
        </Box>
      </Tooltip>
      <Button variant="outlined" color="secondary" startIcon={<TuneIcon />} onClick={onShowColumnsOptions}>
        {locale.column_options_label}
      </Button>
    </Grid2>
  );
}
