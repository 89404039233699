import { TabularDataCell } from "../../../../../../../shared/reporting/api/biClient.types.ts";
import { DataGridTooltipWrapper } from "../../../../common/DataGridTooltipWrapper.tsx";
import { Stack, Typography, TypographyProps } from "@mui/material";
import DimensionStartIcon from "../../../../common/DimensionStartIcon.tsx";
import DimensionBooleanValue from "../../../../common/DimensionBooleanValue.tsx";

interface Props {
  cell: TabularDataCell | undefined;
  formattedValue: string | undefined;
  typographyProps?: TypographyProps;
}

const DimensionCellDisplayValue = ({ cell, formattedValue, typographyProps }: Props) => {
  return (
    <DataGridTooltipWrapper tooltip={cell?.toolTip}>
      <Stack direction="row" alignItems="center" spacing={1} className="value-container">
        {formattedValue && <DimensionStartIcon cell={cell} />}
        {isBoolean(cell?.value) ? (
          <DimensionBooleanValue value={cell?.value} />
        ) : (
          <Typography sx={{ textDecoration: cell?.toolTip ? "underline dotted" : "inherit" }} {...typographyProps}>
            {formattedValue}
          </Typography>
        )}
      </Stack>
    </DataGridTooltipWrapper>
  );
};

const isBoolean = (value?: unknown): value is boolean => typeof value === "boolean";

export default DimensionCellDisplayValue;
