import { JSX, useCallback, useReducer, useState } from "react";

export enum ExportActionType {
  UPDATE = "UPDATE",
}

export type UpdateAction = {
  type: ExportActionType.UPDATE;
  payload: Partial<ExportState>;
};

export type ExportState = {
  exporting: boolean;
  warning?: string;
  exportAllowed: boolean;
};

const initialState: ExportState = {
  exporting: false,
  exportAllowed: true,
};

const reducer = (state: ExportState, action: UpdateAction) => {
  switch (action.type) {
    case ExportActionType.UPDATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export type ExportStateReturnType = ExportState & {
  actions: {
    getRenderMenu?: (renderMenuCallback: () => void) => JSX.Element | JSX.Element[];
    update: (state: Partial<ExportState>) => void;
    setExportMenuCallback: (callback: (renderMenuCallback: () => void) => JSX.Element | JSX.Element[]) => void;
  };
};

export const useExportState = (): ExportStateReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [exportMenuFunc, setExportMenuFunc] = useState<{
    callback: ((renderMenuCallback: () => void) => JSX.Element | JSX.Element[]) | undefined;
  }>({
    callback: undefined,
  });

  const doSetExportMenu = useCallback(
    (callback: (renderMenuCallback: () => void) => JSX.Element | JSX.Element[]) => {
      setExportMenuFunc({ callback });
    },
    [setExportMenuFunc]
  );

  const updateState = (state: Partial<ExportState>) => {
    dispatch({ type: ExportActionType.UPDATE, payload: state });
  };

  return {
    ...state,
    actions: {
      getRenderMenu: exportMenuFunc.callback,
      update: updateState,
      setExportMenuCallback: doSetExportMenu,
    },
  };
};
