import { FieldConfiguration, MeasureUnit, ReportField } from "../../../../shared/reporting/api/biClient.types";
import { isAggregation, isIrr, isMeasure } from "./fieldsHelper";
import { getMeasureAmountTypeField, getMeasureCalculateByField } from "./isConfigurationValid";

const getTrackedMeasureProperties = (fields: ReportField[]): MeasureInfoTracked[] => {
  return fields.map((measure) => {
    const field: MeasureInfoTracked = {
      guid: measure.config.guid,
      dateRange: measure.config.dateRange,
      standalone: measure.config.standalone,
      calculateByField: getMeasureCalculateByField(measure),
      blankZero: measure.config.blankZero,
      reverseSign: measure.config.reverseSign,
      customLabel: measure.config.customLabel,
      format: measure.config.format,
      hideBlankValues: measure.config.hideBlankValues,
      hideZeroValues: measure.config.hideZeroValues,
    };

    if (isMeasure(measure)) {
      field.units = measure.meta.units;
      field.amountType = getMeasureAmountTypeField(measure);
    }

    if (isIrr(measure)) {
      field.cashFlowFrequency = measure.config.cashFlowFrequency;
      field.periodDateField = measure.config.periodDateField;
    }

    if (isAggregation(measure)) {
      field.aggregation = measure.config.aggregation;
    }

    return field;
  });
};

export type MeasureInfoTracked = Pick<
  FieldConfiguration,
  | "guid"
  | "dateRange"
  | "standalone"
  | "calculateByField"
  | "blankZero"
  | "reverseSign"
  | "customLabel"
  | "format"
  | "hideBlankValues"
  | "hideZeroValues"
  | "cashFlowFrequency"
  | "periodDateField"
  | "amountType"
  | "aggregation"
> & {
  units?:
    | {
        [key: string]: MeasureUnit;
      }
    | undefined
    | null;
};

export default getTrackedMeasureProperties;
