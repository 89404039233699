import { Stack, Typography, useTheme } from "@mui/material";
import DataGrid from "../../../../../../shared/components/grid/DataGrid.tsx";
import { UsedByReport } from "../../../../../api/biApi.types.ts";
import { columns } from "./usedByReportsGridProvider.tsx";
import { getBiDataGridStyles } from "../../../../../utilities/Utilities.tsx";
import { useMemo } from "react";
import { getMergedSx } from "../../../../../../shared/hooks/useMergedSx.ts";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper.tsx";

interface Props {
  reports: UsedByReport[];
  minRows: number;
  maxRows: number;
}

const gridSx = {
  ".MuiDataGrid-columnHeaders [role=row]": {
    bgcolor: "#fff",
    ".MuiDataGrid-columnHeader": {
      bgcolor: "#fff",
    },
  },
  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },
};

const columnHeaderHeight = 28;
const rowHeight = 38;

const UsedByReportsGrid = ({ reports, minRows, maxRows }: Props) => {
  const theme = useTheme();

  const sx = useMemo(
    () => getMergedSx(theme, getBiDataGridStyles, gridSx),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const containerHeight = Math.max(minRows, Math.min(maxRows, reports.length)) * rowHeight + columnHeaderHeight + 2;

  return (
    <Stack height={containerHeight} width={"100%"}>
      <DataGrid
        rows={reports}
        columns={columns}
        sx={sx}
        columnHeaderHeight={columnHeaderHeight}
        rowHeight={rowHeight}
        slots={{
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">{"No usages"}</Typography>
            </CenteredWrapper>
          ),
        }}
      />
    </Stack>
  );
};

UsedByReportsGrid.displayName = "UsedByReportsGrid";

export default UsedByReportsGrid;
