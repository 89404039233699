import { useSelector } from "react-redux";
import { ChartOfAccountsLedgerItem, DrillDownInfo } from "../../../../../api/biApi.types";
import { selectAccountBookSettings } from "../../../../../store/clientSettingsSlice";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import ChartOfAccountsListContainer from "./ChartOfAccountsListContainer";
import ChartOfAccountsMultiBookListContainer from "./ChartOfAccountsMultiBookListContainer";

interface Props {
  configuration: DrillDownConfiguration;
  info: DrillDownInfo;
  chartOfAccounts: ChartOfAccountsLedgerItem[];
}
const LedgerAccountsWrapper = (props: Props) => {
  const accountingBookSettings = useSelector(selectAccountBookSettings);

  return (
    <>
      {!accountingBookSettings?.multiBook && <ChartOfAccountsListContainer {...props} />}
      {accountingBookSettings?.multiBook && <ChartOfAccountsMultiBookListContainer {...props} />}
    </>
  );
};

export default LedgerAccountsWrapper;
