import { Collapse, Divider, Grid2 } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import { formatConditionCaption } from "../../../pivot/table/conditions/conditionsHelper";
import { ConditionField } from "../../../Types";
import { getIcon } from "../../fields/FieldItem.helper";
import { ChartOfAccountsContextItem, useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";
import { getFilterUniqueId } from "../helpers/chartOfAccountsContextHelper";
import { FilterFieldItem } from "./FilterFieldItem";
import FilterFieldSubItem from "./FilterFieldSubItem";

interface Props {
  condition: ConditionField;
  chartOfAccountItems?: ChartOfAccountsItem[];
  index: number;
  parentNode: ChartOfAccountsContextItem | null;
}

export const Filter = ({ condition, chartOfAccountItems, parentNode, index }: Props) => {
  const { getNode } = useChartOfAccountsContext();
  const [expanded, setExpanded] = useState(false);
  const filterId = useMemo(
    () => getFilterUniqueId(index, condition, parentNode?.identifier),
    [parentNode, index, condition]
  );

  const selectedValues = useMemo(() => {
    if (condition.config.filter?.values.length === 0) return [];
    return condition.meta.dictionary.filter((d) =>
      condition.config.filter.values.some((sv) => d[condition.meta.keyFieldName || ""] === sv)
    );
  }, [condition.config.filter.values, condition.meta.dictionary, condition.meta.keyFieldName]);

  const formatCaption = useCallback(
    (field: ConditionField) => {
      if (expanded) return undefined;
      return formatConditionCaption(field, false);
    },
    [expanded]
  );

  return (
    <Grid2 container sx={{ position: "relative", flexDirection: "column", width: "100%" }}>
      <FilterFieldItem
        field={condition}
        formatCaption={formatCaption}
        isLoading={condition.dictionary?.loading === true}
        startIcon={getIcon(condition.meta)}
        onToggle={setExpanded}
        disableExpanding={selectedValues.length === 0}
        parentNode={parentNode}
        filterId={filterId}
      />
      <Grid2 container sx={{ position: "relative", flexDirection: "column", width: "100%" }}>
        <Collapse orientation="vertical" in={expanded}>
          <Divider />
          {selectedValues.map((item, i) => (
            <FilterFieldSubItem
              key={`filter-item-${i}`}
              chartOfAccountItems={chartOfAccountItems}
              condition={condition}
              dictionary={item}
              parentNode={getNode(filterId, parentNode)}
            />
          ))}
        </Collapse>
      </Grid2>
    </Grid2>
  );
};

export default Filter;
