import { Grid2, Select, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import {
  DimensionField,
  FieldConfiguration,
  FieldConfigurationType,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { DimensionNames } from "../../../../utilities/DimensionNames";
import { ConditionField } from "../../Types";
import { isMeasureConfigurationValid, MeasureValidReturnType } from "../../utils/isConfigurationValid";

interface Props {
  measure: ReportField;
  fields: DimensionField[];
  conditions: ConditionField[];
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function PeriodDateFieldMenuItem({ measure, fields, conditions, saveChanges }: Props) {
  const dimensions = useSelector(selectDimensions);

  const measureValidity = useMemo(
    () => isMeasureConfigurationValid(conditions, fields, dimensions, measure),
    [conditions, fields, dimensions, measure]
  );

  if (!measureValidity.valid || measure.config.type !== FieldConfigurationType.Irr) {
    return null;
  }

  return <PeriodDateField measure={measure} measureValidity={measureValidity} saveChanges={saveChanges} />;
}

const possibleFields = [DimensionNames.PostingDate, DimensionNames.TransactionDate];

interface PeriodDateFieldProps {
  measure: ReportField;
  measureValidity: MeasureValidReturnType;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

function PeriodDateField({ measure, measureValidity, saveChanges }: PeriodDateFieldProps) {
  const dimensions = useSelector(selectDimensions);
  const possibleDimensions = useMemo(() => dimensions.filter((d) => possibleFields.includes(d.name)), [dimensions]);
  const selectedField = useMemo(
    () =>
      possibleDimensions.find(
        (f) => f.name === (measure.config.periodDateField ?? measureValidity.referenceDateField?.name)
      ),
    [measure.config.periodDateField, measureValidity.referenceDateField, possibleDimensions]
  );

  const handleChange = useCallback(
    (fieldName: string) => {
      const periodDateField = measureValidity.referenceDateField?.name === fieldName ? undefined : fieldName;
      saveChanges({ periodDateField }, false);
    },
    [measureValidity.referenceDateField?.name, saveChanges]
  );

  return (
    <Grid2 container sx={{ alignItems: "center", width: "100%" }}>
      <Typography>Period Date Field</Typography>
      <HorizontalFill />
      <Select
        value={selectedField?.name}
        sx={{
          width: 208,
          ".MuiSelect-select": {
            py: 0.7,
            alignContent: "center",
          },
        }}
        onChange={(evt) => handleChange(evt.target.value)}
      >
        {possibleDimensions.map((value) => (
          <MenuItemStyled key={value.name} value={value.name}>
            <Stack direction="row" flex={1}>
              <Typography variant="caption">{value.caption}</Typography>
            </Stack>
          </MenuItemStyled>
        ))}
      </Select>
    </Grid2>
  );
}
