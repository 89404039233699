import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton.tsx";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader.tsx";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext.tsx";
import useFetch from "../../../../../../shared/hooks/useFetch.ts";
import BinIcon from "../../../../../../shared/icons/BinIcon.tsx";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types.ts";
import { formatString } from "../../../../../../shared/utilities/stringFormatter.ts";
import biClient from "../../../../../api/biApi.ts";
import { useLocalization } from "../../../../../hooks/useLocalization.tsx";
import { metaDataActions } from "../../../../../store/metaDataSlice.ts";
import UsedByReports from "../used-by/UsedByReports.tsx";
import DeleteCustomMeasureWarning from "./DeleteCustomMeasureWarning.tsx";

interface Props {
  measure: MeasureDescriptor;
  onCancel: () => void;
}

export const DeleteMeasureDialog = ({ measure, onCancel }: Props) => {
  const dispatch = useDispatch();
  const { custom_measure: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [saving, setSaving] = useState(false);
  const getCustomMeasureReportsUsage = useCallback(
    () => biClient.getUsedByReports(measure.id ?? measure.name),
    [measure.id, measure.name]
  );
  const [reportsUsageResponse, fetchError, { isFetching }] = useFetch(getCustomMeasureReportsUsage);

  const onDeleteMeasure = useCallback(() => {
    setSaving(true);
    biClient
      .deleteCustomMeasure(measure.id ?? measure.name)
      .then(() => {
        dispatch(metaDataActions.deleteMeasure(measure));
        sendNotification(formatString(locale.measure_deleted, measure.caption));
        onCancel();
      })
      .catch(() => {
        sendNotificationError(locale.delete_error);
        setSaving(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure.name, locale.delete_error, onCancel]);

  return (
    <Dialog open fullWidth PaperProps={{ sx: { maxWidth: 514, minHeight: 205, height: "auto" } }}>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onCancel} />
      {isFetching && !fetchError && (
        <Box px={3} py={2.5}>
          <InlineLoader />
        </Box>
      )}
      {fetchError && !isFetching && (
        <Box px={3} py={2.5}>
          <Alert severity="error" sx={{ width: "100%" }}>
            {locale.fetch_reports_error}
          </Alert>
        </Box>
      )}
      {!fetchError && !isFetching && (
        <DialogContent>
          <Stack alignItems="center" spacing={2}>
            <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6" textAlign="center">
                Delete {measure.caption} Custom Measure?
              </Typography>
              <Typography color="text.secondary" textAlign="center">
                {locale.delete_measure_text}
              </Typography>
            </Stack>
            {reportsUsageResponse && reportsUsageResponse.reports.length > 0 && (
              <Stack spacing={2}>
                <DeleteCustomMeasureWarning />
                <UsedByReports reports={reportsUsageResponse.reports} minRows={3} maxRows={6} />
              </Stack>
            )}
          </Stack>
        </DialogContent>
      )}
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" disabled={saving} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          color="error"
          disabled={saving || isFetching || !!fetchError}
          loading={saving}
          onClick={onDeleteMeasure}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMeasureDialog;
