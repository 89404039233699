import { useSelector } from "react-redux";
import { MeasureDescriptor } from "../../../../../shared/reporting/api/biClient.types";
import { ChartOfAccounts } from "../../../../api/biApi.types";
import { selectAccountBookSettings } from "../../../../store/clientSettingsSlice";
import LedgerAccountsContainer from "./LedgerAccountsContainer";
import LedgerAccountsMultiBookContainer from "./LedgerAccountsMultiBookContainer";

interface Props {
  chartOfAccounts: ChartOfAccounts | undefined;
  measure: MeasureDescriptor;
}
const LedgerAccountsWrapper = (props: Props) => {
  const accountingBookSettings = useSelector(selectAccountBookSettings);

  return (
    <>
      {!accountingBookSettings?.multiBook && <LedgerAccountsContainer {...props} />}
      {accountingBookSettings?.multiBook && <LedgerAccountsMultiBookContainer {...props} />}
    </>
  );
};

export default LedgerAccountsWrapper;
