import { Grid2, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  showBorder?: boolean;
  sx?: SxProps<Theme>;
}

export const StyleItem = ({ showBorder, sx, children }: PropsWithChildren<Props>) => {
  return (
    <Grid2
      container
      sx={{
        flexDirection: "column",
        borderBottom: showBorder ? "1px dashed #E0E0E0" : "none",
        pb: 1,
        gap: 0.5,
        width: "100%",
        ...sx,
      }}
    >
      {children}
    </Grid2>
  );
};

export default StyleItem;
