import { Grid2, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../../../shared/reporting/utils/checkUnitTable";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import {
  CellDrillDownInfoType,
  CellDrillDownInfoTypes,
  ChartOfAccountsDataItem,
  ChartOfAccountsLedgerItem,
  DrillDownInfo,
} from "../../../../../api/biApi.types";
import { selectAccountBookSettings } from "../../../../../store/clientSettingsSlice";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { drillDownActions } from "../../../../../store/drilldownSlice";
import { PersistedTabPanel } from "../../../../common/PersistedTabPanel";
import { useChartOfAccountsContext } from "../../chartOfAccounts/contexts/ChartOfAccountsContext";
import { Book, getAccountingBooks } from "../../chartOfAccounts/getAccountingBooks";
import LedgerAccountsList from "./LedgerAccountsList";

interface Props {
  configuration: DrillDownConfiguration;
  info: DrillDownInfo;
  chartOfAccounts: ChartOfAccountsLedgerItem[];
}

export default function ChartOfAccountsMultiBookListContainer({ configuration, info, chartOfAccounts }: Props) {
  const dispatch = useDispatch();
  const accountingBookSettings = useSelector(selectAccountBookSettings);
  const { selectedAccounts, scrollTargetAccount } = useChartOfAccountsContext();
  const glAccounts = chartOfAccounts.find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Gl));
  const memoAccounts = chartOfAccounts.find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Memo));

  const hasGl = glAccounts && glAccounts.items.length > 0;
  const hasMemo = memoAccounts && memoAccounts.items.length > 0;

  const glInfo = info.items.find((item) => checkUnitTable(item.table, MeasureUnitTable.Gl));
  const memoInfo = info.items.find((item) => checkUnitTable(item.table, MeasureUnitTable.Memo));

  const books = useMemo(
    () => getAccountingBooks(!!hasGl, !!hasMemo, accountingBookSettings),
    [accountingBookSettings, hasGl, hasMemo]
  );

  const [selectedTab, setSelectedTab] = useState(books[0]?.code);

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      const isGl = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Gl))
        ?.items.find((account) => selectedAccounts.includes(account.accountNo));
      if (isGl) {
        setSelectedTab(isGl.accountingBook);
        return;
      }
      const isMemo = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Memo))
        ?.items.some((account) => selectedAccounts.includes(account.accountNo));
      if (isMemo) {
        setSelectedTab(null);
      }
    }
  }, [chartOfAccounts, selectedAccounts]);

  useEffect(() => {
    if (scrollTargetAccount?.accountNo) {
      const isGl = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Gl))
        ?.items.find((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isGl) {
        setSelectedTab(isGl.accountingBook);
        return;
      }
      const isMemo = chartOfAccounts
        .find((coa) => checkUnitTable(coa.measureUnitTable, MeasureUnitTable.Memo))
        ?.items.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isMemo) {
        setSelectedTab(null);
      }
    }
  }, [chartOfAccounts, scrollTargetAccount?.accountNo]);

  const handleSubDrillDown = useCallback(
    (itemInfo: CellDrillDownInfoTypes | undefined, row: ChartOfAccountsDataItem) => {
      const newConfiguration = createSubDrillDown(row, configuration, itemInfo || info.combined);
      if (newConfiguration !== undefined) dispatch(drillDownActions.add(newConfiguration));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const filterChartOfAccounts = useCallback(
    (book: Book) => {
      if (book.ledger === MeasureUnitTable.Memo) {
        return memoAccounts?.items;
      }
      return glAccounts?.items.filter((item) => item.accountingBook === book.code);
    },
    [glAccounts?.items, memoAccounts?.items]
  );
  return (
    <Grid2 container sx={{ py: 2.5, pr: 3, pl: 1.5, gap: 2, flexDirection: "column", width: "100%" }}>
      <Tabs value={selectedTab} sx={{ pl: 2.5 }} onChange={(_, value: string | null) => setSelectedTab(value)}>
        {books.map((book) => (
          <Tab key={book.key} label={book.title} value={book.code} />
        ))}
      </Tabs>
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        {books.map((book) => (
          <PersistedTabPanel key={book.key} value={book.code} selectedTab={selectedTab}>
            <LedgerAccountsList
              configuration={configuration}
              info={book.ledger === MeasureUnitTable.Gl ? glInfo : memoInfo}
              items={filterChartOfAccounts(book) || []}
              ledger={book.ledger}
              accountingBook={book.code}
              onDrillDown={handleSubDrillDown}
            />
          </PersistedTabPanel>
        ))}
      </Grid2>
    </Grid2>
  );
}

function createSubDrillDown(
  row: ChartOfAccountsDataItem,
  configuration: DrillDownConfiguration,
  info: CellDrillDownInfoTypes
) {
  const measure = getSubDrillDownMeasure(configuration, row);

  const newConfiguration: DrillDownConfiguration = {
    id: generateGuid(),
    measure,
    cell: {
      value: row.amount,
      formattedValue: row.amountFormatted || "",
    },
    info: {
      combined: { ...info, drillDownInfoType: CellDrillDownInfoType.Default, conditions: row.conditions },
      items: [],
    },
    onlyLedger: true,
    chartOfAccounts: false,
  };

  return newConfiguration;
}

function getSubDrillDownMeasure(configuration: DrillDownConfiguration, row: ChartOfAccountsDataItem) {
  const measure = cloneDeep(configuration.measure);
  if (measure !== undefined) {
    measure.config.customLabel = row.accountName;
  }

  return measure;
}
