import { Collapse, Grid2, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren, useState } from "react";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import ExpandCollapseButton from "./ExpandCollapseButton";

export default function StyleGroup({ caption, children }: PropsWithChildren<{ caption: string }>) {
  const [expanded, setExpanded] = useState(true);

  return (
    <Grid2 container sx={{ flexDirection: "column", width: "100%" }}>
      <Grid2 container sx={{ alignItems: "center", height: "36px", px: "1.3rem", bgcolor: grey[100], width: "100%" }}>
        <Typography variant="subtitle1">{caption}</Typography>
        <HorizontalFill />
        <ExpandCollapseButton expanded={expanded} onChange={setExpanded} />
      </Grid2>
      <Collapse in={expanded} sx={{ mx: "1.3rem", my: 2 }}>
        {children}
      </Collapse>
    </Grid2>
  );
}
