import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import { Menu } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionMenuItem from "../../../../../shared/components/ActionMenuItem";
import ReportAuthorizedBox from "../../../../../shared/components/ReportAuthorizedBox";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import RenameReportIcon from "../../../../../shared/icons/RenameReportIcon";
import {
  ChangeReportGroupRequest,
  RenameReportRequest,
  Report,
  ReportAccessType,
} from "../../../../../shared/reporting/api/biClient.types";
import ChangeReportGroup from "../../../../../shared/reporting/components/reports/ChangeReportGroup";
import DeleteReport from "../../../../../shared/reporting/components/reports/DeleteReport";
import RenameReport from "../../../../../shared/reporting/components/reports/RenameReport";
import { useEnhancedBiApiClientProvider } from "../../../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { currentReportActions, selectCurrentReportAuthorizationInfo } from "../../../../store/currentReportSlice";

interface Props {
  anchorEl: HTMLElement | null;
  report: Report;
  hideChangeGroup?: boolean;
  onClose: () => void;
  onDeleted?: () => void;
}

export default function ReportActions({ anchorEl, report, onClose, onDeleted, hideChangeGroup }: Props) {
  const { renameReport, changeReportGroup, deleteReports } = useEnhancedBiApiClientProvider();
  const { sendNotification } = useNotificationContext();
  const dispatch = useDispatch();
  const { report: locale } = useLocalization();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>();
  const [openRenameDialog, setOpenRenameDialog] = React.useState<boolean>();
  const [openChangeLevelDialog, setOpenChangeLevelDialog] = React.useState<boolean>();
  const [open, setOpen] = React.useState(() => !!anchorEl);
  const reportAuthorization = useSelector(selectCurrentReportAuthorizationInfo);

  const onDeleteReport = React.useCallback(() => {
    setOpen(false);
    setOpenDeleteDialog(true);
  }, [setOpenDeleteDialog]);

  const onRenameReport = React.useCallback(() => {
    setOpen(false);
    setOpenRenameDialog(true);
  }, [setOpenRenameDialog]);

  const onChangeLevelReport = React.useCallback(() => {
    setOpen(false);
    setOpenChangeLevelDialog(true);
  }, [setOpenChangeLevelDialog]);

  React.useEffect(() => {
    if (openDeleteDialog === false || openRenameDialog === false || openChangeLevelDialog === false) {
      onClose();
    }
  }, [openDeleteDialog, openRenameDialog, openChangeLevelDialog, onClose]);

  const handleRenameReport = React.useCallback(
    async (request: RenameReportRequest) => {
      const result = await renameReport(request);
      if (result.success && result.data) {
        return result.data;
      }
      throw new Error("Failed to rename report");
    },
    [renameReport]
  );

  const handleChangeReportGroup = React.useCallback(
    async (request: ChangeReportGroupRequest) => {
      const result = await changeReportGroup(request);
      if (result.success && result.data) {
        return result.data;
      }
      throw new Error("Failed to change report group");
    },
    [changeReportGroup]
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        slotProps={{
          paper: { sx: { width: "200px" } },
        }}
      >
        <ReportAuthorizedBox
          permissions={["ManageReports"]}
          requiredAccess={ReportAccessType.Write}
          access={reportAuthorization?.access}
        >
          <ActionMenuItem key={"rename"} icon={<RenameReportIcon />} title="Rename" onClick={onRenameReport} />
          {!hideChangeGroup && (
            <ActionMenuItem
              key={"changeLevel"}
              icon={<DriveFileMoveOutlinedIcon />}
              title="Change Group"
              onClick={onChangeLevelReport}
            />
          )}
        </ReportAuthorizedBox>
        <ReportAuthorizedBox
          permissions={["ManageReports"]}
          requiredAccess={ReportAccessType.Owner}
          access={reportAuthorization?.access}
        >
          <ActionMenuItem
            key={"delete"}
            icon={<DeleteOutlineOutlinedIcon />}
            title="Delete"
            sx={(theme) => ({ color: theme.palette.error.main, svg: { color: theme.palette.error.main } })}
            onClick={onDeleteReport}
          />
        </ReportAuthorizedBox>
      </Menu>
      {openDeleteDialog && report && (
        <DeleteReport
          reports={[report]}
          doDelete={deleteReports}
          onDeleted={() => {
            sendNotification(locale.deleted);
            onDeleted?.call(null);
          }}
          onClose={() => setOpenDeleteDialog(false)}
        />
      )}
      {openRenameDialog && report && (
        <RenameReport
          report={report}
          renameReport={handleRenameReport}
          onChanged={(r) => {
            dispatch(currentReportActions.updateReport({ name: r.name }));
            sendNotification(locale.renamed);
          }}
          onClose={() => setOpenRenameDialog(false)}
        />
      )}
      {openChangeLevelDialog && report && (
        <ChangeReportGroup
          report={report}
          changeGroup={handleChangeReportGroup}
          onChanged={(r) => {
            dispatch(currentReportActions.updateReport({ groupId: r.groupId }));
            sendNotification(locale.level_changed);
          }}
          onClose={() => setOpenChangeLevelDialog(false)}
        />
      )}
    </>
  );
}
