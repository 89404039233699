import React from "react";
import { useSelector } from "react-redux";
import { ChartStyleSettings, DimensionField, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { BuildChartReportRequest } from "../../../../api/biApi.types";
import { selectCacheSessionId, selectCurrentReport, selectSessionId } from "../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../store/devToolsSlice";
import { ConditionField, SortField } from "../../Types";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";

export default function useRequestConfigBuilder() {
  const { conditionsArea, argumentsArea, valuesArea, sortingArea } = useFieldsStateContext();
  const report = useSelector(selectCurrentReport);

  const isQueryLoggingActive = useSelector(selectLogQueries);
  const sessionId = useSelector(selectSessionId);
  const cacheSessionId = useSelector(selectCacheSessionId);

  const requestConfig = React.useMemo(() => {
    const request: BuildChartReportRequest = {
      ...createChartRequest(conditionsArea.values, argumentsArea.values, valuesArea.values, sortingArea.values),
      reportId: report?.reportId ?? "",
      sessionId,
      cacheSessionId,
      withDrilldown: true,
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [
    conditionsArea.values,
    argumentsArea.values,
    valuesArea.values,
    sortingArea.values,
    report?.reportId,
    sessionId,
    cacheSessionId,
    isQueryLoggingActive,
  ]);

  return { requestConfig };
}

function createChartRequest(
  conditions: ConditionField[],
  args: DimensionField[],
  values: ReportField[],
  sort: SortField[]
) {
  const result = {
    conditions: conditions.map((c) => c.config),
    arguments: args.map((v) => v.config),
    values: values.map((v) => v.config),
    sort: sort.map((s) => s.config),
    settings: {} as ChartStyleSettings,
  };
  return result;
}
