import { MetaItemType, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../common/fields/types/dropField.types";
import { extractMeta } from "../../common/utilities/dropFieldContainerHelper";
import { referenceDateFields } from "./referenceDateFields";

export const canDropItem = (item: FieldWrapper<DraggableFieldType> | DraggableMetaType, fields: ReportField[]) => {
  const meta = extractMeta(item);
  if (!meta) return false;
  if (meta.itemType === MetaItemType.DIMENSION) {
    if (referenceDateFields.includes(meta.name)) {
      return !fields.some((v) => referenceDateFields.includes(v.meta.name ?? ""));
    }
    return true;
  }
  return true;
};
