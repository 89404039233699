import { Grid2 } from "@mui/material";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";
import StyleGroup from "../StyleGroup";
import HorizontalAlignmentItem from "./HorizontalAlignmentItem";
import Title from "./Title";
import VerticalAlignmentItem from "./VerticalAlignmentItem";

export default function LegendStyles() {
  const { settingsArea } = useFieldsStateContext();

  return (
    <StyleGroup caption="Legend">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        <HorizontalAlignmentItem area={settingsArea} />
        <VerticalAlignmentItem area={settingsArea} />
        <Title area={settingsArea} />
      </Grid2>
    </StyleGroup>
  );
}
