import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { FunctionDescription } from "../../../../../api/biApi.types";
import { DimensionNames } from "../../../../../utilities/DimensionNames";
import { DimensionFormulaVariable, FunctionFormulaVariable, IFormulaVariable } from "./variables";

export const getDimensionVariables = (dimensions: DimensionDescriptor[]): IFormulaVariable[] => {
  return dimensions.map((dimension) => new DimensionFormulaVariable({ ...cloneDeep(dimension) }));
};

export const getFunctionVariables = (functions: FunctionDescription[]): IFormulaVariable[] => {
  return functions.map((func) => new FunctionFormulaVariable(func));
};

const availableDimensions = [
  DimensionNames.AccountCategory,
  DimensionNames.AccountNo,
  DimensionNames.AccountName,
  DimensionNames.AccountingBook,
];

export const getAvailableDimensions = (dimensions: DimensionDescriptor[], isMultibook: boolean) => {
  const actualDimensions = isMultibook
    ? availableDimensions
    : availableDimensions.filter((d) => d !== DimensionNames.AccountingBook);
  return dimensions.filter((d) => actualDimensions.includes(d.name));
};
