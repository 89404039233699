import { Grid2 } from "@mui/material";
import React from "react";
import { FieldConfiguration, MeasureDateRange, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import DateRanges from "../../common/fields/measures/DateRanges";

interface Props {
  measure: ReportField;
  onClose: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function PeriodMenuItem({ measure, onClose, saveChanges }: Props) {
  const updateDateRangeType = React.useCallback(
    (newDateRangeType: MeasureDateRange) => {
      if (measure.config.dateRange === newDateRangeType) {
        onClose();
      } else {
        saveChanges({ dateRange: newDateRangeType }, false);
      }
    },
    [measure, onClose, saveChanges]
  );

  return (
    <Grid2 container width={"100%"}>
      <DateRanges
        dateRange={measure.config.dateRange ?? MeasureDateRange.EndingBalance}
        onChange={updateDateRangeType}
      />
    </Grid2>
  );
}
