import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logError } from "../../shared/logging";
import { MetaDescriptorBase, MetaItemType } from "../../shared/reporting/api/biClient.types";
import biClient from "../api/biApi";
import { MetaDataResponse } from "../api/biApi.types";
import { metaDataActions } from "../store/metaDataSlice";
import { DataSetMetaDataState } from "../store/MetaDataState";

export default function useMetaData() {
  const dispatch = useDispatch();

  const loadMetaData = useCallback(() => {
    const load = async () => {
      try {
        const response = await biClient.loadMeta();
        if (response.success && response.data) {
          const dataSets = refineMetaDataResponse(response.data);
          dispatch(
            metaDataActions.update({
              dataSets,
              loaded: true,
              loadingFailed: false,
            })
          );
        } else {
          dispatch(metaDataActions.update({ loadingFailed: true, loaded: false }));
        }
      } catch (error) {
        logError(error, "[useMetaData] loadMeta");
        dispatch(metaDataActions.update({ loadingFailed: true, loaded: false }));
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadMetaData };
}

function setItemType<T extends MetaDescriptorBase>(objs: T[], itemType: MetaItemType) {
  objs.forEach((obj) => (obj.itemType = itemType));
  return objs;
}

function refineMetaDataResponse(data: MetaDataResponse) {
  return data.dataSets.map<DataSetMetaDataState>((dataset) => ({
    dataSetId: dataset.dataSetId,
    dimensionsStructure: dataset.dimensionsStructure,
    measuresStructure: dataset.measuresStructure,
    dimensions: setItemType(dataset.dimensions, MetaItemType.DIMENSION),
    measures: [],
    allMeasures: setItemType(dataset.measures, MetaItemType.MEASURE),
    functions: dataset.functions,
    measureGroups: dataset.measureGroups,
  }));
}
