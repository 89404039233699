// TODO #11852
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { memo } from "react";
import { DragLayerMonitor, useDragLayer } from "react-dnd";
import { AreaItemType, ReportField } from "../../../../../shared/reporting/api/biClient.types.ts";
import SortFieldItem from "../../tabular/table/SortFieldItem";
import { SortField } from "../../Types";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper.ts";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../fields/types/dropField.types.ts";
import { extractCaptionFromFieldWrapper } from "../utilities/dropFieldContainerHelper";
import AreaFieldItemDraggable from "./AreaFieldItemDraggable";
import { DraggingBox } from "./DraggingBox";
import FieldItemDraggable from "./FieldItemDraggable";

function CustomDragLayerInternal() {
  const { currentOffset, item, itemType } = useDragLayer(
    (monitor: DragLayerMonitor<FieldWrapper<DraggableFieldType> | DraggableMetaType>) => {
      return {
        isDragging: monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
      };
    }
  );

  const caption = extractCaptionFromFieldWrapper(item) || "";

  if ((itemType === AreaItemType.DIMENSION || itemType === AreaItemType.MEASURE) && currentOffset) {
    return (
      <DraggingBox sx={{ transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)` }}>
        <FieldItemDraggable field={item} caption={caption} />
      </DraggingBox>
    );
  }

  if (
    (itemType === AreaItemType.CONDITIONS ||
      itemType === AreaItemType.ROWS ||
      itemType === AreaItemType.COLUMNS ||
      itemType === AreaItemType.ARGUMENTS ||
      itemType === AreaItemType.VALUES) &&
    currentOffset
  ) {
    return (
      <DraggingBox sx={{ transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)` }}>
        <AreaFieldItemDraggable key={item.field.guid} caption={caption} />
      </DraggingBox>
    );
  }

  if (itemType === AreaItemType.FIELDS && currentOffset) {
    const tableField = item as FieldWrapper<ReportField>;
    if (isDimensionBased(tableField.field) || isMeasure(tableField.field)) {
      return (
        <DraggingBox sx={{ transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)` }}>
          <AreaFieldItemDraggable key={tableField.field.config.guid} caption={caption} />
        </DraggingBox>
      );
    }
  }

  if (itemType === AreaItemType.SORTS && currentOffset) {
    const field = item as SortField;

    return (
      <DraggingBox sx={{ transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`, width: "323px" }}>
        <SortFieldItem key={field.config.name} field={field} />
      </DraggingBox>
    );
  }

  return null;
}

const CustomDragLayer = memo(CustomDragLayerInternal, () => true);

export default CustomDragLayer;
