import { Grid2 } from "@mui/material";
import { CellDrillDownInfoBase } from "../../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import LedgerPart from "./LedgerPart";

interface Props {
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function LedgerPage({ configuration, info }: Props) {
  return (
    <Grid2 container sx={{ flex: 1, width: "100%" }}>
      <LedgerPart configuration={configuration} info={info} />
    </Grid2>
  );
}
