import { createContext, PropsWithChildren, useContext } from "react";
import {
  ClientInfo,
  Report,
  ReportConfiguration,
  ReportTemplate,
  ReportType,
} from "../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../shared/utilities/typeHelper";

type ReportEntity = Report | ReportTemplate;

interface VisualizationContextType {
  report: ReportEntity;
  isReportChanged: boolean;
  showUnsavedTooltip: boolean;
  showSaveDialog: boolean;
  isConfigurationEmpty: boolean | undefined;
  reportConfiguration: ReportConfiguration | undefined;
  unsavedChangesPopoverAnchor: HTMLButtonElement | undefined;
  companies: ClientInfo[];
  onCloseSaveChanges: () => void;
  onSaveChangesSaved: (report: ReportEntity) => void;
  onSaveChange: () => Promise<void>;
  onCloseUnsavedChanges: () => void;
  onContinueUnsavedChanges: () => void;
  onReportTypeClicked: (newType: ReportType, ref: HTMLButtonElement | null) => void;
}

const VisualizationContext = createContext<VisualizationContextType | undefined>(undefined);

export const VisualizationContextProvider = ({ children, ...props }: PropsWithChildren<VisualizationContextType>) => (
  <VisualizationContext.Provider value={props}>{children}</VisualizationContext.Provider>
);

export const useVisualizationContext = () => {
  const context = useContext(VisualizationContext);
  return defined(context);
};
