import { Grid2, Select, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { MeasureDateRange } from "../../../../../../shared/reporting/api/biClient.types";
import { getRanges } from "../../../utils/dateRangeToString";

interface Props {
  dateRange?: MeasureDateRange | undefined | null;
  onChange: (newDateRangeType: MeasureDateRange) => void;
}

export const DateRanges = ({ dateRange, onChange }: Props) => {
  const ranges = useMemo(() => getRanges(), []);

  return (
    <Grid2 container sx={{ alignItems: "center", width: "100%" }}>
      <Typography>Period</Typography>
      <HorizontalFill />
      <Select
        value={dateRange || MeasureDateRange.NetChange}
        sx={{
          width: 208,
          ".MuiSelect-select": {
            py: 0.7,
            alignContent: "center",
          },
        }}
        onChange={(evt) => onChange(evt.target.value as MeasureDateRange)}
      >
        {ranges.map((value) => (
          <MenuItemStyled
            key={value.value}
            value={value.value}
            selected={value.value === dateRange}
            onClick={() => onChange(value.value)}
          >
            <Stack direction="row" flex={1}>
              <Typography variant="caption">{value.text}</Typography>
              <HorizontalFill />
              <Typography variant="caption" color="secondary">
                {value.short}
              </Typography>
            </Stack>
          </MenuItemStyled>
        ))}
      </Select>
    </Grid2>
  );
};

export default DateRanges;
