import { Grid2, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import equal from "fast-deep-equal";
import React from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  ConditionEqualityType,
  DimensionDescriptor,
  FieldConfiguration,
  MeasureField,
} from "../../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import { selectDimensions } from "../../../../../store/metaDataSlice";

interface Props {
  measure: MeasureField;
  onUpdate: (value: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function TransferFieldMenuItem({ measure, onUpdate }: Props) {
  const dimensions = useSelector(selectDimensions);

  const possibleOptions = React.useMemo(getPossibleOptions, []);
  const option = React.useMemo(() => getOption(measure), [measure]);

  const onChangeValue = React.useCallback(
    (newValue: Values) => {
      const customConditions = rebuildCustomConditions(newValue, measure, dimensions);
      onUpdate({ customConditions, useCustomConditions: true }, false);
    },
    [measure, dimensions, onUpdate]
  );

  return (
    <Grid2 container sx={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
      <Typography>Transferred Balance</Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={option}
        fullWidth
        sx={{ width: 208 }}
        onChange={(_, value: Values | null) => {
          if (value !== null) onChangeValue(value);
        }}
      >
        {possibleOptions.map((item) => (
          <ToggleButton key={item.value} value={item.value} sx={{ py: 0.3, fontWeight: 400 }}>
            {item.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid2>
  );
}

enum Values {
  On = "on",
  Off = "off",
  Ignore = "ignore",
}

const TransferValue = "ITRANSF";
const SourceCodeDimensionName = "SourceCode";

function getPossibleOptions() {
  return [
    { value: Values.On, text: "On" },
    { value: Values.Off, text: "Off" },
    { value: Values.Ignore, text: "Ignore" },
  ];
}

function getOption(measure: MeasureField) {
  if (!measure.config.customConditions || measure.config.useCustomConditions !== true) {
    return Values.Ignore;
  }
  const sourceCodeCondition = measure.config.customConditions.find(
    (cc) => cc.filter.dimensionName === SourceCodeDimensionName
  );
  if (sourceCodeCondition === undefined) return Values.Ignore;
  if (equal(sourceCodeCondition.filter.values, [TransferValue])) {
    return sourceCodeCondition.filter.equalityType === ConditionEqualityType.NotEqual ? Values.Off : Values.On;
  }
  return Values.Ignore;
}

function rebuildCustomConditions(newValue: Values, measure: MeasureField, dimensions: DimensionDescriptor[]) {
  const customConditions = cloneDeep(measure.config.customConditions || []);

  const changeCondition = (conditionType: ConditionEqualityType) => {
    let sourceCodeCondition = customConditions?.find((cc) => cc.filter.dimensionName === SourceCodeDimensionName);
    if (sourceCodeCondition === undefined) {
      const dimension = dimensions.find((d) => d.name === SourceCodeDimensionName);
      if (dimension === undefined) return;
      sourceCodeCondition = {
        filter: { dimensionName: dimension.name, values: [TransferValue], equalityType: conditionType },
        guid: generateGuid(),
      };
      customConditions.push(sourceCodeCondition);
    } else {
      sourceCodeCondition.filter.equalityType = conditionType;
      sourceCodeCondition.filter.values = [TransferValue];
    }
  };

  if (newValue === Values.On) {
    changeCondition(ConditionEqualityType.Equal);
  } else if (newValue === Values.Off) {
    changeCondition(ConditionEqualityType.NotEqual);
  } else if (newValue === Values.Ignore) {
    const sourceCodeCondition = customConditions?.find((cc) => cc.filter.dimensionName === SourceCodeDimensionName);
    if (sourceCodeCondition !== undefined) {
      sourceCodeCondition.filter.equalityType = ConditionEqualityType.NotEqual;
      const index = customConditions.indexOf(sourceCodeCondition);
      customConditions.splice(index, 1);
    }
  }

  return customConditions;
}
