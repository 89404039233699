import { DimensionField } from "../../../../../shared/reporting/api/biClient.types";
import { AnyObject } from "../../../../../shared/types";
import { PivotRowDimensionCell } from "../../../../api/biApi.types";

export default function formatArgumentLabel(
  fields: DimensionField[],
  value: string | undefined,
  data: AnyObject[]
): string {
  const fieldId = fields[0]?.config.name;
  if (fieldId !== undefined) {
    const cell = data.find((c) => {
      const rowCell = c[fieldId] as PivotRowDimensionCell;
      return rowCell?.value === value;
    });
    if (cell !== undefined) {
      const rowCell = cell[fieldId] as PivotRowDimensionCell;
      return rowCell?.formattedValues.join(" ");
    }
  }
  return value ?? "";
}
