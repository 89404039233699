import { TokenIterator } from "./tokenIterator";
import { Token, TokenType } from "./tokenizer";

export interface INode {
  setError: (error: string) => void;
}
export interface ITokenNode extends INode {
  get token(): Token;
  onMouseOver(handler: () => void): void;
  onMouseLeave(handler: () => void): void;
}

export class NodeBinary implements INode {
  constructor(
    public left: INode,
    public right: INode
  ) {}

  setError(error: string) {
    this.left.setError(error);
    this.right.setError(error);
  }
}

export class NodeNumber implements INode {
  constructor(private token: Token) {}

  setError(error: string) {
    this.token.error = error;
  }
}

export class NodeVariable implements ITokenNode {
  constructor(public token: Token) {}

  setError(error: string) {
    this.token.error = error;
  }

  onMouseOver(handler: () => void) {
    if (this.token.element) {
      this.token.element.addEventListener("mouseover", handler);
    }
  }

  onMouseLeave(handler: () => void) {
    if (this.token.element) {
      this.token.element.addEventListener("mouseleave", handler);
    }
  }
}

export class NodeUnary implements INode {
  constructor(public node: INode) {}

  setError(error: string) {
    this.node.setError(error);
  }
}

export class NodeFunction implements ITokenNode {
  constructor(
    public token: Token,
    public args: INode[]
  ) {}

  setError(error: string) {
    this.token.error = error;
  }

  onMouseOver(handler: () => void) {
    if (this.token.element) {
      this.token.element.addEventListener("mouseover", handler);
    }
  }

  onMouseLeave(handler: () => void) {
    if (this.token.element) {
      this.token.element.addEventListener("mouseleave", handler);
    }
  }
}

export const formulaParser = (iterator: TokenIterator) => {
  function parseExpression() {
    const node = parseAddSubtract();

    if (iterator.token().tokenType !== TokenType.Eof) {
      iterator.token().error = "There is an unexpected character at the end of the formula";
      throw new Error("There is an unexpected character at the end of the formula");
    }

    return node;
  }

  function parseAddSubtract() {
    let lhs = parseMultiplyDivide();

    while (true) {
      if (iterator.token().tokenType !== TokenType.Add && iterator.token().tokenType !== TokenType.Subtract) {
        return lhs;
      }

      iterator.nextToken();

      const rhs = parseMultiplyDivide();

      lhs = new NodeBinary(lhs, rhs);
    }
  }

  function parseMultiplyDivide() {
    let lhs = parseUnary();

    while (true) {
      const token = iterator.token();
      if (token.tokenType !== TokenType.Multiply && token.tokenType !== TokenType.Divide) {
        return lhs;
      }

      iterator.nextToken();
      const rhs = parseUnary();

      lhs = new NodeBinary(lhs, rhs);
    }
  }

  function parseUnary(): INode {
    while (true) {
      if (iterator.token().tokenType === TokenType.Add) {
        iterator.nextToken();
        continue;
      }

      if (iterator.token().tokenType === TokenType.Subtract) {
        iterator.nextToken();

        const rhs = parseUnary();
        return new NodeUnary(rhs);
      }

      return parseLeaf();
    }
  }

  function parseLeaf(): INode {
    if (iterator.token().tokenType === TokenType.Number) {
      const node = new NodeNumber(iterator.token());
      iterator.nextToken();
      return node;
    }

    if (iterator.token().tokenType === TokenType.OpenParens) {
      iterator.nextToken();

      const node = parseAddSubtract();

      if (iterator.token().tokenType !== TokenType.CloseParens) {
        iterator.token().error = "Missing close parenthesis";
        throw new Error("Missing close parenthesis");
      }

      iterator.nextToken();

      return node;
    }

    if (iterator.token().tokenType === TokenType.Identifier) {
      const token = iterator.token();
      iterator.nextToken();

      if (iterator.token().tokenType !== TokenType.OpenParens) {
        return new NodeVariable(token);
      } else {
        iterator.nextToken();

        const args: INode[] = [];
        if (iterator.token().tokenType !== TokenType.CloseParens) {
          while (true) {
            const node = parseAddSubtract();
            if (!(node instanceof NodeVariable)) {
              node.setError("Unsupported argument type");
              throw new Error("Unsupported argument type");
            }
            args.push(node);

            if (iterator.token().tokenType === TokenType.Comma) {
              iterator.nextToken();
              continue;
            }

            break;
          }
        }

        if (iterator.token().tokenType !== TokenType.CloseParens) {
          iterator.token().error = "Missing close parenthesis";
          throw new Error("Missing close parenthesis");
        }

        iterator.nextToken();

        return new NodeFunction(token, args);
      }
    }
    if (iterator.token().tokenType === TokenType.Eof) {
      iterator.token().error = "The formula either contains or ends with an invalid character";
      throw new Error("The formula either contains or ends with an invalid character");
    }
    iterator.token().error = "Unable to parse the provided formula";
    throw new Error("Unable to parse the provided formula");
  }

  return parseExpression();
};
