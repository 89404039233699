import { Grid2, Typography } from "@mui/material";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import NameField from "../NameField";
import { CustomMeasureReturnType } from "../hooks/useCustomMeasure";
import GroupField from "./GroupField";

interface Props {
  measure: MeasureDescriptor | undefined;
  state: CustomMeasureReturnType;
  onError: (error?: string) => void;
}

export default function MainInfoComponent({ measure, state, onError }: Props) {
  return (
    <Grid2 sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">Main Info</Typography>
      <NameField measure={measure} state={state} onError={onError} />
      <GroupField state={state} />
    </Grid2>
  );
}
