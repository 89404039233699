import { Box, Typography } from "@mui/material";
import { GridColDef, GridColumnGroup, GridColumnNode, GridLeafColumn } from "@mui/x-data-grid-premium";
import { JSX } from "react";
import { logError } from "../../../../../shared/logging";
import { DimensionDescriptor, MeasureDateRange, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../../shared/types";
import {
  PivotColumnResponse,
  PivotRowDimensionCell,
  PivotRowMeasureCell,
  PivotRowResponse,
} from "../../../../api/biApi.types";
import { formatDimension } from "../../../../formatters/NumberFormatter";
import { styleCaption } from "../../../../utilities/Utilities";
import { DataGridTooltipWrapper } from "../../common/DataGridTooltipWrapper";
import DimensionStartIcon from "../../common/DimensionStartIcon";

export const generateDisplayValueCore = (
  field: DimensionDescriptor,
  displayValues: string[] | undefined,
  itemKey: string,
  defaultEmptyValue = ""
) => {
  const item = field.dictionary.find((d) => d[field.keyFieldName] === itemKey);
  if (!item || item[field.keyFieldName] === undefined) {
    return [defaultEmptyValue];
  }
  return (displayValues || field.displayValues)
    .map((name) => item[name])
    .filter((val): val is string => val !== undefined)
    .map((value) => formatDimension(field, value));
};

export const renderDisplayValue = (
  values: (string | undefined)[],
  toolTip: string | undefined,
  styleCallback: (value: string | undefined) => JSX.Element | string | undefined
) => {
  if (values.length === 1) {
    return (
      <Typography sx={{ textDecoration: getTextDisplayValueDecoration(toolTip) }}>
        {styleCallback(values[0])}
      </Typography>
    );
  }
  return (
    <>
      <Typography
        style={{
          color: "rgba(33, 33, 33, 0.38)",
          textDecoration: getTextDisplayValueDecoration(toolTip),
        }}
      >
        {styleCallback(values[0])}&nbsp;&nbsp;
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          textDecoration: getTextDisplayValueDecoration(toolTip),
        })}
      >
        {styleCallback(values[1])}
      </Typography>
    </>
  );
};

function getTextDisplayValueDecoration(toolTip: string | undefined) {
  return toolTip ? "underline dotted" : "none";
}

export const getCaptionByDateRangeType = (caption: string, dateRange: Maybe<MeasureDateRange>) => {
  switch (dateRange) {
    case MeasureDateRange.BeginningBalance:
      return `${caption} Beginning`;
    case MeasureDateRange.EndingBalance:
      return `${caption} Ending`;
    case MeasureDateRange.Mtd:
      return `${caption} MTD`;
    case MeasureDateRange.Qtd:
      return `${caption} QTD`;
    case MeasureDateRange.Ytd:
      return `${caption} YTD`;
    default:
      return caption;
  }
};

export function toGridColumn(column: PivotColumnResponse): GridColDef<PivotRowResponse> {
  return {
    field: column.id,
    align: column.id === "lines" ? "left" : "right",
    aggregable: false,
    disableColumnMenu: true,
    disableReorder: true,
    minWidth: 150,
    maxWidth: 500,
    description: column.toolTip,
    headerAlign: column.id === "lines" ? "left" : "right",
    sortable: false,
    renderHeader: () => {
      if (column.id === "lines") {
        return null;
      }
      return createHeaderCell(column.formattedValues || [], column.toolTip);
    },
  } as GridColDef<PivotRowResponse>;
}

export function toGridColumnGroup(column: PivotColumnResponse): GridColumnGroup {
  return {
    groupId: column.id ?? "",
    headerAlign: column.id === "lines" ? "left" : "right",
    children: column.columns?.map(toGridColumnNode) || [],
    renderHeaderGroup: () => createHeaderCell(column.formattedValues || [], column.toolTip),
  };
}

export function toGridColumnNode(column: PivotColumnResponse): GridColumnNode {
  if (column.measureGuid !== undefined) {
    const node: GridLeafColumn = { field: column.id ?? "" };

    return node;
  } else {
    return {
      groupId: column.id ?? "",
      headerAlign: "right",
      children: column.columns?.map(toGridColumnNode) || [],
      renderHeaderGroup: () => createHeaderCell(column.formattedValues || [], column.toolTip),
    };
  }
}

export function createMeasureCell(value: PivotRowMeasureCell | null | undefined, rows: ReportField[]) {
  try {
    if (!value) {
      return null;
    }
    const isFieldLeaf = value.level === rows.length - 1;
    const rowField = rows[value.level];
    if (rowField) {
      return (
        <Box
          sx={(theme) => ({
            "&:hover": value?.drillDownId && { textDecoration: "underline", cursor: "pointer" },
            color: theme.palette.text.primary,
          })}
        >
          {isFieldLeaf ? <Typography>{value.formattedValue}</Typography> : styleCaption(rowField, value.formattedValue)}
        </Box>
      );
    }
    return (
      <Box sx={(theme) => ({ color: theme.palette.text.primary })}>
        <Typography>{value.formattedValue}</Typography>
      </Box>
    );
  } catch (error) {
    logError(error, "render pivot measureCell");
    return null;
  }
}

export function createHeaderCell(formattedValues: string[], toolTip?: string) {
  try {
    return (
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: 0.5, verticalAlign: "middle" }}>
        <DataGridTooltipWrapper tooltip={toolTip}>
          {renderDisplayValue(formattedValues, toolTip, (value) => value)}
        </DataGridTooltipWrapper>
      </Box>
    );
  } catch (error) {
    logError(error, "render pivot headerCell");
    return null;
  }
}

export function createLinesCell(cell: PivotRowDimensionCell | undefined, rows: ReportField[]) {
  if (!cell) return null;
  try {
    const rowField = rows[cell.level];
    if (rowField) {
      const isFieldLeaf = cell.level === rows.length - 1;
      return (
        <DataGridTooltipWrapper tooltip={cell.toolTip}>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, height: 18 }}
            style={{ paddingLeft: cell.level * 10 + 12 }}
          >
            {cell.formattedValues && <DimensionStartIcon cell={cell} />}
            {renderDisplayValue(cell.formattedValues, cell.toolTip, (value) =>
              !isFieldLeaf ? styleCaption(rowField, value) : value
            )}
          </Box>
        </DataGridTooltipWrapper>
      );
    }
    return (
      <DataGridTooltipWrapper tooltip={cell.toolTip}>
        <Box component={"div"} display={"flex"} style={{ paddingLeft: 0 * 10 + 12 }}>
          {renderDisplayValue(cell.formattedValues, cell.toolTip, (value) => value)}
        </Box>
      </DataGridTooltipWrapper>
    );
  } catch (error) {
    logError(error, "render pivot linesCell");
    return null;
  }
}

export const createGrandTotalLinesCell = () => <Typography variant="subtitle2">Grand Total</Typography>;

export const createGrandTotalMeasureCell = (formattedValue: string | undefined) => (
  <Typography variant="subtitle2">{formattedValue}</Typography>
);

export const getDataColumns = (item: PivotColumnResponse): PivotColumnResponse[] => {
  if (item.measureGuid !== undefined) {
    return [item];
  } else if (item.columns.length > 0) {
    return item.columns.flatMap(getDataColumns);
  }
  return [];
};
