import { Grid2, Stack, Switch, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import {
  ConditionDescriptor,
  ConditionEqualityType,
  DimensionDescriptor,
  DimensionDictionary,
} from "../../../../../../shared/reporting/api/biClient.types";
import { selectAccountBookSettings } from "../../../../../store/clientSettingsSlice";
import { ConditionField } from "../../../Types";
import { getCrossFilterDictionary } from "./conditionsHelper";
import TextFilterGrid from "./TextFilter.Grid";
import TextFilterMultiBook from "./TextFilter.MultiBook";

interface Props {
  field: ConditionField;
  meta: DimensionDescriptor;
  showOnlySelectedByDefault?: boolean;
  readonly?: boolean;
  onFilterUpdated: (filter: ConditionDescriptor) => void;
}
export const TextFilterCondition = (props: Props) => {
  const { field, meta, showOnlySelectedByDefault, readonly, onFilterUpdated } = props;
  const [textFilter, setTextFilter] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>(field.config.filter?.values || []);
  const [showOnlySelected, setShowOnlySelected] = useState(showOnlySelectedByDefault || false);
  const [excludeValues, setExcludeValues] = useState(
    field.config.filter?.equalityType === ConditionEqualityType.NotEqual || false
  );
  const accountingBookSettings = useSelector(selectAccountBookSettings);
  const isMultiBookAccount = useMemo(() => {
    return (
      accountingBookSettings?.multiBook === true &&
      (field.meta.name === "AccountNo" || field.meta.name === "AccountName")
    );
  }, [accountingBookSettings?.multiBook, field.meta.name]);

  useEffect(() => {
    onFilterUpdated({
      dimensionName: meta.name,
      values: selectedValues,
      equalityType: excludeValues ? ConditionEqualityType.NotEqual : ConditionEqualityType.Equal,
    });
  }, [selectedValues, excludeValues, meta.name, onFilterUpdated]);

  const updateEqualityType = useCallback(
    (exclude: boolean) => {
      const filter: ConditionDescriptor = {
        dimensionName: field.meta.name,
        values: selectedValues,
        equalityType: exclude ? ConditionEqualityType.NotEqual : ConditionEqualityType.Equal,
      };
      onFilterUpdated(filter);
      setExcludeValues(exclude);
    },
    [field, selectedValues, onFilterUpdated]
  );

  const getOnlySelectedValues = useCallback(
    (filteredValues: DimensionDictionary[]) => {
      if (showOnlySelected) {
        return filteredValues.filter((val) => {
          const keyFieldValue = val[meta.keyFieldName] as string;
          if (keyFieldValue === undefined) return false;
          return selectedValues.indexOf(keyFieldValue) > -1;
        });
      }
      return filteredValues;
    },
    [showOnlySelected, selectedValues, meta.keyFieldName]
  );

  const values = useMemo(() => {
    const dictionary = getCrossFilterDictionary(field, meta);
    if (!textFilter) {
      return getOnlySelectedValues(dictionary);
    }
    const filter = textFilter.toLowerCase();
    const filteredValues = dictionary.filter((item) => {
      for (const key in item) {
        if (field.meta.availableFields.indexOf(key) > -1) {
          const itemValue = (item[key] as string) ?? "No Value";
          const index = itemValue.toLowerCase().indexOf(filter);
          if (index !== undefined && index > -1) {
            return true;
          }
        }
      }
      return false;
    });
    return getOnlySelectedValues(filteredValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, meta, meta.dictionary, textFilter, getOnlySelectedValues]);
  const columns = useMemo(() => getColumns(field), [field]);
  const handleSelect = useCallback(
    (newSelectedValues: string[]) => {
      setSelectedValues((prev) => [
        ...newSelectedValues,
        ...prev.filter((sv) => !values.some((v) => v[field.meta.keyFieldName] === sv)),
      ]);
    },
    [field.meta.keyFieldName, values]
  );

  return (
    <Stack sx={{ display: "flex", flex: 1, gap: "1rem" }}>
      <Grid2 flexDirection={"row"} justifyContent="stretch" display={"flex"}>
        <SearchField onSearch={setTextFilter} debounceTimeMs={500} fullWidth />
      </Grid2>
      <Grid2 pl={0.5} display={"flex"}>
        <Typography>Show only selected</Typography>
        <HorizontalFill />
        <Switch checked={showOnlySelected} onChange={(_, value) => setShowOnlySelected(value)} />
      </Grid2>
      {!readonly && (
        <Grid2 pl={0.5} display={"flex"}>
          <Typography>Exclude values</Typography>
          <HorizontalFill />
          <Switch checked={excludeValues} onChange={(_, value) => updateEqualityType(value)} />
        </Grid2>
      )}
      <Grid2 sx={{ mt: -1, overflow: "auto", maxWidth: "100%", flex: 1, display: "flex" }}>
        {isMultiBookAccount && (
          <TextFilterMultiBook
            columns={columns}
            field={field}
            values={values}
            selectedValues={selectedValues}
            readonly={readonly}
            onChanged={handleSelect}
          />
        )}
        {!isMultiBookAccount && (
          <TextFilterGrid
            columns={columns}
            field={field}
            values={values}
            selectedValues={selectedValues}
            readonly={readonly}
            onChanged={handleSelect}
          />
        )}
      </Grid2>
    </Stack>
  );
};

export default TextFilterCondition;

function getColumns(field: ConditionField) {
  return field.meta.availableFields.map(
    (f) =>
      ({
        field: f,
        disableColumnMenu: true,
        flex: 1,
        disableReorder: true,
        valueFormatter: (value, _, col) => value ?? getFallbackValue(value, col.field === field.meta.keyFieldName),
      }) as GridColDef
  );
}

function getFallbackValue(value: unknown, isKeyFieldColumn: boolean) {
  if (isKeyFieldColumn) {
    return "No Value";
  }

  return value;
}
