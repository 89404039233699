import { Divider, Grid2 } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import biClient from "../../../../../api/biApi";
import { DrillDownInfo } from "../../../../../api/biApi.types";
import useDrillDown from "../../../../../hooks/useDrillDownState";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { selectSessionId } from "../../../../../store/currentReportSlice";
import { selectMeasures, selectMeasuresStructure } from "../../../../../store/metaDataSlice";
import SplitPane from "../../../../common/split-pane/SplitPane";
import MeasureSelector from "../../chartOfAccounts/MeasureSelector";
import { ChartOfAccountsContextProvider } from "../../chartOfAccounts/contexts/ChartOfAccountsContext";
import { Properties } from "../../chartOfAccounts/properties/Properties";
import FormulaContainer from "../../customMeasure/FormulaContainer";
import { useCustomMeasure } from "../../customMeasure/hooks/useCustomMeasure";
import useDeferredDictionaryLoading from "../../hooks/useDeferredDictionaryLoading";
import ReadonlyConditions from "../ReadonlyConditions";
import LedgerAccountsWrapper from "./LedgerAccountsWrapper";

interface Props {
  configuration: DrillDownConfiguration;
  info: DrillDownInfo;
}

export default function ChartOfAccountsPart({ configuration, info }: Props) {
  const storedMeasures = useSelector(selectMeasures);
  const measuresStructure = useSelector(selectMeasuresStructure);
  const sessionId = useSelector(selectSessionId);

  const selectedMeasure = useMemo(() => {
    return storedMeasures.find((m) => m.name === configuration.measure?.meta.name);
  }, [storedMeasures, configuration.measure?.meta.name]);

  const drillDownState = useDrillDown(info.combined);
  const customMeasureState = useCustomMeasure({ measure: selectedMeasure });

  const fetchChartOfAccounts = useCallback(
    () => biClient.getChartOfAccountsDrillDown(sessionId, configuration.cell.drillDownId || ""),
    [configuration.cell.drillDownId, sessionId]
  );
  const [chartOfAccountsResp, error, loading] = useFetch(fetchChartOfAccounts);

  useDeferredDictionaryLoading({
    values: drillDownState.conditions,
    updateItem: drillDownState.actions.updateCondition,
  });

  if (!selectedMeasure) {
    return null;
  }

  if (error) {
    return <DataLoadingFailed title="Failed load Chart Of Accounts"></DataLoadingFailed>;
  }

  if (loading.isFetching) {
    return <InlineLoader />;
  }

  if (!chartOfAccountsResp) {
    return <DataLoadingFailed title="Failed load Chart Of Accounts"></DataLoadingFailed>;
  }

  return (
    <Grid2 container sx={{ flex: 1, flexWrap: "nowrap", width: "100%" }}>
      <ChartOfAccountsContextProvider>
        <SplitPane initialPrimarySize="30%" minPrimarySize="350px" minSecondarySize="350px">
          <Grid2
            container
            sx={{ p: "1.3rem", pl: 3, gap: 2, flexDirection: "column", flexWrap: "nowrap", width: "100%" }}
          >
            <ReadonlyConditions state={drillDownState} configuration={configuration} info={info.combined} />
            <Grid2 container sx={{ flex: 1, flexWrap: "nowrap", width: "100%" }}>
              <Grid2 container sx={{ flex: 1, flexDirection: "column", gap: 2, width: "100%" }}>
                <MeasureSelector
                  measures={storedMeasures}
                  measuresStructure={measuresStructure}
                  measure={selectedMeasure}
                />
                <FormulaContainer state={customMeasureState} measure={selectedMeasure} editable={false} hideCheck />
                <Divider sx={{ mx: -3 }} />
                <ScrollableFlexContainer sx={{ "& .MuiBox-root": { overflowX: "hidden" } }}>
                  <Properties state={customMeasureState} />
                </ScrollableFlexContainer>
              </Grid2>
            </Grid2>
          </Grid2>
          <LedgerAccountsWrapper
            configuration={configuration}
            info={info}
            chartOfAccounts={chartOfAccountsResp.items}
          />
        </SplitPane>
      </ChartOfAccountsContextProvider>
    </Grid2>
  );
}
