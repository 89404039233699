import { Divider, Grid2, List, ListItem, ListSubheader, Popover, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MenuItemStyled } from "../../../shared/components/MenuItemStyled";
import SearchField from "../../../shared/components/inputs/SearchField";
import { DimensionDescriptor } from "../../../shared/reporting/api/biClient.types";
import { selectDimensionsStructure } from "../../store/metaDataSlice";
import { getIcon } from "./common/fields/FieldItem.helper";

interface Props {
  dimensions: DimensionDescriptor[];
  anchorEl: HTMLElement | null;
  menuWidth: number;
  onClose: (field?: DimensionDescriptor) => void;
}

export default function DimensionSelection(props: Props) {
  const { dimensions, anchorEl, onClose, menuWidth } = props;
  const dimensionsStructure = useSelector(selectDimensionsStructure);
  const [textFilter, setTextFilter] = useState("");

  const filteredFields = useMemo(() => {
    if (!textFilter) {
      return dimensions;
    }
    return dimensions.filter((f) => f.caption.toLowerCase().indexOf(textFilter) > -1);
  }, [dimensions, textFilter]);

  const filteredStructure = useMemo(() => {
    return dimensionsStructure.groups
      .map((g) => {
        const dimensionInGroup = filteredFields.filter((ff) => g.dimensions.includes(ff.name));
        return {
          caption: g.caption,
          dimensions: dimensionInGroup,
        };
      })
      .filter((g) => g.dimensions.length > 0)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [dimensionsStructure, filteredFields]);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
            maxHeight: "350px",
            width: "100%",
            maxWidth: `${menuWidth}px`,
          },
        },
      }}
    >
      <Grid2
        sx={(theme) => ({
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "1rem",
          height: "340px",
          width: "100%",
          "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.light,
          },
        })}
      >
        <Grid2 flexDirection={"row"} justifyContent="stretch" display={"flex"} sx={{ px: 2, pt: 2, width: "100%" }}>
          <SearchField onSearch={(t) => setTextFilter(t.toLowerCase())} autoFocus debounceTimeMs={500} fullWidth />
        </Grid2>
        <Divider />
        <Grid2 container sx={{ position: "relative", display: "flex", flex: 1, mt: -1, width: "100%" }}>
          <Grid2 container sx={{ position: "absolute", height: "100%", width: "100%", overflow: "auto", pl: 2 }}>
            <List sx={{ py: 0, width: "100%" }}>
              {filteredStructure.map((group) => (
                <ListItem key={`section-${group.caption}`} sx={{ py: 0, px: 0, width: "100%" }}>
                  <List sx={{ pb: 1, width: "100%" }}>
                    <ListSubheader sx={{ px: 0, pb: 0.5, pt: 1 }}>
                      <Typography>{group.caption}</Typography>
                    </ListSubheader>
                    {group.dimensions.map((item) => (
                      <MenuItemStyled
                        key={`item-${group.caption}-${item.name}`}
                        sx={{ pl: 1, gap: 1.5 }}
                        onClick={() => onClose(item)}
                      >
                        {getIcon(item)}
                        <Typography noWrap>{item.caption}</Typography>
                      </MenuItemStyled>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Grid2>
        </Grid2>
      </Grid2>
    </Popover>
  );
}
