import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box, IconButton, lighten } from "@mui/material";

interface Props {
  variant: "expand" | "collapse";
  paddingTop: number | undefined;
  onClick: () => void;
}

export const ExpandCollapseButton = (props: Props) => {
  const { variant, paddingTop, onClick } = props;

  return (
    <Box sx={{ position: "relative", zIndex: 2 }}>
      <Box sx={{ position: "absolute" }}>
        <IconButton
          sx={(theme) => ({
            border: "1px solid #E5E6E9",
            backgroundColor: "#FFF",
            color: theme.palette.secondary.light,
            p: 0,
            top: paddingTop ?? "17px",
            right: "14px",
            "&:hover": {
              borderColor: lighten(theme.palette.primary.main, 0.5),
              color: theme.palette.primary.main,
              backgroundColor: lighten(theme.palette.primary.main, 0.8),
            },
          })}
          onClick={onClick}
        >
          {variant === "collapse" && <KeyboardArrowLeftRoundedIcon fontSize="medium" />}
          {variant === "expand" && <KeyboardArrowRightRoundedIcon fontSize="medium" />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default ExpandCollapseButton;
