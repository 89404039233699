import { Box, Divider, Grid2, SxProps, Theme, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import { getEmptyConditionsCaption } from "../../chartOfAccounts/helpers/variablePropertyItem.helper";
import useDeferredDictionaryLoading from "../../hooks/useDeferredDictionaryLoading";
import { CustomMeasureReturnType, findNodeByTokenData } from "../hooks/useCustomMeasure";
import { FormulaNode } from "../utilities/fillNodes";
import AddCustomMeasureConditions from "./AddCustomMeasureConditions";
import Filter from "./Filter";
import PropertyItemContainer from "./PropertyItemContainer";
import { PropertyName } from "./PropertyName";
import "./VariablePropertyItem.css";

interface Props {
  formulaNode: FormulaNode;
  state: CustomMeasureReturnType;
  editable: boolean;
  sx?: SxProps<Theme>;
  chartOfAccountItems?: ChartOfAccountsItem[];
}

export const VariablePropertyItem = ({ formulaNode, state, editable, sx, chartOfAccountItems }: Props) => {
  useDeferredDictionaryLoading({
    values: formulaNode.conditions || [],
    updateItem: (field, changes) => state.actions.updateCondition(formulaNode, field, changes),
  });

  const formulaContainerRef = useRef<HTMLDivElement>(null);

  const conditionsAreEmpty = !formulaNode.conditions || formulaNode.conditions?.length === 0;

  const isSelectedParameter = useMemo(() => {
    if (state.selectedFormulaParameter === undefined) return false;

    return formulaNode.linkedNodes.some((ln) => findNodeByTokenData(ln, state.selectedFormulaParameter));
  }, [state.selectedFormulaParameter, formulaNode]);

  useEffect(() => {
    if (isSelectedParameter) {
      formulaContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSelectedParameter]);

  return (
    <Grid2 container component="li" sx={{ flexWrap: "nowrap", width: "100%" }}>
      <Grid2 container sx={{ flexDirection: "column", flex: 1, width: "100%", ...sx }}>
        <PropertyItemContainer
          sx={formulaNode.highlighted === true ? { border: "1px solid #00A866" } : {}}
          ref={formulaContainerRef}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between", minHeight: "30px", gap: 3, alignItems: "center" }}
          >
            <PropertyName
              sx={{
                px: 2,
                py: 0.5,
                "& .MuiTypography-root": { fontWeight: 700, wordBreak: "break-word" },
              }}
              text={formulaNode.text}
            />
            {editable && !conditionsAreEmpty && (
              <AddCustomMeasureConditions
                text="Add Conditions"
                conditions={formulaNode.conditions}
                dimensions={state.dimensions}
                onAddField={(newCondition) => state.actions.addConditions(formulaNode, [newCondition])}
                sx={(theme) => ({
                  height: "30px",
                  "& .MuiButton-text.MuiButtonBase-root.MuiButton-root": {
                    color: theme.palette.secondary.main,
                    px: 2,
                  },
                })}
              />
            )}
          </Box>
          {conditionsAreEmpty && (
            <>
              <Divider />
              <Grid2
                container
                sx={{ px: 2, height: "30px", width: "100%", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography variant="caption">{getEmptyConditionsCaption(state.isConfigurable)}</Typography>
                {editable && (
                  <AddCustomMeasureConditions
                    text="Add Conditions"
                    conditions={formulaNode.conditions}
                    dimensions={state.dimensions}
                    onAddField={(newCondition) => state.actions.addConditions(formulaNode, [newCondition])}
                  />
                )}
              </Grid2>
            </>
          )}
          {formulaNode.conditions?.length !== 0 &&
            formulaNode.conditions?.map((condition) => (
              <Fragment key={condition.meta.name}>
                <Divider />
                <Grid2 container sx={{ alignItems: "center", width: "100%" }}>
                  <Filter
                    condition={condition}
                    editable={editable}
                    onChanged={(changes) => state.actions.updateCondition(formulaNode, condition, changes)}
                    onRemove={() => state.actions.removeCondition(formulaNode, condition)}
                    chartOfAccountItems={chartOfAccountItems}
                  />
                </Grid2>
              </Fragment>
            ))}
        </PropertyItemContainer>
      </Grid2>
    </Grid2>
  );
};

export default VariablePropertyItem;
