import { Grid2 } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ConditionDescriptor, ItemDataType } from "../../../../../../shared/reporting/api/biClient.types";
import { selectDimensionDictionary } from "../../../../../store/metaDataSlice";
import { ConditionField, ShowFieldOptionsSettings } from "../../../Types";
import ConditionFieldOptionPopup from "./ConditionFieldOptionPopup";
import ConditionItemContainer from "./ConditionItemContainer";

interface Props {
  fields: ConditionField[];
  detailed: boolean;
  canBeRemoved: boolean;
  readonly?: boolean;
  disableEqualitySelection?: boolean;
  onRemoveFields?: (field: ConditionField) => void;
  onFieldChanged: (field: ConditionField, changes: Partial<ConditionField>) => void;
}

export const Conditions = (props: Props) => {
  const { fields, detailed, canBeRemoved, readonly, onRemoveFields, onFieldChanged, disableEqualitySelection } = props;
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(null);
  const [optionField, setOptionField] = useState<ConditionField>();
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const dictionary = useSelector(selectDimensionDictionary(optionField?.meta.name));

  React.useEffect(() => {
    if (optionField !== undefined) {
      const field = fields.find((f) => f.config.guid === optionField.config.guid);
      if (field !== undefined) {
        setOptionField(field);
      }
    }
  }, [fields, optionField]);

  const handleClosePopup = React.useCallback(() => {
    setOptionsAnchorEl(null);
  }, []);

  const onShowOptions = React.useCallback((settings: ShowFieldOptionsSettings<ConditionField>) => {
    setOptionField(settings.field);
    setOptionsAnchorEl(settings.ref);
    setShowOnlySelected(settings.showOnlySelected);
  }, []);

  const onApplyFilter = useCallback(
    (filter: ConditionDescriptor) => {
      if (optionField) {
        onFieldChanged(optionField, { config: { ...optionField.config, filter } });
      }
      handleClosePopup();
    },
    [optionField, onFieldChanged, handleClosePopup]
  );

  return (
    <Grid2 container sx={{ gap: 1, width: "100%" }}>
      {fields.map((f) => {
        return (
          <ConditionItemContainer
            key={f.config.guid}
            field={f}
            detailed={detailed}
            canBeRemoved={canBeRemoved}
            readonly={readonly}
            onRemoveItem={onRemoveFields}
            showOptions={onShowOptions}
            disableEqualitySelection={disableEqualitySelection}
          />
        );
      })}
      {optionsAnchorEl && optionField && (
        <ConditionFieldOptionPopup
          anchorEl={optionsAnchorEl}
          field={optionField}
          showOnlySelectedByDefault={showOnlySelected}
          canBeRemoved={canBeRemoved}
          readonly={readonly}
          save={onApplyFilter}
          resizable={optionField.meta.type !== ItemDataType.Date}
          readonlyCustomLabel={true}
          dictionary={dictionary || optionField.meta.dictionary}
          onRemoveItem={() => {
            if (!onRemoveFields) return;
            onRemoveFields(optionField);
            handleClosePopup();
          }}
          disableEqualitySelection={disableEqualitySelection}
          close={handleClosePopup}
        />
      )}
    </Grid2>
  );
};

export default Conditions;
