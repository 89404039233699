import { Grid2 } from "@mui/material";
import React from "react";
import {
  CalculateByField,
  FieldConfiguration,
  MeasureField,
} from "../../../../../../shared/reporting/api/biClient.types";
import { getMeasureCalculateByField } from "../../../utils/isConfigurationValid";
import CalculateByFieldControl from "../../fields/CalculateByFieldControl";

interface Props {
  measure: MeasureField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function ValueFieldsMenuItem({ measure, saveChanges }: Props) {
  const calcField = React.useMemo(() => getMeasureCalculateByField(measure), [measure]);

  const handleOnChange = React.useCallback(
    (calculateBy: CalculateByField) => {
      saveChanges({ calculateByField: calculateBy }, false);
    },
    [saveChanges]
  );

  if (!calcField) return null;

  return (
    <Grid2 container width={"100%"}>
      <CalculateByFieldControl
        calculateBy={calcField}
        disabled={!measure.meta.capabilities.changeAmountField}
        onChange={handleOnChange}
      />
    </Grid2>
  );
}
