import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectMeasureGroups } from "../../../../../store/metaDataSlice";
import { CustomMeasureReturnType } from "../hooks/useCustomMeasure";

interface Props {
  state: CustomMeasureReturnType;
}

export default function GroupField({ state }: Props) {
  const measureGroups = useSelector(selectMeasureGroups);

  return (
    <Autocomplete
      disablePortal
      disableClearable
      options={measureGroups}
      value={state.group || measureGroups[0]}
      slotProps={{ listbox: { style: { fontSize: "13px" } } }}
      sx={{ flex: 0.5 }}
      renderInput={(params) => <TextField {...params} label="Group" />}
      onChange={(_, value) => state.actions.update({ group: value })}
    />
  );
}
