import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Grid2, IconButton } from "@mui/material";
import { JSX } from "react";
import CloseIconButton from "../../shared/components/CloseIconButton";
import HorizontalFill from "../../shared/components/HorizontalFill";

interface Props {
  fullScreen: boolean;
  title: JSX.Element | undefined;
  setFullScreen: (fullScreen: boolean) => void;
  onClose: () => void;
}

export default function PageToolbar(props: Props) {
  const { fullScreen, title, setFullScreen, onClose } = props;

  return (
    <Grid2
      container
      sx={{
        display: "flex",
        alignItems: "center",
        px: 3,
        minHeight: "48px",
        width: "100%",
        gap: 0.5,
      }}
    >
      {title}
      <HorizontalFill />
      <IconButton onClick={() => setFullScreen(!fullScreen)}>
        {fullScreen && <CloseFullscreenIcon />}
        {!fullScreen && <OpenInFullIcon />}
      </IconButton>
      <CloseIconButton onClick={onClose} />
    </Grid2>
  );
}
