import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid-premium";
import TooltipWrapper from "../../../../../../shared/components/TooltipWrapper.tsx";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis.tsx";
import { nameof } from "../../../../../../shared/utilities/typeHelper.ts";
import { UsedByReport, UsedByReportSource } from "../../../../../api/biApi.types.ts";
import {
  getAbsoluteEditApiReportUrl,
  getAbsoluteEditReportUrl,
  getAbsoluteEditTemplateUrl,
} from "../../utilities/editReportUrl.ts";

export const columns: GridColDef<UsedByReport>[] = [
  {
    field: nameof<UsedByReport>("reportName"),
    headerName: "Name",
    flex: 1,
    resizable: false,
    renderCell: ({ value }: GridCellParams<UsedByReport, string>) => (
      <TypographyTooltipEllipsis tooltipProps={{ disableInteractive: true }} text={value ?? ""} />
    ),
  },
  {
    field: nameof<UsedByReport>("source"),
    resizable: false,
    headerName: "Source",
    flex: 0.5,
    renderCell: ({ value, row }: GridCellParams<UsedByReport, UsedByReportSource>) => (
      <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Typography noWrap>{value}</Typography>
        <TooltipWrapper title={"Open in New Window"}>
          <IconButton
            href={value ? buildHref(value, row.clientCode, row.organization, row.id) : ""}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <OpenInNewRoundedIcon />
          </IconButton>
        </TooltipWrapper>
      </Stack>
    ),
  },
];

const buildHref = (source: UsedByReportSource, clientCode: string, organization: string, reportId: string) => {
  if (source === "Report") {
    return getAbsoluteEditReportUrl(clientCode, reportId);
  }

  if (source === "API Analytics") {
    return getAbsoluteEditApiReportUrl(clientCode, reportId);
  }

  if (source === "Template") {
    return getAbsoluteEditTemplateUrl(clientCode, reportId, organization);
  }

  return "";
};
