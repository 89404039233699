import { TabularDataCell } from "../../../../../../../shared/reporting/api/biClient.types.ts";
import { TabularRow } from "../../../../../../api/biApi.types.ts";

export const getFormattedValueWithFallback = (cell: TabularDataCell | undefined) => {
  return cell?.formattedValue || "No Value";
};

export const getCellFromRow = (row: TabularRow, fieldGuid: string): TabularDataCell | undefined => {
  return row?.data?.[fieldGuid] as TabularDataCell;
};

export const isTopLevelGroup = (depth: number) => depth === 0;

export const getGroupingValue = (cell: TabularDataCell | undefined) => {
  return !isEmpty(cell?.value) ? cell.value : "";
};

export const refineComparisonResult = (
  currentGroupSorting: "asc" | "desc",
  topLevelGroupSorting: "asc" | "desc",
  comparisonResult: number
) => {
  const ascMultiplier = topLevelGroupSorting === "asc" ? 1 : -1;
  const descMultiplier = ascMultiplier * -1;

  return comparisonResult * (currentGroupSorting === "asc" ? ascMultiplier : descMultiplier);
};

const isEmpty = (value: unknown): value is null | undefined => value === null || value === undefined;

export const compareValues = (v1: unknown, v2: unknown) => {
  if (typeof v1 === "number" && typeof v2 === "number") {
    return v1 - v2;
  }
  return (v1?.toString() ?? "").localeCompare(v2?.toString() ?? "");
};
