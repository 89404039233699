import {
  ConditionEqualityType,
  DimensionDescriptor,
  ItemDataType,
} from "../../../../../../shared/reporting/api/biClient.types";
import { ConditionField } from "../../../Types";
import ConditionDimensionCaption from "../../../common/fields/field-captions/ConditionDimensionCaption";
import FieldCaptionWrapper from "../../../common/fields/field-captions/FieldCaptionWrapper";

export const formatCaption = (
  field: ConditionField,
  color?: string,
  hideEqualitySignForSingleDate?: boolean,
  captionVariant?: "caption" | "body1"
) => {
  const customCaption = getCustomCaption(field, hideEqualitySignForSingleDate);
  const subCaptionColor =
    color !== undefined
      ? color
      : field.config.filter?.equalityType === ConditionEqualityType.NotEqual
        ? "#FF6D38"
        : undefined;
  return (
    <FieldCaptionWrapper
      Caption={<ConditionDimensionCaption field={field} captionColor={color} captionVariant={captionVariant} />}
      subCaption={customCaption || ""}
      subCaptionColor={subCaptionColor}
      isMandatory={field.config.mandatory}
    />
  );
};

export const formatConditionCaption = (
  field: ConditionField,
  detailedView: boolean,
  color?: string,
  hideEqualitySignForSingleDate?: boolean
) => {
  const filterValues = field.config.filter?.values || [];
  if (detailedView && field.meta.type !== ItemDataType.Date && filterValues.length >= 2) {
    return (
      <FieldCaptionWrapper
        Caption={<ConditionDimensionCaption field={field} />}
        subCaption=""
        isMandatory={field.config.mandatory}
      />
    );
  }
  return formatCaption(field, color, hideEqualitySignForSingleDate);
};

export const getCustomCaption = (field: ConditionField, hideEqualitySignForSingleDate?: boolean) => {
  let customCaption;
  const params = field.config.filter?.values?.length || 0;
  const value = field.config.filter?.values.map(formatNullValue);
  if (field.meta.type === ItemDataType.Date) {
    if (params === 1) {
      const equalitySign = hideEqualitySignForSingleDate ? "" : getEqualitySign(field.config.filter.equalityType);
      customCaption = `${equalitySign} ${value[0]}`;
    } else if (params === 2) {
      customCaption = `${value[0]}..${value[1]}`;
    }
  } else if (field.meta.type === ItemDataType.General && params > 0) {
    customCaption = value.join(", ");
  }
  if (field.config.filter?.equalityType === ConditionEqualityType.NotEqual && customCaption !== undefined) {
    customCaption = `${getEqualitySign(ConditionEqualityType.NotEqual)} ${customCaption}`;
  }

  return customCaption;
};

const formatNullValue = (value: string | null) => {
  return value ?? "No Value";
};

const getEqualitySign = (equalityType?: ConditionEqualityType) => {
  if (equalityType === undefined) return "";
  switch (equalityType) {
    case ConditionEqualityType.Equal:
      return "=";
    case ConditionEqualityType.NotEqual:
      return "<>";
    case ConditionEqualityType.LessThan:
      return "<";
    case ConditionEqualityType.LessThanOrEqual:
      return "≤";
    default:
      return "";
  }
};

export function getCrossFilterDictionary(field: ConditionField, meta: DimensionDescriptor) {
  const crossFilterValues = field.crossFilter?.values;
  if (crossFilterValues === null) return [];
  if (crossFilterValues === undefined || crossFilterValues.length === 0) return meta.dictionary;
  return meta.dictionary.filter((d) => {
    const value = d[meta.keyFieldName] as string;
    if (value === undefined) return true;
    return crossFilterValues.some((cfv) => String(cfv).localeCompare(value, undefined, { sensitivity: "base" }) === 0);
  });
}

export function isCrossFilterLoading(conditions: ConditionField[]) {
  return conditions.some((c) => c.crossFilter?.loading === true || c.crossFilter?.toRefresh === true);
}

export function isCrossFilterToBeRefreshed(field: ConditionField) {
  return (
    field.crossFilter?.loading === true || field.crossFilter?.toRefresh === true || field.dictionary?.loading === true
  );
}
