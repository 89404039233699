import { Grid2 } from "@mui/material";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";
import StyleGroup from "../StyleGroup";
import GridModeItem from "./GridModeItem";
import RotateItem from "./RotateItem";
import ThicknessItem from "./ThicknessItem";
import ToolTipItem from "./ToolTipItem";

export default function ChartStyles() {
  const { settingsArea } = useFieldsStateContext();

  return (
    <StyleGroup caption="General">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        <GridModeItem area={settingsArea} />
        <RotateItem area={settingsArea} />
        <ThicknessItem area={settingsArea} />
        <ToolTipItem area={settingsArea} />
      </Grid2>
    </StyleGroup>
  );
}
