import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { LoadingButton } from "@mui/lab";
import { Grid2, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../../../shared/types";
import biClient from "../../../../../api/biApi";
import { useDrillDownExport } from "../../../../../hooks/drillDownExport";
import { selectSessionId } from "../../../../../store/currentReportSlice";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";

enum ShowItems {
  "All" = "All",
  "IncludedInCalculation" = "IncludedInCalculation",
}

interface Props {
  configuration: DrillDownConfiguration;
  table: MeasureUnitTable;
  showOnlyIncluded: boolean;
  accountingBook: Maybe<string>;
  onShowOnlyIncludedChange: (showOnlyIncluded: boolean) => void;
}

export default function ToolBar({
  configuration,
  table,
  showOnlyIncluded,
  accountingBook,
  onShowOnlyIncludedChange,
}: Props) {
  const sessionId = useSelector(selectSessionId);
  const exporting = useDrillDownExport(biClient.requestChartOfAccountsExport);

  const exportToExcel = React.useCallback(() => {
    const caption =
      (configuration.measure?.config.customLabel || configuration.measure?.meta.caption || "") + " Chart Of Accounts";
    exporting.requestExport({
      caption,
      sessionId,
      itemId: configuration.cell.drillDownId || "",
      table,
      includedOnly: showOnlyIncluded,
      accountingBook,
    });
  }, [
    configuration.cell.drillDownId,
    configuration.measure?.config.customLabel,
    configuration.measure?.meta.caption,
    exporting,
    sessionId,
    showOnlyIncluded,
    table,
    accountingBook,
  ]);

  return (
    <Grid2 container sx={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
      <ToggleButtonGroup
        exclusive
        value={showOnlyIncluded ? ShowItems.IncludedInCalculation : ShowItems.All}
        sx={{ height: 30 }}
        size="small"
        onChange={(_, value) => onShowOnlyIncludedChange(value === ShowItems.IncludedInCalculation)}
      >
        <ToggleButton value={ShowItems.All} sx={{ width: 84 }} size="small">
          All
        </ToggleButton>
        <ToggleButton value={ShowItems.IncludedInCalculation} sx={{ px: 2 }} size="small">
          Included In Calculation
        </ToggleButton>
      </ToggleButtonGroup>
      <HorizontalFill />
      <LoadingButton
        variant="outlined"
        color="secondary"
        disabled={exporting.exporting}
        startIcon={<ExitToAppIcon />}
        loading={exporting.exporting}
        onClick={exportToExcel}
      >
        Export
      </LoadingButton>
    </Grid2>
  );
}
