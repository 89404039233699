import { Grid2, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { AmountType, CalculateByField, MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../../../shared/reporting/utils/checkUnitTable";
import FormatSelect from "../../FormatSelect";
import AmountTypeFieldControl from "../../fields/AmountTypeFieldControl";
import CalculateByFieldControl from "../../fields/CalculateByFieldControl";
import { CustomMeasureReturnType } from "../hooks/useCustomMeasure";
import { getAllVariableNodes } from "../utilities/fillNodes";

interface Props {
  state: CustomMeasureReturnType;
}

export default function SettingsComponent({ state }: Props) {
  const disabledAmountTypeSelection = useMemo(() => {
    return getAllVariableNodes(state.formulaNodes).some((node) => checkUnitTable(node.table, MeasureUnitTable.Memo));
  }, [state.formulaNodes]);

  const handleUpdateAmountType = useCallback(
    (amountType: AmountType) => {
      state.actions.update({ amountType });
    },
    [state.actions]
  );

  const handleUpdateCalculateBy = useCallback(
    (calculateBy: CalculateByField) => {
      state.actions.update({ calculateBy });
    },
    [state.actions]
  );

  return (
    <Grid2 sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">Settings</Typography>
      <AmountTypeFieldControl
        amountType={disabledAmountTypeSelection ? AmountType.Net : state.amountType || AmountType.Net}
        disabled={disabledAmountTypeSelection}
        onChange={handleUpdateAmountType}
      />
      <CalculateByFieldControl
        calculateBy={state.calculateBy || CalculateByField.Lcy}
        onChange={handleUpdateCalculateBy}
      />
      <Grid2 container width={"100%"}>
        <FormatSelect
          format={state.format}
          label="Format"
          onUpdateFormatter={(format) => state.actions.update({ format })}
        />
      </Grid2>
    </Grid2>
  );
}
