import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { selectDataSetSettings } from "../../../../store/clientSettingsSlice";
import { selectDimensionsForCurrentReportType, selectDimensionsStructure } from "../../../../store/metaDataSlice";
import DataSetSelector from "./DataSetSelector";
import DimensionsTree from "./dimensions/DimensionsTree";
import Measures from "./measures/Measures";

export const FieldsComponent = () => {
  const [dimensionsExpanded, setDimensionsExpanded] = useState(true);
  const [searchText, setSearchText] = useState("");

  const dimensions = useSelector(selectDimensionsForCurrentReportType);
  const dimensionsStructure = useSelector(selectDimensionsStructure);
  const dataSets = useSelector(selectDataSetSettings);
  const isMultiDataSet = dataSets.length > 1;

  const filteredDimensions = useMemo(() => {
    if (searchText === "") {
      return dimensions;
    }
    return dimensions.filter((d) => d.caption.toLowerCase().indexOf(searchText) > -1);
  }, [searchText, dimensions]);

  const revertDimensionExpanded = useCallback(() => setDimensionsExpanded(!dimensionsExpanded), [dimensionsExpanded]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {isMultiDataSet && (
        <>
          <Box px="1.3rem">
            <DataSetSelector />
          </Box>
          <Divider />
        </>
      )}
      <Box px="1.3rem">
        <SearchField onSearch={(text) => setSearchText(text?.toLowerCase())} debounceTimeMs={500} fullWidth />
      </Box>
      <ScrollableFlexContainer scrollContainerSx={{ display: "flex", flexDirection: "column", gap: 1, px: "1.3rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography sx={{ flexGrow: 1, color: "text.primary" }} variant="subtitle2">
              Fields
            </Typography>
            <IconButton color="secondary" onClick={revertDimensionExpanded}>
              {dimensionsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Collapse
            orientation="vertical"
            in={dimensionsExpanded}
            sx={{ "& .MuiCollapse-wrapperInner": { display: "block" } }}
          >
            <DimensionsTree fields={filteredDimensions} structure={dimensionsStructure} />
          </Collapse>
        </Box>
        <Measures searchText={searchText} />
      </ScrollableFlexContainer>
    </Box>
  );
};

export default FieldsComponent;
