import { Box, FormControlLabel, ListItemIcon, Radio, Typography, useTheme } from "@mui/material";
import { JSX, useMemo } from "react";

export enum FormatType {
  Number = "Number",
  Percentage = "Percentage",
  Currency = "Currency",
}

interface Props {
  formatType: FormatType;
  currentFormatType?: FormatType;
  disabled?: boolean;
  icon: JSX.Element;
}

export const FormatTypeItem = (props: Props) => {
  const { formatType, currentFormatType, disabled, icon } = props;

  const theme = useTheme();

  const selected = useMemo(() => {
    return formatType === currentFormatType;
  }, [formatType, currentFormatType]);

  const selectionColor = useMemo(() => {
    if (disabled) {
      return "inherit";
    }
    return selected ? theme.palette.primary.main : theme.palette.text.primary;
  }, [selected, disabled, theme]);

  return (
    <FormControlLabel
      sx={{
        color: selectionColor,
      }}
      value={formatType}
      control={<Radio disabled={!!disabled} sx={{ p: "4px" }} />}
      label={
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pl: "5px" }}>
          <ListItemIcon sx={{ minWidth: "25px", color: selectionColor }}>{icon}</ListItemIcon>
          <Typography>{formatType}</Typography>
        </Box>
      }
    />
  );
};

export default FormatTypeItem;
