import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ReportAuthorizedBox from "../../../../../shared/components/ReportAuthorizedBox";
import {
  ReportAccessType,
  ReportAuthorizationInfo,
  ReportInfo,
  ReportType,
} from "../../../../../shared/reporting/api/biClient.types";
import ShareReport from "../../../../../shared/reporting/components/sharing/ShareReport";
import { CurrentUser, ReportCompany } from "../../../../../shared/reporting/components/sharing/ShareReport.types";
import { checkAccess } from "../../../../../shared/reporting/components/sharing/utils";
import { useClientContext } from "../../../../contexts/ClientContext";
import { useUserContext } from "../../../../contexts/UserContext";
import {
  currentReportActions,
  selectCurrentReport,
  selectCurrentReportAuthorizationInfo,
  selectReportEditMode,
} from "../../../../store/currentReportSlice";
import { Pages } from "../../../../utilities/Pages";

export default function ShareReportButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, name, email } = useUserContext();
  const client = useClientContext();
  const report = useSelector(selectCurrentReport);
  const reportAuthorization = useSelector(selectCurrentReportAuthorizationInfo);
  const [showSharedDialog, setShowSharedDialog] = useState(false);
  const isEditMode = useSelector(selectReportEditMode);

  const currentUser = useMemo((): CurrentUser => ({ id, name, email }), [id, name, email]);
  const isReportPrivate = useMemo(() => reportAuthorization?.private === true, [reportAuthorization]);

  const reportCompany = useMemo((): ReportCompany | undefined => {
    return {
      clientCode: client.clientCode,
      name: client.clientInfo.title,
      logoMarkUrl: client.clientInfo.branding.logoMarkUrl,
    };
  }, [client]);

  const reportInfo = useMemo((): ReportInfo | undefined => {
    if (report === undefined || reportAuthorization === undefined) {
      return undefined;
    }
    return {
      clientCode: report.clientCode,
      reportId: report.reportId,
      name: report.name,
      reportType: report.reportType || ReportType.Tabular,
      groupId: "",
      updateAt: "",
      modifiedBy: "",
      createdBy: "",
      authorization: reportAuthorization,
      reportUsage: report.reportUsage,
    };
  }, [report, reportAuthorization]);

  const handleClosed = useCallback(() => {
    setShowSharedDialog(false);
  }, []);

  const handleAuthorizationUpdated = useCallback(
    (authorization: ReportAuthorizationInfo) => {
      dispatch(currentReportActions.setAuthorizationInfo(authorization));
      if (authorization.access === ReportAccessType.NoAccess) {
        navigate(`../${Pages.Forbidden}`);
        return;
      }
      if (isEditMode && !checkAccess(authorization.access, ReportAccessType.Write)) {
        navigate(`../${Pages.Forbidden}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditMode]
  );

  if (reportInfo === undefined) {
    return null;
  }

  if (reportInfo.reportId === undefined) {
    return null;
  }

  return (
    <>
      <ReportAuthorizedBox
        permissions={["ShareReports"]}
        access={reportInfo.authorization.access}
        requiredAccess={ReportAccessType.FullAccess}
      >
        <Button
          variant="outlined"
          color="secondary"
          startIcon={isReportPrivate ? <LockOutlinedIcon /> : <PeopleAltOutlinedIcon />}
          onClick={() => setShowSharedDialog(true)}
        >
          Share
        </Button>
        {showSharedDialog && (
          <ShareReport
            report={reportInfo}
            currentUser={currentUser}
            reportCompany={reportCompany!}
            onClose={handleClosed}
            onAuthorizationUpdated={handleAuthorizationUpdated}
          />
        )}
      </ReportAuthorizedBox>
    </>
  );
}
