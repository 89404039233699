import { Grid2, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import { currentReportActions, selectAutoShowItemOptionsId } from "../../../../../store/currentReportSlice";
import { FieldItemProps } from "../types/areaFiledItem.types";
import AreaFieldItemLoader from "./AreaFieldItemLoader";
import { getDefaultCaption } from "./AreaFieldsHelper";

export const LinkedAreaFieldItem = <T,>(props: FieldItemProps<T>) => {
  const { field, canBeRemoved, formatCaption, onRemoveItem, onShowOptions, isLoading, disableEqualitySelection } =
    props;
  const autoShowItemOptionsId = useSelector(selectAutoShowItemOptionsId);
  const dispatch = useDispatch();

  const [optionElementRef, setOptionElementRef] = useState<HTMLElement | null>(null);
  const [over, setOver] = useState(false);

  const getCaption = () => {
    const customCaption = formatCaption && formatCaption(field, undefined, disableEqualitySelection);
    if (customCaption) {
      return customCaption;
    }
    return (
      <Typography variant="caption" sx={{ flexGrow: 1, color: "text.primary" }}>
        {getDefaultCaption(field)}
      </Typography>
    );
  };

  const showOptions = useCallback(() => {
    if (!onShowOptions) return false;
    const ref = optionElementRef;
    if (!ref) return false;

    onShowOptions({ field: field, ref, showOnlySelected: false });
    return true;
  }, [optionElementRef, field, onShowOptions]);

  const handleRemoveItem = useCallback(() => {
    if (onRemoveItem) {
      onRemoveItem(field);
    }
  }, [field, onRemoveItem]);

  const onOver = useCallback(() => setOver(true), [setOver]);
  const offOver = useCallback(() => setOver(false), [setOver]);

  useEffect(() => {
    if (!autoShowItemOptionsId) return;
    const item = field as { config: { guid: string; filter: { guid: string } }; guid: string };
    if (
      autoShowItemOptionsId === item?.guid ||
      item.config?.guid === autoShowItemOptionsId ||
      item.config?.filter?.guid === autoShowItemOptionsId
    ) {
      if (showOptions()) {
        dispatch(currentReportActions.setAutoShowItemOptionsId(undefined));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoShowItemOptionsId, field, showOptions]);

  return (
    <>
      {isLoading === true && <AreaFieldItemLoader />}
      <Grid2 container onMouseOver={onOver} onMouseLeave={offOver}>
        <Grid2
          ref={setOptionElementRef}
          container
          sx={{ alignItems: "center", px: "5px", height: "28px", width: "100%" }}
        >
          {getCaption()}
        </Grid2>
        <Grid2
          sx={{
            position: "absolute",
            right: 0,
            height: "28px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            visibility: over ? "visible" : "hidden",
            opacity: over ? 1 : 0,
            transition: "all 0.3s ease",
            bgcolor: "white",
          }}
        >
          {canBeRemoved && (
            <CloseIconButton
              onClick={handleRemoveItem}
              iconProps={{ sx: (theme) => ({ color: theme.palette.error.main }) }}
            />
          )}
        </Grid2>
      </Grid2>
    </>
  );
};
export default LinkedAreaFieldItem;
