import { Alert } from "@mui/material";

const DeleteCustomMeasureWarning = () => {
  return (
    <Alert severity="warning">
      This measure is used in other reports. Deleting it will remove it from all associated reports.
    </Alert>
  );
};

export default DeleteCustomMeasureWarning;
