import { isDate } from "date-fns";
import numeral, { isNumeral } from "numeral";
import {
  DimensionDescriptor,
  FormatType,
  MetaFormat,
  NumberFormatterOptions,
  ReportField,
} from "../../shared/reporting/api/biClient.types";
import { formatDateTime } from "../../shared/utilities/dateUtils";
import { stringifyValue } from "../../shared/utilities/stringHelper";
import { FormatterDescription } from "../api/biApi.types";
import { formatDate, parseDate } from "../utilities/Utilities";

export const formatDimension = (field: DimensionDescriptor, value: unknown) => {
  if (typeof value === "object" || value === undefined) {
    return "";
  }
  switch (field.defaultFormat?.formatType) {
    case FormatType.Numeric: {
      const parsedValue = isNumeral(value) ? (value as number) : parseFloat(value as string);
      return formatNumber(parsedValue, field.defaultFormat);
    }
    case FormatType.Date: {
      const parsedValue = isDate(value) ? value : parseDate(value as string);
      return formatDate(parsedValue);
    }
    case FormatType.DateTime: {
      const parsedValue = isDate(value) ? value : parseDate(value as string);
      return formatDateTime(parsedValue);
    }
    case FormatType.BlankIfZero: {
      return value === "0" || value === 0 ? "" : stringifyValue(value);
    }
    case FormatType.BlankIfFalse: {
      return value === false || value === "false" || value === "False" ? "" : stringifyValue(value);
    }
    default: {
      return stringifyValue(value);
    }
  }
};

export const formatMeasure = (field: ReportField | undefined, value: number) => {
  if (field === undefined) return `${value}`;
  switch (field.meta.defaultFormat?.formatType) {
    case FormatType.Numeric: {
      return formatNumber(value, field.config.format || field.meta.defaultFormat);
    }
    default:
      return `${value}`;
  }
};

export const formatNumber = (value: number, incomeOptions: NumberFormatterOptions | undefined) => {
  if (isNaN(value)) {
    return "";
  }
  if (!incomeOptions) {
    return value + "";
  }
  numeral.reset();
  const num = numeral(value);

  const options = { ...incomeOptions } as NumberFormatterOptions;
  setUpDefaults();
  setupSeparators(options);

  const template = createFormatTemplate(options);
  let formattedValue = num.format(template);

  if (options.percentage && !options.convertToPercentage) {
    formattedValue += "%";
  }
  if (options.prefix) {
    formattedValue = options.prefix + formattedValue;
  }
  if (options.suffix) {
    formattedValue += options.suffix;
  }

  return formattedValue.indexOf("NaN") > -1 ? "" : formattedValue;
};

const setUpDefaults = () => {
  numeral.localeData().delimiters.thousands = ",";
  numeral.localeData().delimiters.decimal = ".";
  numeral.localeData().abbreviations.million = "";
  numeral.localeData().abbreviations.thousand = "";
};

const setupSeparators = (options: NumberFormatterOptions) => {
  if (options.thousandSeparator !== undefined) {
    numeral.localeData().delimiters.thousands = options.thousandSeparator;
  }
  if (options.decimalSeparator !== undefined) {
    numeral.localeData().delimiters.decimal = options.decimalSeparator;
  }
};

const createFormatTemplate = (options: NumberFormatterOptions) => {
  let template = `${options.currency || ""}0,0`;
  if ((options.decimals || 0) > 0) {
    template += `.${Array.apply(0, Array(options.decimals))
      .map(() => 0)
      .join("")}`;
  }
  template += getAbbreviation(options);
  if (options.convertToPercentage) {
    template += "%";
  }

  return template;
};

const getAbbreviation = (options: NumberFormatterOptions) => {
  switch (options.abbreviation) {
    case "K": {
      return "ak";
    }
    case "M": {
      return "am";
    }
    case "B": {
      return "ab";
    }
    default:
      return "";
  }
};

export const getAllNumberFormattersDescription = () => {
  const result: FormatterDescription[] = [
    {
      title: "Number Rounded",
      subTitle: "1,234",
      options: {
        formatType: FormatType.Numeric,
        type: "NumberRounded",
      },
    },
    {
      title: "Number (2 decimals)",
      subTitle: "1,234.12",
      options: {
        formatType: FormatType.Numeric,
        type: "Number2Decimals",
        decimals: 2,
      },
    },
    {
      title: "Group (Dot)",
      subTitle: "1.234.000,12",
      options: {
        formatType: FormatType.Numeric,
        type: "GroupDot",
        thousandSeparator: ".",
        decimalSeparator: ",",
        decimals: 2,
      },
    },
    {
      title: "Abbreviation (Million)",
      subTitle: "1,234,000→1,2",
      options: {
        formatType: FormatType.Numeric,
        type: "AbbreviationM",
        abbreviation: "M",
        decimals: 2,
      },
    },
    {
      title: "Abbreviation (Thousand)",
      subTitle: "1,234→1,2",
      options: {
        formatType: FormatType.Numeric,
        type: "AbbreviationK",
        abbreviation: "K",
        decimals: 2,
      },
    },
    {
      title: "Percent (Rounded)",
      subTitle: "12→12%",
      options: {
        formatType: FormatType.Numeric,
        type: "PercentRounded",
        percentage: true,
      },
    },
    {
      title: "Percent (Converted)",
      subTitle: "0,12→12%",
      options: {
        formatType: FormatType.Numeric,
        type: "PercentConverted",
        percentage: true,
        convertToPercentage: true,
      },
    },
    {
      title: "Multiple",
      subTitle: "1,02→1.02x",
      options: {
        formatType: FormatType.Numeric,
        type: "Multiple",
        decimals: 2,
        suffix: "x",
      },
    },
    {
      title: "Currency",
      subTitle: "$123.45",
      options: {
        formatType: FormatType.Numeric,
        type: "Currency",
        decimals: 2,
        currency: "$",
      },
    },
  ];
  return result;
};

export const isNumberApplicable = (value: number | undefined, format: MetaFormat | undefined) => {
  if (value === undefined || value === 0) return false;
  return formatNumber(value, format?.formatType === FormatType.Numeric ? format : undefined) !== "";
};

export const defaultTextFormat = {
  formatType: FormatType.None,
} as NumberFormatterOptions;

export const defaultCountAggregationFormat = {
  formatType: FormatType.Numeric,
} as NumberFormatterOptions;

export const defaultNumericFormat = {
  formatType: FormatType.Numeric,
  decimals: 2,
  thousandSeparator: ",",
  decimalSeparator: ".",
} as NumberFormatterOptions;

export const defaultDateFormat = {
  formatType: FormatType.Date,
} as NumberFormatterOptions;
