import { PropsWithChildren, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BiAggregation,
  DimensionDescriptor,
  ItemDataType,
  MeasureDescriptor,
  Report,
  ReportConfiguration,
  ReportField,
  TabularConfiguration,
} from "../../../../shared/reporting/api/biClient.types";
import useReportMode from "../../../hooks/useReportMode.ts";
import { currentReportActions, selectReportConfiguration } from "../../../store/currentReportSlice";
import { metaDataActions, selectDimensions, selectMeasures, selectPreSetDataSet } from "../../../store/metaDataSlice";
import { SupportedAggregationsContextProvider } from "../common/fields/contexts/ExportStateContext";
import useCrossFiltering from "../common/hooks/useCrossFiltering";
import useDeferredDictionaryLoading from "../common/hooks/useDeferredDictionaryLoading";
import { addMissingGroupSorts, setGroupingFlag } from "../common/utilities/sortFieldStateHelper";
import { configurationToConditions, configurationToSorts } from "../pivot/utilities/Configurations";
import { isDimensionBased } from "../utils/fieldsHelper";
import { updateMeasureFields } from "../utils/MeasureUtils";
import { FieldsStateContextProvider, FieldsStateContextType } from "./contexts/FieldsStateContext";
import useFieldsState, { FieldsStateReturnType } from "./hooks/useFieldsState";
import {
  configurationToFields,
  configurationToGrouping,
  configurationToSettings,
  createReportConfiguration,
} from "./utilities/configurations";

interface Props {
  report: Report;
}

export default function TabularContainer({ report, children }: PropsWithChildren<Props>) {
  const dispatch = useDispatch();
  const dimensions = useSelector(selectDimensions);
  const measures = useSelector(selectMeasures);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const preSetDataSetId = useSelector(selectPreSetDataSet);
  const fieldsState = useFieldsState();
  const fieldsStateRef = useRef(fieldsState);
  fieldsStateRef.current = fieldsState;

  const dimensionsRef = useRef(dimensions);
  dimensionsRef.current = dimensions;

  const measuresRef = useRef(measures);
  measuresRef.current = measures;

  const { mode } = useReportMode();

  useEffect(() => {
    if (preSetDataSetId) {
      fieldsState.resetToDefault();
      dispatch(metaDataActions.updateDataSet(preSetDataSetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSetDataSetId]);

  useEffect(
    () => {
      const customConfiguration = mode === "run" ? undefined : reportConfiguration;
      setReportConfiguration(
        report,
        fieldsStateRef.current,
        dimensionsRef.current,
        measuresRef.current,
        customConfiguration
      );
      dispatch(currentReportActions.refreshSession());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report.reportId, mode]
  );

  useEffect(() => {
    const configuration = createReportConfiguration(
      fieldsState.conditions,
      fieldsState.fields,
      fieldsState.sorts,
      fieldsState.grouping,
      fieldsState.settings
    );

    dispatch(currentReportActions.updateReportConfiguration(configuration));
  }, [
    fieldsState.conditions,
    fieldsState.fields,
    fieldsState.sorts,
    fieldsState.grouping,
    fieldsState.settings,
    dispatch,
  ]);

  useEffect(() => updateMeasureFields(fieldsStateRef.current, measures), [measures]);

  const context = useMemo(() => buildContext(fieldsState), [fieldsState]);

  useCrossFiltering(context.conditionsArea);
  useDeferredDictionaryLoading(context.conditionsArea);

  return (
    <FieldsStateContextProvider {...context}>
      <SupportedAggregationsContextProvider
        aggregations={supportedAggregations}
        getSupportedAggregationTypes={getSupportedAggregationTypes}
      >
        {children}
      </SupportedAggregationsContextProvider>
    </FieldsStateContextProvider>
  );
}

const supportedAggregations = [
  BiAggregation.None,
  BiAggregation.Average,
  BiAggregation.Count,
  BiAggregation.CountDistinct,
  BiAggregation.Max,
  BiAggregation.Min,
  BiAggregation.StringAgg,
  BiAggregation.Sum,
];

const getSupportedAggregationTypes = (field: ReportField) => {
  const items = [BiAggregation.None, BiAggregation.Count, BiAggregation.CountDistinct];

  if (field.meta.type === ItemDataType.Numeric) {
    items.push(BiAggregation.Average, BiAggregation.Min, BiAggregation.Max, BiAggregation.Sum);
  } else if (field.meta.type === ItemDataType.Date) {
    items.push(BiAggregation.Min, BiAggregation.Max);
  } else if (field.meta.type === ItemDataType.General) {
    items.push(BiAggregation.StringAgg);
  }

  return items;
};

function setReportConfiguration(
  report: Report,
  fieldsState: FieldsStateReturnType,
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  currentReportConfiguration: ReportConfiguration | undefined
) {
  const configuration = (currentReportConfiguration || report.configuration) as TabularConfiguration;
  if (!configuration) {
    return;
  }
  if (configuration.conditions) {
    const result = configurationToConditions(configuration.conditions, dimensions);
    fieldsState.setConditions(result);
  }
  if (configuration.fields) {
    const result = configurationToFields(configuration.fields, dimensions, measures);
    fieldsState.setFields(result);
  }
  if (configuration.sort) {
    const sorts = addMissingGroupSorts(
      configuration.fields,
      configuration.grouping,
      setGroupingFlag(configuration.fields ?? [], configuration.grouping ?? [], configuration.sort ?? [])
    );
    const result = configurationToSorts(sorts, dimensions);
    const groupMetaNames = configuration.grouping.map(
      (g) => configuration.fields.find((f) => f.guid === g.name)?.name ?? ""
    );
    fieldsState.setSorting(result, groupMetaNames);
  }
  if (configuration.grouping) {
    const result = configurationToGrouping(configuration.grouping);
    fieldsState.setGrouping(result);
  }
  if (configuration.settings) {
    const result = configurationToSettings(configuration.settings);
    fieldsState.setSettings(result);
  }
}

function removeField(field: ReportField, fieldsState: FieldsStateReturnType) {
  if (isDimensionBased(field)) {
    fieldsState.removeSortingByMeta(field.meta);
  }
  fieldsState.removeField(field);
}

function buildContext(fieldsState: FieldsStateReturnType): FieldsStateContextType {
  const context: FieldsStateContextType = {
    conditionsArea: {
      values: fieldsState.conditions,
      addItem: fieldsState.addCondition,
      removeItem: fieldsState.removeCondition,
      moveItem: fieldsState.moveCondition,
      updateItem: fieldsState.updateCondition,
      updateItemConfig: fieldsState.updateConditionConfig,
    },
    fieldsArea: {
      values: fieldsState.fields,
      addItem: fieldsState.addField,
      removeItem: (item) => removeField(item, fieldsState),
      moveItem: fieldsState.moveField,
      updateItemConfig: fieldsState.updateFieldConfig,
      updateItem: fieldsState.updateMeasureField,
      updateFieldsOrder: fieldsState.updateFieldsOrder,
    },
    sortingArea: {
      values: fieldsState.sorts,
      addItem: fieldsState.addSorting,
      removeItem: fieldsState.removeSorting,
      moveItem: fieldsState.moveSorting,
      updateItem: fieldsState.updateSorting,
      updateItemConfig: fieldsState.updateSortingConfig,
      setSorting: fieldsState.setSorting,
    },
    groupingArea: {
      values: fieldsState.grouping,
      setGrouping: fieldsState.setGrouping,
      updateGroup: fieldsState.updateGroup,
      addItem: fieldsState.addGroup,
      removeItem: fieldsState.removeGroup,
      moveItem: fieldsState.moveGroup,
    },
    settingsArea: {
      settings: fieldsState.settings,
      update: fieldsState.updateSettings,
    },
  };
  return context;
}
