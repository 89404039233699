import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { LoadingButton } from "@mui/lab";
import { Grid2, Stack } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import biClient from "../../../../../api/biApi";
import { IrrDrillDownInfo } from "../../../../../api/biApi.types";
import { useDrillDownExport } from "../../../../../hooks/drillDownExport";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import { parseDate } from "../../../../../utilities/Utilities";

interface Props {
  configuration: DrillDownConfiguration;
  info: IrrDrillDownInfo;
}
export default function ToolBar({ configuration, info }: Props) {
  const exporting = useDrillDownExport(biClient.requestCashflowExport);
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = React.useCallback(() => {
    if (copied) return;
    const result = info.cashFlowValues
      ?.map((v) => {
        if (v.dateFormatted) {
          const date = parseDate(v.date);
          const dateFormatted = !isNaN(date.getTime()) ? date.toLocaleDateString() : "";
          const amountFormatted = v.amount.toLocaleString(undefined, { useGrouping: false });
          return `${v.description}\t${dateFormatted}\t${amountFormatted}`;
        } else {
          return `${v.description}\t\t${v.amountFormatted}`;
        }
      })
      .join("\n");
    if (result) {
      navigator.clipboard.writeText(result);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied, info.cashFlowValues]);

  const exportToExcel = React.useCallback(() => {
    const caption = configuration.measure?.config.customLabel || configuration.measure?.meta.caption || "Cash Flow";
    exporting.requestExport({ drillDownInfo: info, caption });
  }, [configuration.measure?.config.customLabel, configuration.measure?.meta.caption, exporting, info]);

  return (
    <Grid2 container sx={{ flexDirection: "row", alignItems: "center", gap: 1.5, width: "100%" }}>
      <HorizontalFill />
      <Stack direction={"row"} gap={1}>
        <LoadingButton
          variant="outlined"
          color="secondary"
          startIcon={copied ? <CheckRoundedIcon /> : <CopyAllRoundedIcon />}
          onClick={copyToClipboard}
        >
          {copied ? "Copied" : "Copy Data"}
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          color="secondary"
          disabled={exporting.exporting}
          startIcon={<ExitToAppIcon />}
          loading={exporting.exporting}
          onClick={exportToExcel}
        >
          Export
        </LoadingButton>
      </Stack>
    </Grid2>
  );
}
