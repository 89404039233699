import { Box } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { ResizeObserverWrapper } from "../ResizeObserverWrapper";
import { Splitter } from "./Splitter";

type Size = {
  height: number;
  width: number;
};

export type Props = {
  horizontal?: boolean;
  initialPrimarySize?: string;
  minPrimarySize?: string;
  minSecondarySize?: string;
  splitterSize?: string;
  resetOnDoubleClick?: boolean;
  defaultSplitterColors?: {
    color: string;
    hover: string;
    drag: string;
  };
};

export default function SplitPane(props: React.PropsWithChildren<Props>): React.JSX.Element {
  const {
    horizontal = false,
    initialPrimarySize = "50%",
    minPrimarySize = "0px",
    minSecondarySize = "0px",
    splitterSize = "7px",
    resetOnDoubleClick = false,
  } = props;

  const [contentMeasuredDimensions, setContentMeasuredDimensions] = useState<Size>({
    height: 0,
    width: 0,
  });
  const [primaryMeasuredDimensions, setPrimaryMeasuredDimensions] = useState<Size>({
    height: 0,
    width: 0,
  });
  const [splitterMeasuredDimensions, setSplitterMeasuredDimensions] = useState<Size>({
    height: 0,
    width: 0,
  });

  const currentContentSize = React.useMemo(
    () => (horizontal ? contentMeasuredDimensions.height : contentMeasuredDimensions.width),
    [horizontal, contentMeasuredDimensions]
  );
  const currentPrimarySize = React.useMemo(
    () => (horizontal ? primaryMeasuredDimensions.height : primaryMeasuredDimensions.width),
    [horizontal, primaryMeasuredDimensions]
  );
  const currentSplitterSize = React.useMemo(
    () => (horizontal ? splitterMeasuredDimensions.height : splitterMeasuredDimensions.width),
    [horizontal, splitterMeasuredDimensions]
  );

  const [percent, setPercent] = React.useState<number | undefined>(undefined);

  const [clientStart, setClientStart] = React.useState(0);
  const [primaryStart, setPrimaryStart] = React.useState(0);
  const [dragging, setDragging] = React.useState(false);

  const onMeasureContent = ({ width, height }: DOMRectReadOnly) => setContentMeasuredDimensions({ height, width });
  const onMeasurePrimary = ({ width, height }: DOMRectReadOnly) => setPrimaryMeasuredDimensions({ height, width });
  const onMeasureSplitter = ({ width, height }: DOMRectReadOnly) => setSplitterMeasuredDimensions({ width, height });

  const onSplitPointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
    event.currentTarget.setPointerCapture(event.pointerId);
    setClientStart(horizontal ? event.clientY : event.clientX);
    setPrimaryStart(currentPrimarySize);
    setDragging(true);
  };

  const onSplitPointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
    if (event.currentTarget.hasPointerCapture(event.pointerId)) {
      const position = horizontal ? event.clientY : event.clientX;
      const primarySize = primaryStart + (position - clientStart);
      const newPrimary = Math.max(0, Math.min(primarySize, currentContentSize));
      const newPercent = (newPrimary / currentContentSize) * 100;
      setPercent(newPercent);
    }
  };

  const onSplitPointerUp = (event: React.PointerEvent<HTMLDivElement>) => {
    event.currentTarget.releasePointerCapture(event.pointerId);
    setDragging(false);
  };

  const onSplitDoubleClick = () => {
    if (resetOnDoubleClick) {
      setPercent(undefined);
    }
  };

  const children = React.Children.toArray(props.children);
  const primaryChild = children.length > 0 ? children[0] : <div />;
  const secondaryChild = children.length > 1 ? children[1] : <div />;

  const renderSizes = {
    primary: percent !== undefined ? `${percent}%` : initialPrimarySize,
    minPrimary: minPrimarySize ?? "0px",
    minSecondary: minSecondarySize ?? "0px",
  };

  const renderSplitterProps = {
    pixelSize: currentSplitterSize,
    horizontal,
    dragging: dragging,
  };

  return (
    <ResizeObserverWrapper onResize={onMeasureContent}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          outline: "none",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            outline: "none",
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: horizontal
              ? "1fr"
              : `minmax(${renderSizes.minPrimary},${renderSizes.primary}) ${splitterSize} minmax(${renderSizes.minSecondary}, 1fr)`,
            gridTemplateRows: horizontal
              ? `minmax(${renderSizes.minPrimary},${renderSizes.primary}) ${splitterSize} minmax(${renderSizes.minSecondary}, 1fr)`
              : "1fr",
            gridTemplateAreas: '"primary split secondary"',
          }}
        >
          <Box
            sx={{
              gridArea: "primary",
              boxSizing: "border-box",
              outline: "none",
              overflow: "hidden",
              height: horizontal ? "auto" : "100%",
              width: horizontal ? "100%" : "auto",
            }}
          >
            <ResizeObserverWrapper onResize={onMeasurePrimary}>
              <FullContent>{primaryChild}</FullContent>
            </ResizeObserverWrapper>
          </Box>
          <Box
            sx={{
              gridArea: "split",
              background: "transparent",
              userSelect: "none",
              boxSizing: "border-box",
              outline: "none",
              overflow: "hidden",
              height: horizontal ? "auto" : "100%",
              width: horizontal ? "100%" : "auto",
              cursor: horizontal ? "row-resize" : "col-resize",
            }}
            tabIndex={-1}
            onPointerDown={onSplitPointerDown}
            onPointerUp={onSplitPointerUp}
            onPointerMove={onSplitPointerMove}
            onDoubleClick={onSplitDoubleClick}
          >
            <ResizeObserverWrapper onResize={onMeasureSplitter}>
              <FullContent>
                <Splitter {...renderSplitterProps} />
              </FullContent>
            </ResizeObserverWrapper>
          </Box>
          <Box
            sx={{
              gridArea: "secondary",
              boxAizing: "border-box",
              outline: "none",
              overflow: "hidden",
              height: horizontal ? "auto" : "100%",
              width: horizontal ? "100%" : "auto",
            }}
          >
            <FullContent>{secondaryChild}</FullContent>
          </Box>
        </Box>
      </Box>
    </ResizeObserverWrapper>
  );
}

const FullContent = React.forwardRef((props: React.PropsWithChildren, ref: React.Ref<unknown>) => {
  return (
    <Box
      ref={ref}
      className="full-content"
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        outline: "none",
        overflow: "hidden",
      }}
    >
      {props.children}
    </Box>
  );
});

FullContent.displayName = "FullContent";
