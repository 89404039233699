import { Divider, Grid2 } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaItemType,
  ConditionConfiguration,
  ConditionDescriptor,
  ConditionEqualityType,
  DimensionDescriptor,
  FieldConfiguration,
  ItemDataType,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import { currentReportActions } from "../../../../../store/currentReportSlice";
import { selectDimensionDictionary } from "../../../../../store/metaDataSlice";
import useDeferredDictionaryLoading from "../../../common/hooks/useDeferredDictionaryLoading";
import { ConditionField, ShowFieldOptionsSettings } from "../../../Types";
import { resetMandatoryField } from "../../../utils/conditionsUtils";
import ConditionFieldOptionPopup from "../conditions/ConditionFieldOptionPopup";
import AddCustomConditions from "./AddCustomConditions";
import LinkConditions from "./LinkConditions";
import MeasureItemConditions from "./MeasureItemConditions";

interface Props {
  field: ReportField;
  fields: DimensionDescriptor[];
  globalConditions: ConditionField[];
  showConditionParameter?: boolean;
  canBeRemoved: boolean;
  updateField: (field: ReportField, change: Partial<FieldConfiguration>) => void;
}
const CustomConditions = (props: Props) => {
  const { field, fields, globalConditions, canBeRemoved, showConditionParameter, updateField } = props;

  const dispatch = useDispatch();
  const [fieldOptions, setFieldOptions] = React.useState<ShowFieldOptionsSettings<ConditionField>>();
  const [showMeasureOptions, setShowMeasureOptions] = React.useState(false);
  const disableEqualitySelection = true;

  const [conditions, setConditions] = React.useState<ConditionField[]>([]);
  const [linkedConditions, setLinkedConditions] = React.useState<ConditionField[]>([]);
  const currentCondition = useMemo(() => {
    if (fieldOptions === undefined) return undefined;
    return conditions.find((c) => c.meta.name === fieldOptions.field.meta.name);
  }, [conditions, fieldOptions]);

  const dictionary = useSelector(selectDimensionDictionary(fieldOptions?.field?.meta.name));

  //update linked conditions when global condition has changed
  React.useEffect(() => {
    let customConditions: ConditionField[] = [];
    if (field.config.customConditions) {
      customConditions = field.config.customConditions
        .map((cc) => {
          const f = fields.find((fld) => fld.name === cc.filter.dimensionName);
          if (f) {
            const copied: ConditionField = {
              config: cc,
              meta: f,
              areaItemType: AreaItemType.VALUES,
            };
            return copied;
          }
          return undefined;
        })
        .filter((item): item is ConditionField => !!item);
    }
    return setConditions(customConditions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.config.customConditions, setConditions]);

  //update linked conditions when global condition has changed
  React.useEffect(() => {
    let linkedConditions: ConditionField[] = [];
    if (field.config.linkedConditions) {
      linkedConditions = field.config.linkedConditions
        .map((guid) => {
          const condition = globalConditions.find((gc) => gc.config.guid === guid);
          if (condition) {
            const copied = { ...cloneDeep(condition), linked: true } as ConditionField;
            return copied;
          }
          return undefined;
        })
        .filter((item): item is ConditionField => !!item);
    }

    return setLinkedConditions(linkedConditions);
  }, [field.config.linkedConditions, globalConditions, setLinkedConditions]);

  const fieldsForConditions = React.useMemo(() => {
    return fields.filter((f) => !conditions.some((c) => c.meta.name === f.name));
  }, [fields, conditions]);

  const addCondition = React.useCallback(
    (value: DimensionDescriptor) => {
      const newCondition: ConditionConfiguration = {
        guid: generateGuid(),
        filter: { dimensionName: value.name, values: [], equalityType: ConditionEqualityType.Equal },
      };

      if (!field.config.customConditions) {
        updateField(field, { customConditions: [newCondition] });
      } else {
        updateField(field, { customConditions: field.config.customConditions.concat(newCondition) });
      }
      dispatch(currentReportActions.setAutoShowItemOptionsId(newCondition.guid));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, updateField]
  );

  const handleSetLinkCondition = React.useCallback(
    (condition: ConditionField) => {
      const newLinkedConditions = [...linkedConditions, { ...condition, linked: true }];
      setLinkedConditions(newLinkedConditions);
      updateField(field, { linkedConditions: newLinkedConditions.map((v) => v.config.guid) });
    },
    [field, updateField, linkedConditions]
  );

  const onRemoveConditionItem = React.useCallback(
    (item: ConditionField) => {
      if (!field.config.customConditions) return;
      const conditionItem = field.config.customConditions.find((c) => c.filter.dimensionName === item.meta.name);
      if (conditionItem !== undefined) {
        const copied = [...field.config.customConditions];
        const index = copied.indexOf(conditionItem);
        copied.splice(index, 1);
        updateField(field, { customConditions: copied });
      }
    },
    [field, updateField]
  );

  const onRemoveLinkedConditionItem = React.useCallback(
    (item: ConditionField) => {
      if (!field.config.linkedConditions) return;
      const index = field.config.linkedConditions.findIndex((c) => c === item.config.guid);
      if (index > -1) {
        const copied = [...field.config.linkedConditions];
        copied.splice(index, 1);
        updateField(field, { linkedConditions: copied });
      }
    },
    [field, updateField]
  );

  const onUpdateAsParameter = React.useCallback(
    (field: ConditionField, newValue: boolean) => {
      const copy = cloneDeep(conditions);
      const condition = copy.find((c) => c.config.guid === field.config.guid);
      if (condition !== undefined) {
        const changes = resetMandatoryField(condition.config, { parameter: newValue });
        condition.config = { ...condition.config, ...changes };
        setConditions(copy);
      }
    },
    [conditions]
  );

  const onUpdateAsMandatory = React.useCallback(
    (field: ConditionField, newValue: boolean) => {
      const copy = cloneDeep(conditions);
      const condition = copy.find((c) => c.config.guid === field.config.guid);
      if (condition !== undefined) {
        condition.config.mandatory = newValue;
        setConditions(copy);
      }
    },
    [conditions]
  );

  const onUpdateDictionary = React.useCallback(
    (field: ConditionField, changes: Partial<ConditionField>) => {
      const condition = conditions.find((c) => c.meta.name === field.meta.name);
      if (condition !== undefined && changes.dictionary !== undefined) {
        field.dictionary = changes.dictionary;
        setConditions([...conditions]);
      }
    },
    [conditions]
  );

  const updateCustomConditionProperty = React.useCallback(
    <K extends keyof ConditionConfiguration>(
      condition: ConditionField,
      propName: K,
      value: ConditionConfiguration[K]
    ) => {
      if (!field.config.customConditions) return;
      const copy = cloneDeep(field.config.customConditions);
      const customConditionItem = copy.find((c) => c.filter.dimensionName === condition.meta.name);
      if (customConditionItem) {
        customConditionItem[propName] = value;
        return copy;
      }
      return;
    },
    [field]
  );

  const updateCustomConditionFilterAndParameter = React.useCallback(
    (current: ConditionField, condition: Partial<ConditionDescriptor>) => {
      if (!field.config.customConditions) return;

      const copy: ConditionConfiguration[] | undefined = cloneDeep(field.config.customConditions);
      const customConditionItem = copy.find((cc) => cc.guid === current.config.guid);
      if (
        customConditionItem &&
        (customConditionItem.parameter !== current.config.parameter ||
          customConditionItem.mandatory !== current.config.mandatory)
      ) {
        customConditionItem.parameter = current.config.parameter;
        customConditionItem.mandatory = current.config.mandatory;
      }
      if (customConditionItem) {
        customConditionItem.filter = { ...customConditionItem.filter, ...condition };
        updateField(field, { customConditions: copy });
      }
    },
    [field, updateField]
  );

  const onUpdateCustomLabel = React.useCallback(
    (condition: ConditionField, customLabel: string | undefined) => {
      const newConditions = updateCustomConditionProperty(condition, "customLabel", customLabel);
      if (newConditions !== undefined) {
        updateField(field, { customConditions: newConditions });
      }
    },
    [field, updateField, updateCustomConditionProperty]
  );

  const onShowMeasureOptions = React.useCallback(
    (settings: ShowFieldOptionsSettings<ConditionField>) => {
      setFieldOptions(settings);
      setShowMeasureOptions(true);
    },
    [setFieldOptions, setShowMeasureOptions]
  );

  useDeferredDictionaryLoading({ values: conditions, updateItem: onUpdateDictionary });

  const conditionsToShow = React.useMemo(() => {
    if (canBeRemoved) {
      return conditions;
    }
    return conditions.filter((c) => c.config.parameter === true);
  }, [conditions, canBeRemoved]);

  const linkedConditionsToShow = React.useMemo(() => {
    if (canBeRemoved) {
      return linkedConditions;
    }
    return [];
  }, [linkedConditions, canBeRemoved]);

  const linkableConditions = useMemo(() => {
    return globalConditions.filter((c) => !linkedConditions.some((lc) => lc.config.guid === c.config.guid));
  }, [globalConditions, linkedConditions]);

  const [addConditionAnchorEl, setAddConditionAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      {field.config.useCustomConditions && (
        <Grid2 container sx={{ flex: 1, flexDirection: "column", width: "100%" }}>
          <Grid2 container sx={{ flexDirection: "column", width: "100%" }}>
            <Divider sx={{ borderStyle: "dashed" }} />
            <MeasureItemConditions
              conditions={conditionsToShow}
              canBeRemoved={canBeRemoved}
              onRemoveItem={onRemoveConditionItem}
              onShowOptions={onShowMeasureOptions}
              disableEqualitySelection={disableEqualitySelection}
            />
            <MeasureItemConditions
              conditions={linkedConditionsToShow}
              canBeRemoved={canBeRemoved}
              onRemoveItem={onRemoveLinkedConditionItem}
              disableEqualitySelection={disableEqualitySelection}
            />
          </Grid2>
          {canBeRemoved && (
            <Grid2
              container
              sx={{ gap: 1, py: 0.625, px: 0.75, justifyContent: "space-evenly", cursor: "default", width: "100%" }}
              ref={setAddConditionAnchorEl}
            >
              <AddCustomConditions
                dimensions={fieldsForConditions}
                conditions={conditions}
                addCondition={addCondition}
                anchorEl={addConditionAnchorEl}
              />
              <LinkConditions
                linkableConditions={linkableConditions}
                disableEqualitySelection={disableEqualitySelection}
                setLinkedCondition={handleSetLinkCondition}
                anchorEl={addConditionAnchorEl}
              />
            </Grid2>
          )}
        </Grid2>
      )}
      {showMeasureOptions && fieldOptions?.ref && currentCondition && (
        <ConditionFieldOptionPopup
          anchorEl={fieldOptions.ref}
          field={currentCondition}
          showParameter={showConditionParameter}
          updateParameter={onUpdateAsParameter}
          updateMandatory={onUpdateAsMandatory}
          updateCustomLabel={(label) => {
            onUpdateCustomLabel(currentCondition, label);
            setShowMeasureOptions(false);
          }}
          save={(filter) => {
            updateCustomConditionFilterAndParameter(currentCondition, filter);
            setShowMeasureOptions(false);
          }}
          onRemoveItem={(item) => {
            onRemoveConditionItem(item);
            setShowMeasureOptions(false);
          }}
          canBeRemoved={canBeRemoved}
          dictionary={dictionary || currentCondition.meta.dictionary}
          resizable={field.meta.type !== ItemDataType.Date}
          disableEqualitySelection={disableEqualitySelection}
          close={() => setShowMeasureOptions(false)}
        />
      )}
    </>
  );
};

export default CustomConditions;
