import { CustomMeasureState, NodesCounter } from "../hooks/customMeasureState";
import { FormulaNode } from "./fillNodes";
import { INode, NodeBinary, NodeFunction, NodeVariable } from "./formulaParser";

export const isFormulaValid = (state: CustomMeasureState) => {
  if (state.caption === undefined || state.caption === "") return false;
  if (state.rootNode === undefined) return false;
  if (!allNodesValid(state.rootNode)) {
    return false;
  }
  if (!formulaNodesValid(state)) {
    return false;
  }

  return true;
};

const allNodesValid = (node: INode): boolean => {
  if (node instanceof NodeVariable) {
    return node.token.error === undefined;
  }
  if (node instanceof NodeFunction) {
    if (node.args === undefined || node.args.length === 0) return true;
    return node.args.every(allNodesValid);
  }
  if (node instanceof NodeBinary) {
    return allNodesValid(node.left) && allNodesValid(node.right);
  }

  return true;
};

const formulaNodesValid = (state: CustomMeasureState): boolean => {
  return state.formulaNodes.every(isFormulaNodeValid);
};

const isFormulaNodeValid = (formulaNode: FormulaNode): boolean => {
  const accountBasedCondition = formulaNode.conditions?.filter((c) => c.meta?.name !== "AccountingBook");
  if (formulaNode.function === undefined && accountBasedCondition !== undefined && accountBasedCondition.length > 0) {
    return accountBasedCondition.every(
      (condition) => condition.config.filter !== undefined && condition.config.filter.values.length > 0
    );
  }
  if (formulaNode.function !== undefined) {
    return (
      formulaNode.args !== undefined &&
      formulaNode.args.length === formulaNode.function.args.length &&
      formulaNode.args.every(isFormulaNodeValid)
    );
  }

  return false;
};

//eg. XIRR(A), A + 10, A + B, A
export const isValidFormulaStructure = (counter: NodesCounter) => {
  return counter.nodeFunction > 0 ? inStandAloneFunction(counter) : true;
};

export const isFormulaNumericOnly = (counter: NodesCounter) => {
  return counter.nodeNumber > 0 ? counter.nodeFunction === 0 && counter.nodeVariable === 0 : false;
};

//eg. XIRR(A) + 10
export const isStandAloneFunctionWithNumbers = (counter: NodesCounter) => {
  return counter.nodeFunction === 1 && counter.nodeVariable === 0 && counter.nodeNumber > 0;
};

//eg. XIRR(A), XIRR(A,B)
const inStandAloneFunction = (counter: NodesCounter) => {
  return counter.nodeFunction === 1 && counter.nodeVariable === 0 && counter.nodeNumber === 0;
};
