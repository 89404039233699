import { Box, FormControl, Select, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { ConditionDescriptor, ConditionEqualityType } from "../../../../../../shared/reporting/api/biClient.types";
import { selectReportEditMode } from "../../../../../store/currentReportSlice";
import { formatDate } from "../../../../../utilities/Utilities";
import BcDatePicker from "../../../../common/bc-datepicker/BcDatePicker";
import { ConditionField } from "../../../Types";

enum DateType {
  Period = 0,
  Date = 1,
}

const equalityOptions = [
  { caption: "Equal =", value: ConditionEqualityType.Equal },
  { caption: "Less than <", value: ConditionEqualityType.LessThan },
  { caption: "Less than or equal to ≤", value: ConditionEqualityType.LessThanOrEqual },
];

const periodOptions = [
  { value: DateType.Date, caption: "Day" },
  { value: DateType.Period, caption: "Period" },
];

const singleDateDefaultEqualityType = ConditionEqualityType.Equal;

interface Props {
  field: ConditionField;
  sx?: SxProps<Theme>;
  disableEqualitySelection?: boolean;
  onFilterUpdated: (filter: ConditionDescriptor) => void;
}

export default function DateFilter({ field, onFilterUpdated, sx, disableEqualitySelection }: Props) {
  const editMode = useSelector(selectReportEditMode);
  const [periodType, setPeriodType] = React.useState(DateType.Period);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [dayEqualityType, setDayEqualityType] = React.useState<ConditionEqualityType>();

  const hidePeriodType = React.useMemo(
    () => editMode === false && field.config.parameter === true,
    [editMode, field.config.parameter]
  );

  const handlePeriodTypeChange = (selectedPeriod: DateType) => {
    if (selectedPeriod === DateType.Date) {
      setDayEqualityType(singleDateDefaultEqualityType);
    }
    setPeriodType(selectedPeriod);
  };

  React.useEffect(() => {
    const filterValuesCount = field.config.filter?.values?.length || 0;
    if (filterValuesCount > 0) {
      const fromValue = field.config.filter?.values[0];
      if (fromValue === undefined || fromValue === null) return;
      const fromValueDate = new Date(fromValue);
      setFromDate(fromValueDate);
      if (filterValuesCount === 2) {
        const toValue = field.config.filter?.values[1] || "";
        const toValueDate = new Date(toValue);
        setToDate(toValueDate);
      }
    }
    const currentPeriod = filterValuesCount > 1 ? DateType.Period : DateType.Date;
    setPeriodType(currentPeriod);
    if (currentPeriod === DateType.Date) {
      setDayEqualityType(field.config.filter?.equalityType || singleDateDefaultEqualityType);
    }
  }, [field.config.filter?.values, field.config.filter?.equalityType]);

  React.useEffect(() => {
    const newFilter: ConditionDescriptor = {
      dimensionName: field.meta.name,
      values: [],
      equalityType: periodType === DateType.Period ? ConditionEqualityType.Equal : dayEqualityType,
    };
    newFilter.values.push(formatDate(fromDate));
    if (periodType === DateType.Period) {
      newFilter.values.push(formatDate(toDate));
    }
    onFilterUpdated(newFilter);
  }, [fromDate, toDate, periodType, field.meta.name, onFilterUpdated, dayEqualityType]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: "1rem", width: "320px", ...sx }}>
      {!hidePeriodType && (
        <ToggleButtonGroup
          exclusive
          fullWidth
          color="primary"
          value={periodType}
          onChange={(_, value: DateType | null) => {
            if (value !== null) {
              handlePeriodTypeChange(value);
            }
          }}
        >
          {periodOptions.map((item) => (
            <ToggleButton key={item.value} value={item.value}>
              {item.caption}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      {disableEqualitySelection !== true && periodType === DateType.Date && dayEqualityType && (
        <FormControl size="small" sx={sx}>
          <Select
            value={dayEqualityType}
            size="small"
            onChange={(e) => setDayEqualityType(e.target.value as ConditionEqualityType)}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "divider",
              },
            }}
          >
            {equalityOptions.map((v) => (
              <MenuItemStyled key={v.value} value={v.value}>
                <Typography>{v.caption}</Typography>
              </MenuItemStyled>
            ))}
          </Select>
        </FormControl>
      )}
      <Box sx={{ display: "flex", flex: 1, flexDirection: "row", gap: ".5rem" }}>
        <BcDatePicker
          value={fromDate}
          maxDate={periodType === DateType.Period ? toDate : undefined}
          onChange={(newValue) => {
            if (newValue && !isNaN(newValue.getTime())) {
              setFromDate(newValue);
            }
          }}
        />
        {periodType === DateType.Period && (
          <BcDatePicker
            value={toDate}
            minDate={fromDate}
            onChange={(newValue) => {
              if (newValue && !isNaN(newValue.getTime())) {
                setToDate(newValue);
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
}
