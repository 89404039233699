import { Box, Divider, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useClientPermissionsContext } from "../../../shared/contexts/ClientPermissionsContext";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import useRequest from "../../../shared/hooks/useRequest";
import biClient from "../../../shared/reporting/api/biClient";
import { ReportTemplate, ReportType, Status } from "../../../shared/reporting/api/biClient.types";
import SwitchStatusDialog from "../../../shared/reporting/components/reportTemplates/dialogs/SwitchStatusDialog";
import { useSharedReportingLocalization } from "../../../shared/reporting/hooks/useLocalization";
import { useClientContext } from "../../contexts/ClientContext";
import { currentReportActions, selectCurrentReport, selectReportConfiguration } from "../../store/currentReportSlice";
import { selectCompanies } from "../../store/reportTemplatesSlice";
import { AppDispatch } from "../../store/store";
import { setReport } from "../../store/thunks";
import PartContainer from "../builder/PartContainer";
import ChartEditor from "../builder/chart/ChartEditor";
import CustomDragLayerInternal from "../builder/common/dragLayer/CustomDragLayer";
import FieldsComponent from "../builder/common/fields/Fields";
import { getEditTemplateUrl, getOrganizationReportTemplatesUrl } from "../builder/common/utilities/editReportUrl";
import { DeveloperPanelWrapper } from "../builder/dev/DeveloperPanelWrapper";
import PivotEditor from "../builder/pivot/PivotEditor";
import TabularEditor from "../builder/tabular/TabularEditor";
import { isChartReport } from "../builder/tabular/utilities/isChartReport";
import CollapseContainer from "../common/CollapseContainer";
import EntityLevelAccess from "../common/EntityLevelAccess";
import MoreAction from "./MoreAction";
import SaveTemplate from "./SaveTemplate";
import SwitchStatusButton from "./SwitchStatusButton";
import TemplateActionHeader from "./TemplateActionHeader";
import TemplateEditor from "./TemplateEditor";
import TemplateNotFound from "./TemplateNotFound";

export default function EditTemplatePage() {
  const { report_templates: locale } = useSharedReportingLocalization();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [showConfirmStatusChange, setShowConfirmStatusChange] = useState(false);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const template = useSelector(selectCurrentReport) as ReportTemplate;
  const companies = useSelector(selectCompanies);
  const { clientInfo } = useClientContext();
  const { hasAnyPermission } = useClientPermissionsContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const chartTemplate = useMemo(() => isChartReport(template), [template]);

  const statusRef = useRef<Status>(template?.status || Status.Draft);
  const switchStatus = useCallback(
    () =>
      biClient.updateReportTemplateStatus({
        templateId: template.reportId,
        status: statusRef.current,
      }),
    [template.reportId]
  );

  const organizationTemplatesUrl = useMemo(
    () => getOrganizationReportTemplatesUrl(clientInfo.organization),
    [clientInfo.organization]
  );

  const { request, isLoading: switching } = useRequest(switchStatus);

  const handleStatusChange = async () => {
    const [, error] = await request();
    setShowConfirmStatusChange(false);
    if (!error) {
      dispatch(currentReportActions.updateReport({ status: statusRef.current }));
      sendNotification(locale.status_changed);
      return;
    }
    sendNotificationError(locale.status_change_error);
  };

  useEffect(() => {
    dispatch(currentReportActions.updateReportMode(true));
  }, [dispatch]);

  if (template === undefined) return <></>;

  if (!hasAnyPermission(["ManageOrganizationReportTemplates"])) {
    return <TemplateNotFound backUrl={organizationTemplatesUrl} />;
  }

  if (companies.length === 0)
    return <TemplateNotFound backUrl={getOrganizationReportTemplatesUrl(clientInfo.organization)} />;

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack flex={1}>
        <TemplateActionHeader template={template} clientTitle={clientInfo.title} companies={companies}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <EntityLevelAccess />
            {template?.reportId && (
              <SwitchStatusButton
                currentStatus={template.status}
                disabled={switching}
                onClick={() => setShowConfirmStatusChange(true)}
              />
            )}
            <SaveTemplate template={template} companies={companies} clientInfo={clientInfo} />
            {template.reportId !== undefined && (
              <MoreAction
                template={template}
                templateConfiguration={reportConfiguration}
                companies={companies}
                onEdited={(rt, dataSourceChanged) => {
                  dispatch(setReport(rt));
                  if (dataSourceChanged) {
                    navigate(
                      getEditTemplateUrl(
                        rt.dataSource || clientInfo.clientCode,
                        template.reportId,
                        template.organization
                      )
                    );
                  }
                }}
                onDataSourceChanged={(ds) =>
                  navigate(getEditTemplateUrl(ds || clientInfo.clientCode, template.reportId, template.organization))
                }
              />
            )}
          </Stack>
        </TemplateActionHeader>
        <Divider />
        <Box sx={{ display: "flex", flex: 1 }}>
          <CollapseContainer
            expandCollapsePaddingTop={25}
            collapseElement={
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.text.primary, transform: "rotate(90deg)" })}
              >
                Fields
              </Typography>
            }
          >
            <PartContainer sx={{ maxWidth: "21rem", minWidth: "21rem" }} caption={<Box></Box>}>
              <FieldsComponent />
            </PartContainer>
          </CollapseContainer>
          {(!template.reportType || template.reportType === ReportType.Pivot) && (
            <TemplateEditor Editor={PivotEditor} template={template} />
          )}
          {template.reportType === ReportType.Tabular && <TemplateEditor Editor={TabularEditor} template={template} />}
          {chartTemplate && <TemplateEditor Editor={ChartEditor} template={template} />}
          <DeveloperPanelWrapper />
        </Box>
        {showConfirmStatusChange && (
          <SwitchStatusDialog
            templateName={template.name}
            currentStatus={template.status}
            onClose={() => setShowConfirmStatusChange(false)}
            onConfirm={(status) => {
              statusRef.current = status;
              handleStatusChange();
            }}
            loading={switching}
          />
        )}
      </Stack>
      <CustomDragLayerInternal />
    </DndProvider>
  );
}
