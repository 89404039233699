import { Slice } from "@reduxjs/toolkit";
import { useCallback, useReducer } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractStateType<S> = S extends Slice<infer State, any, string> ? State : never;

export default function useReducerSafe<S extends Slice, T extends keyof S["actions"]>(
  slice: S
): [ExtractStateType<S>, (action: ReturnType<S["actions"][T]>) => void] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [stateSafe, dispatch] = useReducer(slice.reducer, slice.getInitialState());
  const dispatchSafe = useCallback(
    (action: ReturnType<S["actions"][T]>) => {
      return dispatch(action);
    },
    [dispatch]
  );

  return [stateSafe as ExtractStateType<S>, dispatchSafe];
}
