import { createAsyncThunk } from "@reduxjs/toolkit";
import { Report, ReportTemplate } from "../../shared/reporting/api/biClient.types";
import { currentReportActions } from "./currentReportSlice";
import { metaDataActions } from "./metaDataSlice";
import { RootState } from "./RootState";

export const setReport = createAsyncThunk(
  "store/setReport",

  async (report: Report, b) => {
    const state = b.getState() as RootState;
    const newDataSetId = report.dataSetId ?? state.metaData.dataSets[0]?.dataSetId;
    let dataSetChanged = false;
    if (newDataSetId && newDataSetId !== state.metaData.dataSetId) {
      b.dispatch(metaDataActions.preSetDataSet(newDataSetId));
      dataSetChanged = true;
    }

    if (dataSetChanged) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        b.dispatch(currentReportActions.setReport(report));
      }, 1000);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      b.dispatch(currentReportActions.setReport(report));
    }

    return Promise.resolve();
  }
);

export const applyTemplate = createAsyncThunk(
  "store/applyTemplate",

  async (template: ReportTemplate, b) => {
    const state = b.getState() as RootState;
    const newDataSetId = template.dataSetId ?? state.metaData.dataSets[0]?.dataSetId;
    let dataSetChanged = false;
    if (newDataSetId && newDataSetId !== state.metaData.dataSetId) {
      dataSetChanged = true;
      b.dispatch(metaDataActions.preSetDataSet(newDataSetId));
    }

    if (dataSetChanged) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        b.dispatch(currentReportActions.applyTemplateOnReport(template));
      }, 1000);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      b.dispatch(currentReportActions.applyTemplateOnReport(template));
    }

    return Promise.resolve();
  }
);
