import { Divider, Grid2 } from "@mui/material";
import React from "react";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";
import { getLinearSettings, isLinearSettings } from "../../contexts/FieldsStateContext.types";
import StyleGroup from "../StyleGroup";
import ValueAxisItem from "./ValueAxisItem";

export default function ValueAxisStyles() {
  const { settingsArea } = useFieldsStateContext();
  const settings = React.useMemo(() => getLinearSettings(settingsArea.settings), [settingsArea]);
  const supported = React.useMemo(() => isLinearSettings(settingsArea.settings), [settingsArea.settings]);

  if (!supported) {
    return <></>;
  }

  return (
    <StyleGroup caption="Value Axes">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        {settings?.valueAxis?.map((axis, index) => {
          const lastSerie = settings?.valueAxis?.length === index + 1;
          const serieItem = <ValueAxisItem key={axis.name} area={settingsArea} axis={axis} />;
          if (!lastSerie) {
            return [serieItem, <Divider key={`Divider_values_${index}`} sx={{ borderStyle: "dashed" }} />];
          }
          return serieItem;
        })}
      </Grid2>
    </StyleGroup>
  );
}
