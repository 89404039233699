import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Grid2, Menu, MenuItem, Typography } from "@mui/material";
import { useRef, useState } from "react";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { ConditionField } from "../../../Types";
import { getIcon } from "../../../common/fields/FieldItem.helper";
import { formatCaption } from "../conditions/conditionsHelper";

interface Props {
  linkableConditions: ConditionField[];
  disableEqualitySelection?: boolean;
  anchorEl: HTMLElement | null;
  setLinkedCondition: (condition: ConditionField) => void;
}

export const LinkConditions = (props: Props) => {
  const { linkableConditions, disableEqualitySelection, setLinkedCondition, anchorEl } = props;

  const [showAddConditions, setShowAddConditions] = useState(false);
  const areaFieldItemWidth = useRef<number>(0);

  return (
    <>
      <Button
        color="secondary"
        startIcon={<AddRoundedIcon />}
        onClick={(e) => {
          setShowAddConditions(true);
          const parentWidth = e.currentTarget.parentElement?.offsetWidth;
          if (parentWidth === undefined) return;
          areaFieldItemWidth.current = parentWidth;
        }}
        disabled={linkableConditions.length === 0}
      >
        Link Condition
      </Button>
      {anchorEl && showAddConditions && (
        <Menu
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: {
                boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
                width: "100%",
                maxWidth: `${areaFieldItemWidth.current}px`,
                maxHeight: "350px",
              },
            },
          }}
          onClose={() => setShowAddConditions(false)}
        >
          <Typography variant="subtitle2" color={"secondary"} sx={{ px: 2, py: 0.625 }}>
            Link Condition
          </Typography>
          {linkableConditions.map((condition) => (
            <MenuItem
              key={condition.config.guid}
              onClick={() => {
                setLinkedCondition({ ...cloneDeep(condition) });
                setShowAddConditions(false);
              }}
              sx={{ pl: 3, py: 1 }}
            >
              <Grid2
                display={"flex"}
                flex={1}
                alignItems={"center"}
                gap={1.5}
                sx={(t) => ({
                  width: "100%",
                  "& .MuiSvgIcon-root": {
                    color: t.palette.secondary.light,
                  },
                })}
              >
                {getIcon(condition.meta)}
                {formatCaption(condition, undefined, disableEqualitySelection, "body1")}
              </Grid2>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
export default LinkConditions;
