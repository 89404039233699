import { Grid2, Tab, Tabs } from "@mui/material";
import React from "react";
import { MeasureUnitTable } from "../../../../../shared/reporting/api/biClient.types";
import { DrillDownInfo } from "../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";
import { PersistedTabPanel } from "../../../common/PersistedTabPanel";
import ChartOfAccountsPart from "./chartOfAccounts/ChartOfAccountsPart";
import LedgerPart from "./ledger/LedgerPart";
import { getLedgerTitle } from "./utils/getLedgerTitle";

enum MainPageTab {
  ChartOfAccounts = "chartOfAccounts",
  GeneralLedger = "gl",
  MemoLedger = "memogl",
}

interface Props {
  configuration: DrillDownConfiguration;
  info: DrillDownInfo;
}

export default function MainPage({ configuration, info }: Props) {
  const glInfo = info.items.find((item) => item.table === MeasureUnitTable.Gl);
  const memoInfo = info.items.find((item) => item.table === MeasureUnitTable.Memo);
  const [selectedTab, setSelectedTab] = React.useState<MainPageTab>(() => {
    if (configuration.chartOfAccounts) {
      return MainPageTab.ChartOfAccounts;
    }
    if (glInfo) {
      return MainPageTab.GeneralLedger;
    }
    if (memoInfo) {
      return MainPageTab.MemoLedger;
    }
    return MainPageTab.ChartOfAccounts;
  });

  return (
    <>
      <Tabs value={selectedTab} sx={{ pl: 2.5 }} onChange={(_, value: MainPageTab) => setSelectedTab(value)}>
        {configuration.chartOfAccounts && (
          <Tab key={MainPageTab.ChartOfAccounts} label="Chart Of Accounts" value={MainPageTab.ChartOfAccounts} />
        )}
        {glInfo && (
          <Tab key={MainPageTab.GeneralLedger} label={getLedgerTitle(glInfo)} value={MainPageTab.GeneralLedger} />
        )}
        {memoInfo && (
          <Tab key={MainPageTab.MemoLedger} label={getLedgerTitle(memoInfo)} value={MainPageTab.MemoLedger} />
        )}
      </Tabs>
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        {configuration.chartOfAccounts && (
          <PersistedTabPanel
            key={MainPageTab.ChartOfAccounts}
            value={MainPageTab.ChartOfAccounts}
            selectedTab={selectedTab}
          >
            <ChartOfAccountsPart configuration={configuration} info={info} />
          </PersistedTabPanel>
        )}
        {glInfo && (
          <PersistedTabPanel
            key={MainPageTab.GeneralLedger}
            value={MainPageTab.GeneralLedger}
            selectedTab={selectedTab}
          >
            <LedgerPart configuration={configuration} info={glInfo} />
          </PersistedTabPanel>
        )}
        {memoInfo && (
          <PersistedTabPanel key={MainPageTab.MemoLedger} value={MainPageTab.MemoLedger} selectedTab={selectedTab}>
            <LedgerPart configuration={configuration} info={memoInfo} />
          </PersistedTabPanel>
        )}
      </Grid2>
    </>
  );
}
