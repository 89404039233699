import { Button, Stack, Typography } from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";

interface Props {
  companyName: string;
  reportName: string | undefined;
  isMenuOpened: boolean;
  onOpenReportSelectorMenu: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
}

const ReportSelectorButton = ({ companyName, reportName, onOpenReportSelectorMenu, isMenuOpened }: Props) => {
  return (
    <Button
      aria-haspopup="true"
      aria-expanded={isMenuOpened ? "true" : undefined}
      color="secondary"
      sx={{ px: 0.75 }}
      onClick={onOpenReportSelectorMenu}
      endIcon={
        isMenuOpened ? (
          <ArrowDropUpRoundedIcon color="action" fontSize="medium" />
        ) : (
          <ArrowDropDownRoundedIcon color="action" fontSize="medium" />
        )
      }
    >
      <Stack useFlexGap spacing={0.25} sx={(t) => ({ maxWidth: t.spacing(25) })}>
        <Typography color="text.primary" variant="h6" noWrap>
          {reportName}
        </Typography>
        <Typography color="text.secondary" variant="caption" alignSelf="flex-start">
          {companyName}
        </Typography>
      </Stack>
    </Button>
  );
};

export default ReportSelectorButton;
