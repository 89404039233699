import { ComponentType, JSX, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ExportImageIcon from "../../../../icons/ExportImageIcon";
import ExportPdfIcon from "../../../../icons/ExportPdfIcon";
import ExportSvgIcon from "../../../../icons/ExportSvgIcon";
import { selectCurrentReport } from "../../../../store/currentReportSlice";
import { useExportStateContext } from "../../common/contexts/ExportStateContext";
import ExportMenuItem from "../../common/exports/ExportMenuItem";
import { ExportChartFormats } from "../ChartProps";
import { PreviewChartStateType } from "../hooks/useChartStateBuilder";
import useUpdateExportState from "../hooks/useUpdateExportState";

interface Props {
  state: PreviewChartStateType;
  exportFuncCallback?: (callback: ((fileName: string, format: ExportChartFormats) => void) | undefined) => void;
}

export default function withExport<T extends Props>(WrappedComponent: ComponentType<T>) {
  return function useExport(props: T) {
    const exportState = useExportStateContext();
    const report = useSelector(selectCurrentReport);
    const exportFuncRef = useRef<(fileName: string, format: ExportChartFormats) => void>();

    useUpdateExportState(props.state);

    useLayoutEffect(() => {
      exportState.actions.setExportMenuCallback((renderMenuCallback: () => void) => {
        return exportFormats.map((format, i) => (
          <ExportMenuItem
            key={i}
            Icon={format.icon}
            onClick={() => {
              renderMenuCallback();
              exportFuncRef.current?.call(null, report?.name || "Exported Chart", format.ext);
            }}
            title={format.ext}
          />
        ));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report?.name]);

    return <WrappedComponent {...props} exportFuncCallback={(cb) => (exportFuncRef.current = cb)} />;
  };
}

const exportFormats: { icon: JSX.Element; ext: ExportChartFormats }[] = [
  { icon: <ExportPdfIcon />, ext: "PDF" },
  { icon: <ExportImageIcon />, ext: "PNG" },
  { icon: <ExportImageIcon />, ext: "JPEG" },
  { icon: <ExportSvgIcon />, ext: "SVG" },
];
