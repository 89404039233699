import React, { PropsWithChildren, useContext } from "react";
import {
  AxisSettings,
  BasicSeriesSettings,
  ChartStyleSettings,
  DimensionField,
  FieldConfiguration,
  LegendSettings,
  ValueAxisType,
} from "../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { ConditionFieldsArea, FieldArea } from "../../common/fields/FieldArea";
import { SortingFieldsArea, ValueFieldsArea } from "../../pivot/types";

export type ArgumentFieldsArea = FieldArea<DimensionField, FieldConfiguration> & {
  addItem: (item: DimensionField, index: number) => void;
};

export type SettingsArea = {
  settings: ChartStyleSettings;
  update: (changes: Partial<ChartStyleSettings>) => void;
  updateLegend: (changes: Partial<LegendSettings>) => void;
  updateArgumentAxis: (changes: Partial<AxisSettings>) => void;
  updateValueAxis: (name: string, changes: Partial<AxisSettings>) => void;
  updateSerie: <T extends BasicSeriesSettings>(name: string, changes: Partial<T>) => void;
  updateSerieAxis: (name: string, axis: ValueAxisType) => void;
  addSerie: (name: string) => void;
  removeSerie: (name: string) => void;
};

export type FieldsStateContextType = {
  conditionsArea: ConditionFieldsArea;
  argumentsArea: ArgumentFieldsArea;
  valuesArea: ValueFieldsArea;
  sortingArea: SortingFieldsArea;
  settingsArea: SettingsArea;
};

const FieldsStateContext = React.createContext<FieldsStateContextType | undefined>(undefined);

export const FieldsStateContextProvider = ({ children, ...props }: PropsWithChildren<FieldsStateContextType>) => (
  <FieldsStateContext.Provider value={props}>{children}</FieldsStateContext.Provider>
);

export const useFieldsStateContext = () => {
  const context = useContext(FieldsStateContext);
  return defined(context);
};
