import {
  AreaItemType,
  AxisSettings,
  ChartStyleSettings,
  ConditionConfiguration,
  DimensionDescriptor,
  DimensionField,
  FieldConfiguration,
  LegendSettings,
  Ordering,
  ReportField,
  ReportType,
  SeriesSettings,
  SortConfiguration,
  ValueAxisType,
} from "../../../../../shared/reporting/api/biClient.types";
import sortsSlice, {
  addSortingAction,
  moveSortingAction,
  removeSortingAction,
  removeSortingByMetaAction,
  setSortingAction,
  updateSortingAction,
  updateSortingConfigAction,
} from "../../common/hooks/fieldsState.sorts";
import useReducerSafe from "../../common/hooks/useReducerSafe";
import { ConditionField, SortField } from "../../Types";
import fieldsStateChartSlice, { fieldStateActions } from "./fieldsState.charts";

export default function useFieldsState() {
  const [state, dispatch] = useReducerSafe(fieldsStateChartSlice);
  const [sortingState, dispatchSorting] = useReducerSafe(sortsSlice);

  //CONDITIONS
  const setConditions = (fields: ConditionField[]) => dispatch(fieldStateActions.setConditionsAction(fields));

  const addCondition = (field: ConditionField, index: number) => {
    dispatch(fieldStateActions.addConditionAction({ field, index: index }));
  };

  const removeCondition = (field: ConditionField) => dispatch(fieldStateActions.removeConditionAction(field));

  const moveCondition = (field: ConditionField, newIndex: number) =>
    dispatch(fieldStateActions.moveConditionAction({ field, newIndex }));

  const updateCondition = (field: ConditionField, changes: Partial<ConditionField>) =>
    dispatch(fieldStateActions.updateConditionAction({ field, changes }));

  const updateConditionConfig = (field: ConditionField, changes: Partial<ConditionConfiguration>) =>
    dispatch(fieldStateActions.updateConditionConfigAction({ field, changes }));

  //ARGUMENTS
  const setArguments = (fields: DimensionField[]) => dispatch(fieldStateActions.setArgumentsAction(fields));

  const addArgument = (field: DimensionField, index: number) => {
    dispatch(fieldStateActions.addArgumentAction({ field, index: index }));
  };

  const removeArgument = (field: DimensionField) => {
    removeSortingByMeta(field.meta);
    dispatch(fieldStateActions.removeArgumentAction(field));
  };

  const moveArgument = (field: DimensionField, newIndex: number) =>
    dispatch(fieldStateActions.moveArgumentAction({ field, newIndex }));

  const updateArgument = (field: DimensionField, changes: Partial<DimensionField>) =>
    dispatch(fieldStateActions.updateArgumentAction({ field, changes }));

  const updateArgumentConfig = (field: DimensionField, changes: Partial<FieldConfiguration>) => {
    dispatch(fieldStateActions.updateArgumentConfigAction({ field, changes }));
    if (field.config.customLabel !== changes.customLabel) {
      const sortingField = sortingState.sorts.find((s) => s.meta.name === field.meta.name);
      if (sortingField) {
        updateSortingConfig(sortingField, { caption: changes.customLabel });
      }
    }
  };

  //MEASURES
  const setMeasures = (fields: ReportField[]) => {
    dispatch(fieldStateActions.setMeasuresAction(fields));
    fields.forEach((field) => addSerie(field.config.guid));
  };

  const addMeasure = (field: ReportField, index: number) => {
    dispatch(fieldStateActions.addSeriesAction(field.config.guid));
    dispatch(fieldStateActions.addMeasureAction({ field, index: index }));
  };

  const removeMeasure = (field: ReportField) => {
    dispatch(fieldStateActions.removeSeriesAction(field.config.guid));
    dispatch(fieldStateActions.removeMeasureAction(field));
  };

  const moveMeasure = (field: ReportField, newIndex: number) =>
    dispatch(fieldStateActions.moveMeasureAction({ field, newIndex }));

  const updateMeasure = (field: ReportField, changes: Partial<ReportField>) =>
    dispatch(fieldStateActions.updateMeasureAction({ field, changes }));

  const updateMeasureConfig = (field: ReportField, changes: Partial<FieldConfiguration>) =>
    dispatch(fieldStateActions.updateMeasureConfigAction({ field, changes }));

  //SORTING
  const setSorting = (fields: SortField[]) => dispatchSorting(setSortingAction({ fields, groupMetaNames: [] }));

  const addSorting = (field: DimensionField) => {
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatchSorting(addSortingAction(conditionField));
  };

  const removeSorting = (field: SortField) => dispatchSorting(removeSortingAction(field));

  const removeSortingByMeta = (field: DimensionDescriptor) => dispatchSorting(removeSortingByMetaAction(field));

  const moveSorting = (field: SortField, newIndex: number) => dispatchSorting(moveSortingAction({ field, newIndex }));

  const updateSorting = (field: SortField, changes: Partial<SortField>) =>
    dispatchSorting(updateSortingAction({ field, changes }));

  const updateSortingConfig = (field: SortField, changes: Partial<SortConfiguration>) =>
    dispatchSorting(updateSortingConfigAction({ field, changes }));

  //SETTINGS
  const setSettings = (settings: ChartStyleSettings) => dispatch(fieldStateActions.setSetingsAction(settings));
  const updateReportTypeSettings = (type: ReportType) =>
    dispatch(fieldStateActions.updateReportTypeSettingsAction(type));
  const updateSettings = (changes: Partial<ChartStyleSettings>) =>
    dispatch(fieldStateActions.updateSettingsAction(changes));
  const updateLegendSettings = (changes: Partial<LegendSettings>) =>
    dispatch(fieldStateActions.updateLegendSettingsAction(changes));
  const updateArgumentAxisSettings = (changes: Partial<AxisSettings>) =>
    dispatch(fieldStateActions.updateAxisArgumentSettingsAction(changes));
  const updateValueAxisSettings = (name: string, changes: Partial<AxisSettings>) =>
    dispatch(fieldStateActions.updateAxisValueSettingsAction({ name, changes }));
  const addSerie = (name: string) => dispatch(fieldStateActions.addSeriesAction(name));
  const removeSerie = (name: string) => {
    dispatch(fieldStateActions.removeSeriesAction(name));
  };
  const updateSeriesSettings = (name: string, changes: Partial<SeriesSettings>) =>
    dispatch(fieldStateActions.updateSeriesSettingsAction({ name, changes }));
  const updateSerieValueAxis = (name: string, axis: ValueAxisType) =>
    dispatch(fieldStateActions.updateSerieValueAxisSettingsAction({ name, axis }));

  const resetToDefault = () => dispatch(fieldStateActions.resetToDefaultAction());

  return {
    ...state,
    ...sortingState,

    setConditions,
    addCondition,
    updateCondition,
    updateConditionConfig,
    removeCondition,
    moveCondition,

    setArguments,
    addArgument,
    updateArgument,
    updateArgumentConfig,
    removeArgument,
    moveArgument,

    setMeasures,
    addMeasure,
    updateMeasure,
    updateMeasureConfig,
    removeMeasure,
    moveMeasure,

    setSorting,
    addSorting,
    removeSorting,
    removeSortingByMeta,
    moveSorting,
    updateSorting,
    updateSortingConfig,

    setSettings,
    updateSettings,
    updateReportTypeSettings,
    updateLegendSettings,
    updateArgumentAxisSettings,
    updateValueAxisSettings,
    addSerie,
    removeSerie,
    updateSeriesSettings,
    updateSerieValueAxis,

    resetToDefault,
  };
}

export type FieldsStateReturnType = ReturnType<typeof useFieldsState>;
