let currentDataSet = "";

export const updateDataSet = (dataSet: string) => {
  currentDataSet = dataSet;
};

export const getDataSet = () => {
  return currentDataSet;
};

export const dataSetHeader = () => ({ ["X-Entrilia-BI-DataSet"]: currentDataSet });
