import { Grid2, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MeasureDescriptor, MeasureUnitTable } from "../../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../../shared/reporting/utils/checkUnitTable";
import { ChartOfAccounts } from "../../../../api/biApi.types";
import { selectAccountBookSettings } from "../../../../store/clientSettingsSlice";
import { PersistedTabPanel } from "../../../common/PersistedTabPanel";
import LedgerAccountsList from "./LedgerAccountsList";
import { useChartOfAccountsContext } from "./contexts/ChartOfAccountsContext";
import { Book, getAccountingBooks } from "./getAccountingBooks";

interface LedgerAccountsListProps {
  chartOfAccounts: ChartOfAccounts | undefined;
  measure: MeasureDescriptor;
}
export default function LedgerAccountsMultiBookContainer({ chartOfAccounts, measure }: LedgerAccountsListProps) {
  const { selectedAccounts, scrollTargetAccount } = useChartOfAccountsContext();
  const accountingBookSettings = useSelector(selectAccountBookSettings);

  const hasGl = useMemo(() => {
    return Object.values(measure.units).some((unit) => checkUnitTable(unit.table, MeasureUnitTable.Gl));
  }, [measure]);

  const hasMemo = useMemo(() => {
    return Object.values(measure.units).some((unit) => checkUnitTable(unit.table, MeasureUnitTable.Memo));
  }, [measure]);

  const books = useMemo(
    () => getAccountingBooks(hasGl, hasMemo, accountingBookSettings),
    [accountingBookSettings, hasGl, hasMemo]
  );

  const [selectedTab, setSelectedTab] = useState(books[0]?.code);

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      const isGl = chartOfAccounts?.gl.find((account) => selectedAccounts.includes(account.accountNo));
      if (isGl) {
        setSelectedTab(isGl.accountingBook);
        return;
      }
      const isMemo = chartOfAccounts?.memo.some((account) => selectedAccounts.includes(account.accountNo));
      if (isMemo) {
        setSelectedTab(null);
      }
    }
  }, [chartOfAccounts?.gl, chartOfAccounts?.memo, selectedAccounts]);

  useEffect(() => {
    if (scrollTargetAccount?.accountNo) {
      const isGl = chartOfAccounts?.gl.find((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isGl) {
        setSelectedTab(isGl.accountingBook);
        return;
      }
      const isMemo = chartOfAccounts?.memo.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isMemo) {
        setSelectedTab(null);
      }
    }
  }, [chartOfAccounts?.gl, chartOfAccounts?.memo, scrollTargetAccount?.accountNo]);

  const filterChartOfAccounts = useCallback(
    (book: Book) => {
      if (book.ledger === MeasureUnitTable.Memo) {
        return chartOfAccounts?.memo;
      }
      return chartOfAccounts?.gl.filter((item) => item.accountingBook === book.code);
    },
    [chartOfAccounts]
  );

  return (
    <Grid2 container sx={{ py: 2.5, pr: 3, pl: 1.5, gap: 2, flexDirection: "column", width: "100%" }}>
      <Tabs value={selectedTab} sx={{ pl: 2.5 }} onChange={(_, value: string | null) => setSelectedTab(value)}>
        {books.map((book) => (
          <Tab key={book.key} label={book.title} value={book.code} />
        ))}
      </Tabs>
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        {books.map((book) => (
          <PersistedTabPanel key={book.key} value={book.code} selectedTab={selectedTab}>
            <LedgerAccountsList chartOfAccounts={filterChartOfAccounts(book)} ledger={book.ledger} />
          </PersistedTabPanel>
        ))}
      </Grid2>
    </Grid2>
  );
}
