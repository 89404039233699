import { Alert, Grid2 } from "@mui/material";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { IrrDrillDownInfo } from "../../../../../api/biApi.types";
import useDrillDown from "../../../../../hooks/useDrillDownState";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";
import CollapseContainer from "../../../../common/CollapseContainer";
import useDeferredDictionaryLoading from "../../hooks/useDeferredDictionaryLoading";
import ConditionsCollapsedHeader from "../ConditionsCollapsedHeader";
import ReadonlyConditions from "../ReadonlyConditions";
import { CashFlowGrid } from "./CashFlowGrid";
import ToolBar from "./ToolBar";

export interface Props {
  configuration: DrillDownConfiguration;
  info: IrrDrillDownInfo;
}

export default function CashFlowPart({ configuration, info }: Props) {
  const state = useDrillDown(info);

  useDeferredDictionaryLoading({ values: state.conditions, updateItem: state.actions.updateCondition });

  return (
    <Grid2 container sx={{ position: "relative", flexDirection: "row", width: "100%" }}>
      <CollapseContainer collapseElement={<ConditionsCollapsedHeader conditionsCount={state.conditions.length} />}>
        <Grid2
          sx={(theme) => ({
            flex: 1,
            maxWidth: 340,
            width: "100%",
            p: "1.3rem",
            pl: 3,
            borderRight: "1px solid " + theme.palette.divider,
          })}
        >
          <ReadonlyConditions state={state} configuration={configuration} info={info} />
        </Grid2>
      </CollapseContainer>
      <Grid2 container sx={{ flex: 1, width: "unset", flexDirection: "column" }}>
        <Grid2 container sx={{ flex: 1, width: "unset", flexDirection: "column", pl: "1.3rem", py: 2, pr: 3, gap: 2 }}>
          <ToolBar configuration={configuration} info={info} />
          {state.error && (
            <Alert sx={{ mb: ".5rem" }} severity="error">
              {state.error}
            </Alert>
          )}
          <ScrollableFlexContainer scrollContainerSx={{ mb: 1 }}>
            <CashFlowGrid configuration={configuration} info={info} />
          </ScrollableFlexContainer>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
