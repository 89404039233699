import { GridBasicGroupNode } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { DimensionField, MeasureCell, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { isDimensionBased } from "../../../utils/fieldsHelper";

export function getGroupKeys(rowNode: GridBasicGroupNode, fields: ReportField[], api: GridApiPremium) {
  const filters: Record<string, Partial<MeasureCell>> = {};
  if (rowNode.groupingKey !== null) {
    const field = fields.find((f) => f.config.guid === rowNode.groupingField);
    if (!isDimensionBased(field)) return {};
    const value = getKey(field, rowNode.groupingKey.toString());
    filters[field.meta?.name || ""] = {
      value:
        value === "" //This value, an empty string in this case, is a default grouping key for a null cell value that groupingValueGetter uses.
          ? null
          : value,
    };
  }

  if (rowNode.parent !== null) {
    const parentNode = api.getRowNode(rowNode.parent);
    if (parentNode !== null && parentNode.type === "group") {
      const parentKeys = getGroupKeys(parentNode, fields, api);
      Object.assign(filters, parentKeys);
    }
  }

  return filters;
}

function getKey(dimension: DimensionField | undefined, key: string): string {
  const displayValues = dimension?.config.displayValues || dimension?.meta.displayValues;
  if (displayValues === undefined) {
    return key;
  }

  const fieldKey = displayValues[0];
  if (fieldKey === undefined) {
    return key;
  }

  const item = dimension?.meta.dictionary.find((pair) => pair[fieldKey] === key);
  if (item === undefined || dimension?.meta.keyFieldName === undefined) {
    return key;
  }

  return (item[dimension?.meta.keyFieldName] as string) || key;
}
