import { configureStore } from "@reduxjs/toolkit";
import apiReportReducer from "./apiReportSlice";
import clientSettingsReducer from "./clientSettingsSlice";
import currentReportReducer from "./currentReportSlice";
import devPanelConfigurationReducer from "./devToolsSlice";
import drillDownConfigurationReducer from "./drilldownSlice";
import measureDatasetsReducer from "./measureDataSetsSlice";
import metaDataReducer from "./metaDataSlice";
import reportsReducer from "./reportsSlice";
import reportTemplatesReducer from "./reportTemplatesSlice";

export const store = configureStore({
  reducer: {
    currentReport: currentReportReducer,
    metaData: metaDataReducer,
    measureDatasets: measureDatasetsReducer,
    drillDownConfiguration: drillDownConfigurationReducer,
    devTools: devPanelConfigurationReducer,
    reportTemplates: reportTemplatesReducer,
    reports: reportsReducer,
    apiReport: apiReportReducer,
    clientSettings: clientSettingsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
