import { DimensionDictionary } from "../../../../../../shared/reporting/api/biClient.types";
import { unique } from "../../preview/utilities/unique";

export function getNewSelection(rowNumbers: number[], values: DimensionDictionary[], keyField: string) {
  return values
    .filter((row) => rowNumbers.includes(row["id"] as number))
    .map((row) => row[keyField])
    .filter((v): v is string => v !== undefined)
    .filter(unique);
}
