import { Stack, Typography } from "@mui/material";
import { UsedByReport } from "../../../../../api/biApi.types.ts";
import UsedByReportsGrid from "./UsedByReportsGrid.tsx";

interface Props {
  reports: UsedByReport[];
  minRows: number;
  maxRows: number;
}

const UsedByReports = ({ reports, minRows, maxRows }: Props) => {
  if (reports.length === 0) {
    return (
      <Typography variant="subtitle1" textAlign={"center"}>
        No usages found!
      </Typography>
    );
  }
  return (
    <Stack useFlexGap spacing={2} width="100%" alignItems="start">
      <Typography variant="subtitle2">{`${reports.length} report${reports.length === 1 ? "" : "s"}`}</Typography>
      <UsedByReportsGrid reports={reports} minRows={minRows} maxRows={maxRows} />
    </Stack>
  );
};

export default UsedByReports;
