import {
  GridAggregationFunction,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridGroupNode,
  GridRowGroupingModel,
  GridRowModel,
  GridSortItem,
  GridSortModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isAutoGeneratedRowNode, isPinnedRowNode } from "../../../../../shared/components/grid/gridHelper";
import { BiAggregation, ReportField, TabularDataCell } from "../../../../../shared/reporting/api/biClient.types";
import { AnyObject } from "../../../../../shared/types";
import { GroupTotal, TabularRow } from "../../../../api/biApi.types";
import useAutosizeColumns from "../../../../hooks/useAutosizeColumns";
import { isAggregation, isDimension, isMeasure } from "../../utils/fieldsHelper";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { Column } from "../utilities/gridStateHelper";
import { TabularGridCore } from "./TabularGridCore";
import { buildMeasureColumn } from "./utils/buildMeasureColumn";
import { buildDimensionColumn } from "./utils/dimension-column/buildDimensionColumn.tsx";
import { getAggregationModel } from "./utils/getAggregationModel";
import { getAggregationPosition } from "./utils/getAggregationPosition";

const custom: GridAggregationFunction<number, number> = {
  label: "",
  apply: () => 0,
  columnTypes: ["string"],
};

interface Props {
  rows: TabularRow[];
  fields: ReportField[];
  columns: Column[];
  groupTotals: GroupTotal[];
  grandTotals: AnyObject;
  groupingFields: { name: string; hideSummaries?: boolean }[];
  sortingModel: GridSortItem[];
  loading: boolean;
  groupTotalsLoading: boolean;
  size: { height?: number; width?: number };
  onGroupingChanged?: (model: GridRowGroupingModel) => void;
  onColumnsSortingChanged: (gridSortModel: GridSortModel) => void;
  onColumnsOrderChanged?: (names: string[]) => void;
  onDrillDown: (rowData: Record<string, TabularDataCell>, columnName: string) => void;
}

export default function TabularGrid({
  rows,
  fields,
  columns,
  groupTotals,
  grandTotals,
  groupingFields,
  sortingModel,
  loading,
  groupTotalsLoading,
  size,
  onGroupingChanged,
  onColumnsSortingChanged,
  onColumnsOrderChanged,
  onDrillDown,
}: Props) {
  const apiRef = useGridApiRef();
  const { settingsArea } = useFieldsStateContext();

  const fieldsRef = useRef(fields);
  const groupTotalsRef = useRef(groupTotals);
  const groupTotalsLoadingRef = useRef(groupTotalsLoading);
  const grandTotalsRef = useRef(grandTotals);
  const columnsRef = useRef(columns);

  fieldsRef.current = fields;
  groupTotalsRef.current = groupTotals;
  groupTotalsLoadingRef.current = groupTotalsLoading;
  grandTotalsRef.current = grandTotals;
  columnsRef.current = columns;

  const getFieldTitle = useCallback((field: string) => {
    return columnsRef.current.find((c) => c.name === field)?.title || "";
  }, []);

  const isColumnFailedToLoadRef = useRef((fieldGuid: string): boolean => {
    return columnsRef.current.find((c) => c.name === fieldGuid)?.failedToLoad || false;
  });

  const isGroupingField = useCallback(
    (fieldGuid: string): boolean => {
      return !!groupingFields.find((gf) => gf.name === fieldGuid);
    },
    [groupingFields]
  );

  const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    setGridColumns(
      fields
        .map((field) => {
          if (isDimension(field)) {
            return buildDimensionColumn(
              apiRef,
              {
                guid: field.config.guid,
                title: field.config.customLabel || field.meta.caption || "",
                fieldType: field.meta.type,
                groupingDateBy: field?.config.grouping,
              },
              getFieldTitle,
              settingsArea.settings.showGroupedColumns,
              isGroupingField
            );
          }
          const alignment: GridAlignment =
            isAggregation(field) && field.config.aggregation === BiAggregation.StringAgg ? "left" : "right";
          const fieldMeasure = {
            guid: field.config.guid,
            title: field.config.customLabel || field.meta.caption || "",
            hideAggregation: field.config.hideAggregation,
            align: alignment,
            headerAlign: alignment,
            failedToLoad: columns.find((c) => c.name === field.config.guid)?.failedToLoad || undefined,
            isFailedToLoad: isColumnFailedToLoadRef.current,
          };
          return buildMeasureColumn({
            apiRef,
            field: fieldMeasure,
            getFieldTitle,
            fields: fieldsRef,
            grandTotals: grandTotalsRef,
            groupTotals: groupTotalsRef,
            loading: groupTotalsLoadingRef,
          });
        })
        .filter((c): c is GridColDef => !!c) as []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields,
    apiRef,
    columns,
    groupTotalsLoading,
    groupTotals,
    settingsArea.settings.showGroupedColumns,
    isGroupingField,
  ]);

  const groupingModel = React.useMemo(() => groupingFields.map((gf) => gf.name), [groupingFields]);
  const aggregationModel = React.useMemo(() => getAggregationModel(gridColumns), [gridColumns]);
  const aggregationFunctions = React.useMemo(() => ({ custom }), []);

  const calculateAggregationModel = React.useCallback(
    (groupNode: GridGroupNode) => {
      return getAggregationPosition(groupNode, settingsArea.settings, groupingFields);
    },
    [groupingFields, settingsArea.settings]
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {},
    rowGroupingModel: groupingModel,
  });

  const handleCellClicked = React.useCallback(
    (params: GridCellParams<GridRowModel<TabularRow>>) => {
      const field = fields.find((f) => f.config.guid === params.field);
      if (!isMeasure(field)) {
        return;
      }
      if (isAutoGeneratedRowNode(params.rowNode) || isPinnedRowNode(params.rowNode)) {
        return;
      }
      onDrillDown(params.row.data, field.config.guid);
    },
    [fields, onDrillDown]
  );

  const onColumnOrderChange = React.useCallback(() => {
    const newOrder = apiRef.current.getAllColumns().map((c) => c.field);
    onColumnsOrderChanged?.call(null, newOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onColumnsOrderChanged]);

  const { autosizeOptions } = useAutosizeColumns(apiRef, [columns, settingsArea.settings.showGroupedColumns]);

  const rowGroupingColumnMode = settingsArea.settings.showGroupedColumns ? "multiple" : "single";

  return (
    <TabularGridCore
      key={rowGroupingColumnMode}
      apiRef={apiRef}
      size={size}
      initialState={initialState}
      rows={rows}
      columns={gridColumns}
      sortModel={sortingModel}
      rowGroupingModel={groupingModel}
      aggregationFunctions={aggregationFunctions}
      aggregationModel={aggregationModel}
      loading={loading}
      autosizeOptions={autosizeOptions}
      autosizeOnMount
      getAggregationPosition={calculateAggregationModel}
      onColumnOrderChange={onColumnOrderChange}
      onRowGroupingModelChange={onGroupingChanged}
      onSortModelChange={onColumnsSortingChanged}
      onCellClick={handleCellClicked}
      rowGroupingColumnMode={rowGroupingColumnMode}
      showGroupedColumns={settingsArea.settings.showGroupedColumns}
    />
  );
}
