import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps, DateValidationError } from "@mui/x-date-pickers-pro";
import { FieldChangeHandler } from "@mui/x-date-pickers/internals/hooks/useField/useField.types";
import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";
import { CustomDatePickerFieldProps, ErrorHandlerType } from "./bcDatePickerTypes";
import { hasTextFieldAndRequired, parseDate, validateDateRange } from "./helper/bcDatePickerHelper";

interface BcDatePickerProps extends DatePickerProps<Date> {
  required?: boolean;
}

const BcDatePicker = (props: BcDatePickerProps) => {
  return (
    <DatePicker<Date>
      {...props}
      slots={{ field: CustomDatePickerField, openPickerIcon: CalendarTodayRoundedIcon, ...props.slots }}
      slotProps={{
        textField: {
          required: props.required,
        },
        popper: {
          sx: {
            zIndex: 1400,
          },
        },
      }}
    />
  );
};

const CustomDatePickerField = (props: CustomDatePickerFieldProps) => {
  const {
    label,
    onError,
    value,
    onChange,
    InputProps: { ref, endAdornment } = {},
    inputProps,
    minDate,
    maxDate,
    slotProps,
  } = props;

  const [innerValue, setInnerValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const handleEmptyValue = (
    slotProps: unknown,
    onError: ErrorHandlerType,
    onChange: FieldChangeHandler<Date | null, DateValidationError> | undefined
  ) => {
    if (hasTextFieldAndRequired(slotProps) && slotProps.textField.required === true) {
      onError?.("invalidDate", null);
      setError("Field is required");
    } else {
      onChange?.(null, { validationError: null });
    }
  };

  const handleNonEmptyValue = (
    dateStr: string,
    onError: ErrorHandlerType,
    onChange: FieldChangeHandler<Date | null, DateValidationError> | undefined
  ) => {
    const date = parseDate(dateStr);

    if (!date) {
      onError?.("invalidDate", null);
      setError("Invalid date");
      return;
    }

    onChange?.(date, { validationError: null });
  };

  useEffect(() => {
    setInnerValue(value && isValid(value) ? format(value, "MM/dd/yyyy") : "");
    if (!value) {
      setError(null);
    } else {
      const validationError = validateDateRange(value, minDate, maxDate);
      setError(validationError);
    }
  }, [value, minDate, maxDate]);

  return (
    <TextField
      fullWidth
      ref={ref}
      label={label}
      slotProps={{
        htmlInput: { ...inputProps },
        input: { endAdornment: endAdornment },
      }}
      value={innerValue}
      onBlur={(e) => {
        const { value } = e.target;
        if (value === "") {
          handleEmptyValue(slotProps, onError, onChange);
        } else {
          handleNonEmptyValue(value, onError, onChange);
        }
      }}
      onChange={(e) => setInnerValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          const target = e.target as HTMLInputElement;
          e.preventDefault();
          target.blur();
        }
      }}
      error={!!error}
      placeholder="MM/DD/YYYY"
    />
  );
};

export default BcDatePicker;
