import { MeasureUnitTable } from "../../../../../shared/reporting/api/biClient.types";
import { AccountingBookSettings } from "../../../../api/biApi.types";

export type Book = {
  key: string;
  code: string | null;
  title: string;
  ledger: MeasureUnitTable;
};

export const getAccountingBooks = (
  hasGl: boolean,
  hasMemo: boolean,
  accountingBookSettings: AccountingBookSettings | undefined
): Book[] => {
  const accountingBooks = [];
  if (hasGl) {
    accountingBookSettings?.books.forEach((book) => {
      accountingBooks.push({ key: book.code, code: book.code, title: book.name, ledger: MeasureUnitTable.Gl });
    });
  }
  if (hasMemo) {
    accountingBooks.push({ key: "memo", code: null, title: "Memo Ledger Accounts", ledger: MeasureUnitTable.Memo });
  }
  return accountingBooks;
};
