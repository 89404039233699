import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Button, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import TooltipWrapper from "../../../../shared/components/TooltipWrapper";
import { CustomLabelData } from "./CustomLabelData";

interface EditCustomLabelProps extends CustomLabelData {
  onApply: (customLabel: string | undefined) => void;
  onCancel: () => void;
}
export default function EditCustomLabel({ customLabelValue, originalName, onApply, onCancel }: EditCustomLabelProps) {
  const [customLabel, setCustomLabel] = useState(() => (customLabelValue ? customLabelValue : originalName));

  const handleApply = useCallback(() => {
    if (customLabel) {
      onApply(getCustomLabel(customLabel, originalName));
    }
  }, [customLabel, onApply, originalName]);

  return (
    <>
      <TextField
        placeholder={originalName}
        sx={(theme) => ({
          flex: 1,
          color: theme.palette.text.primary,
          borderRadius: "4px",
          border: `2px solid ${theme.palette.primary.main}`,
          fieldset: {
            border: "none",
          },
          "& .MuiOutlinedInput-root": {
            maxHeight: "30px",
          },
        })}
        slotProps={{
          input: {
            autoFocus: true,
            value: customLabel,
            onChange: (evt) => setCustomLabel(evt.target.value),
            onKeyDown: (evt) => {
              if (evt.key === "Enter") {
                handleApply();
              }
            },
            endAdornment: (
              <TooltipWrapper title="Reset to Default Name" placement="bottom">
                <RotateLeftIcon
                  onClick={() => setCustomLabel(originalName)}
                  sx={(theme) => ({ cursor: "pointer", color: theme.palette.secondary.light })}
                />
              </TooltipWrapper>
            ),
          },
        }}
      />
      <Button variant="outlined" color="secondary" sx={(theme) => ({ maxHeight: theme.spacing(4) })} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="contained"
        sx={(theme) => ({ maxHeight: theme.spacing(4) })}
        disabled={!customLabel}
        onClick={handleApply}
      >
        Apply
      </Button>
    </>
  );
}

function getCustomLabel(customLabel: string | undefined, originalName: string) {
  return customLabel === originalName ? "" : customLabel;
}
