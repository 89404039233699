import { Box, Grid2 } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { enUS } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router";
import { getStatusCodeFromError } from "../shared/api/axiosHelper";
import { updateClientCode } from "../shared/api/clientApiContext";
import { ClientInfoBase } from "../shared/api/clientTypes";
import { ssoApi } from "../shared/api/sso";
import { useAuth } from "../shared/auth/hooks";
import { redirectToLoginSignin, redirectToUnauthorized } from "../shared/auth/internal/redirects";
import AuthenticationFailed from "../shared/components/AuthenticationFailed";
import ErrorBoundary from "../shared/components/ErrorBoundary";
import Intercom from "../shared/components/Intercom";
import FullScreenLoader from "../shared/components/fullScreenLoader/FullScreenLoader";
import { ClientPermissionsContextProvider } from "../shared/contexts/ClientPermissionsContext";
import sharedBiClient from "../shared/reporting/api/biClient";
import { ClientInfo } from "../shared/reporting/api/biClient.types";
import { getOrganization } from "../shared/utilities/clientHelper";
import biClient from "./api/biApi";
import { loadUser } from "./api/identityApi";
import { User } from "./api/identityApi.types";
import ImportantDataLoadingFailed from "./components/common/ImportantDataLoadingFailed";
import { ClientContextProvider } from "./contexts/ClientContext";
import { UserContextProvider } from "./contexts/UserContext";
import { useLocalization } from "./hooks/useLocalization";
import { clientSettingsActions, selectClientSettings } from "./store/clientSettingsSlice";
import { metaDataActions } from "./store/metaDataSlice";
import { store } from "./store/store";

export default function App() {
  const navigate = useNavigate();
  const { client } = useParams();
  const locale = useLocalization();
  const [clientCode, setClientCode] = useState<string | undefined>(client);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loadingUserError, setLoadingUserError] = useState(false);
  const [userHasNoPermissions, setUserHasNoPermissions] = useState(false);
  const [clientInfo, setClientInfo] = useState<ClientInfo>();
  const [clients, setClients] = useState<ClientInfoBase[]>();

  if (clientCode !== undefined) {
    updateClientCode(clientCode);
  }

  useEffect(() => {
    if (clientCode !== undefined) {
      store.dispatch(metaDataActions.update({ loaded: false }));
    }
  }, [clientCode]);

  const setCurrentClientCode = useCallback(
    (newClientCode: string) => {
      setClientCode(newClientCode);
      navigate(`/${newClientCode}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.permissions]
  );

  useEffect(() => {
    if (user === undefined) return;
    let clientCode = user.permissions.find((p) => p.clientCode === client)?.clientCode;
    if (!clientCode) {
      const firstClient = user.permissions[0];
      if (firstClient) {
        clientCode = firstClient.clientCode;
        setCurrentClientCode(firstClient.clientCode);
      } else {
        redirectToUnauthorized();
      }
      return;
    }
    setClientCode(clientCode);
    updateClientCode(clientCode || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, user?.permissions, clients]);

  useEffect(() => {
    if (!clientCode) {
      return;
    }
    let isMounted = true;
    const fetchClients = async () => {
      const response = await sharedBiClient.getClients();
      if (response.success && response.data && isMounted) {
        const org = getOrganization(clientCode, response.data.clients);
        const info = response.data.clients.find((c) => c.clientCode === clientCode);
        if (info) {
          setClientInfo({ ...info, organization: org || "" });
        }
      }
      setClients(response?.data?.clients || []);
    };
    fetchClients();

    return () => {
      isMounted = false;
    };
  }, [clientCode]);

  const onAuthenticated = useCallback(() => {
    if (user !== undefined) {
      return;
    }
    const load = async () => {
      try {
        const { data } = await loadUser();
        setUser(data);
      } catch (error) {
        if (getStatusCodeFromError(error) === 401) {
          await ssoApi.logout();
          setUserHasNoPermissions(true);
        } else {
          setLoadingUserError(true);
        }
      }
    };
    return load();
  }, [user, setLoadingUserError]);

  const isAuthenticated = useAuth(onAuthenticated, redirectToLoginSignin);

  if (!isAuthenticated) {
    return <FullScreenLoader title={locale.redirect.title} subtitle={locale.redirect.subtitle} />;
  }

  if (userHasNoPermissions) {
    return <AuthenticationFailed />;
  }

  if (loadingUserError) {
    return (
      <ImportantDataLoadingFailed
        title={locale.common.report_data_loading_failed_title}
        disclaimer={locale.common.report_data_loading_failed_text}
      />
    );
  }

  if (user === undefined || clientCode === undefined || clients === undefined) {
    return <FullScreenLoader title={locale.identity.title} subtitle={locale.identity.subtitle} />;
  }

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
        <Provider store={store}>
          <UserContextProvider value={user}>
            <ClientSettingsComponent clientCode={clientCode} user={user} />
            <ClientContextProvider
              client={{
                clientCode,
                setClientCode: setCurrentClientCode,
                clientInfo: clientInfo as ClientInfo,
                clients: clients,
              }}
            >
              <ClientPermissionsContextProvider
                permissions={user.permissions.find((p) => p.clientCode === clientCode)?.permissions || []}
                permissionSets={user.permissions}
              >
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <Grid2 container flex={1} maxHeight={"100%"} width={"100%"}>
                    <Outlet />
                  </Grid2>
                </Box>
              </ClientPermissionsContextProvider>
            </ClientContextProvider>
          </UserContextProvider>
        </Provider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

function ClientSettingsComponent({ clientCode, user }: { clientCode: string; user: User }) {
  const dispatch = useDispatch();
  const clientSettings = useSelector(selectClientSettings);

  useEffect(() => {
    if (!clientCode) {
      return;
    }
    let isMounted = true;
    const fetchClientSettings = async () => {
      const response = await biClient.getClientSettings();
      if (response.success && response.data && isMounted) {
        dispatch(clientSettingsActions.setSettings(response.data));
      }
    };

    if (!clientSettings) {
      fetchClientSettings();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode, clientSettings]);

  return (
    <>
      {clientSettings?.intercomAuthentication !== undefined && (
        <Intercom fullName={user.name} email={user.email} settings={clientSettings?.intercomAuthentication} />
      )}
    </>
  );
}
