import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ReportAccessType } from "../../shared/reporting/api/biClient.types";
import { useEnhancedBiApiClientProvider } from "../contexts/ApiClientProviderContext";
import { useClientContext } from "../contexts/ClientContext";
import { currentReportActions } from "../store/currentReportSlice";
import { getNewReport } from "../store/currentReportSlice.helper";
import { AppDispatch } from "../store/store";
import { setReport } from "../store/thunks";
import { getExistingReportWithAuth, manageReportSwitching } from "../utilities/defineReportDataHelper";

const useDefineReportData = () => {
  const { id } = useParams();
  const { clientCode } = useClientContext();
  const { getReport, getReportAuthorizationInfo } = useEnhancedBiApiClientProvider();
  const dispatch: AppDispatch = useDispatch();
  const [showReportNotFound, setShowReportNotFound] = useState(false);
  const [showSwitchingReport, setShowSwitchingReport] = useState(false);
  const cachedReportId = useRef<string>();

  useEffect(() => {
    const isSwitchingReport = !!cachedReportId.current && cachedReportId.current !== id;
    cachedReportId.current = id;

    if (id === undefined) {
      return;
    }

    const execute = async () => {
      if (id === "new") {
        dispatch(setReport(getNewReport(clientCode)));
        dispatch(
          currentReportActions.setAuthorizationInfo({ access: ReportAccessType.Owner, own: true, private: true })
        );
      } else {
        await getExistingReportWithAuth(
          getReport.bind(null, id, "edit"),
          getReportAuthorizationInfo.bind(null, id),
          () => setShowReportNotFound(true),
          (report) => dispatch(setReport(report)),
          (authInfo) => dispatch(currentReportActions.setAuthorizationInfo(authInfo))
        );
      }
    };
    manageReportSwitching(isSwitchingReport, setShowSwitchingReport, execute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, clientCode, getReport, getReportAuthorizationInfo]);

  return { showReportNotFound, showSwitchingReport };
};

export default useDefineReportData;
