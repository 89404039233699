import { LoadingButton } from "@mui/lab";
import { Button, Dialog, Divider, Grid2, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import LoaderBox from "../../../../../shared/components/LoaderBox";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { FormatType, MeasureDescriptor } from "../../../../../shared/reporting/api/biClient.types";
import { formatString } from "../../../../../shared/utilities/stringFormatter";
import biClient from "../../../../api/biApi";
import { useLocalization } from "../../../../hooks/useLocalization";
import { metaDataActions } from "../../../../store/metaDataSlice";
import { AlertItem } from "../../../common/AlertItem";
import FormulaContainer from "./FormulaContainer";
import { useCustomMeasure } from "./hooks/useCustomMeasure";
import MainInfoComponent from "./mainInfo/MainInfoComponent";
import Properties from "./properties/Properties";
import SettingsComponent from "./settings/SettingsComponent";
import {
  isFormulaNumericOnly,
  isStandAloneFunctionWithNumbers,
  isValidFormulaStructure,
} from "./utilities/formulaValid";
import { convertToMeasure } from "./utilities/measureConverter";

interface Props {
  measure?: MeasureDescriptor;
  defaultGroup?: string;
  onClose?: () => void;
}

export const CustomMeasureDialog = ({ measure, defaultGroup, onClose }: Props) => {
  const { custom_measure: locale } = useLocalization();
  const state = useCustomMeasure({
    measure,
    defaultGroup,
    measureFormat: measure?.defaultFormat?.formatType === FormatType.Numeric ? measure.defaultFormat : undefined,
  });
  const dispatch = useDispatch();
  const { sendNotification } = useNotificationContext();

  const [nameError, setNameError] = useState<string>();
  const [formulaValid, setFormulaValid] = useState<boolean>();
  const [formulaStructureError, setFormulaStructureError] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [savingError, setSavingError] = useState<string>();
  const [deletingError, setDeletingError] = useState<string>();
  const [isNewMeasure] = useState(measure === undefined);

  useEffect(() => {
    if (!state.nodesCounter) {
      setFormulaStructureError(undefined);
      return;
    }
    let message: string | undefined;

    if (isStandAloneFunctionWithNumbers(state.nodesCounter)) {
      message = locale.function_with_numbers_error;
    } else if (!isValidFormulaStructure(state.nodesCounter)) {
      message = locale.formula_structure_error;
    } else if (isFormulaNumericOnly(state.nodesCounter)) {
      message = `${locale.numeric_only_formula_error} ${locale.formula_structure_error}`;
    }
    setFormulaStructureError(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.nodesCounter]);

  const saveMeasure = useCallback(() => {
    const measureDescription = convertToMeasure(state, measure);
    setSaving(true);

    if (isNewMeasure) {
      biClient
        .addCustomMeasure(measureDescription)
        .then((result) => {
          if (result.data !== undefined) {
            dispatch(metaDataActions.addMeasure(result.data));
          }
          sendNotification(formatString(locale.measure_added, measureDescription.caption));
          onClose?.call(null);
        })
        .catch(() => setSavingError(locale.saving_error))
        .finally(() => setSaving(false));
    } else {
      biClient
        .updateCustomMeasure(measureDescription)
        .then((result) => {
          if (result.data !== undefined) {
            dispatch(metaDataActions.updateMeasure(result.data));
          }
          sendNotification(formatString(locale.measure_updated, measureDescription.caption));
          onClose?.call(null);
        })
        .catch(() => setSavingError(locale.saving_error))
        .finally(() => setSaving(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMeasure, state, locale.saving_error, onClose]);

  const onDeleteMeasure = useCallback(() => {
    if (measure === undefined) return;
    setDeleting(true);
    biClient
      .deleteCustomMeasure(measure.id ?? measure.name)
      .then(() => {
        dispatch(metaDataActions.deleteMeasure(measure));
        sendNotification(formatString(locale.measure_deleted, measure.caption));
        onClose?.call(null);
      })
      .catch(() => {
        setDeletingError(locale.delete_error);
      })
      .finally(() => setDeleting(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure?.name, locale.delete_error, onClose]);

  const formValid = useMemo(() => {
    return state.formulaValid && formulaValid === true && nameError === undefined && !formulaStructureError;
  }, [state.formulaValid, formulaValid, nameError, formulaStructureError]);

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: "100%",
          height: "100%",
          maxWidth: "1070px",
          maxHeight: "700px",
          overflow: "hidden",
        },
      }}
    >
      <Grid2 container alignItems="center" sx={{ py: 2, px: 3, width: "100%" }}>
        <Typography variant="h6">{isNewMeasure ? "New Measure" : "Edit Measure"}</Typography>
        <HorizontalFill />
        <CloseIconButton onClick={onClose} />
      </Grid2>
      <Divider flexItem />
      {state.dataLoadingError || state.datasetError ? (
        <DataLoadingFailed text={state.dataLoadingError || state.datasetError} sx={{ bgcolor: "white" }} />
      ) : (
        <LoaderBox loading={state.loading}>
          <Grid2 container sx={{ flex: 1, flexWrap: "nowrap", width: "100%" }}>
            <Grid2 size={4.5} sx={{ display: "flex", flexDirection: "column", py: 2, pl: 3, pr: 2.5, gap: 3 }}>
              <MainInfoComponent state={state} measure={measure} onError={setNameError} />
              <SettingsComponent state={state} />
            </Grid2>
            <Divider orientation="vertical" flexItem />
            <Grid2 size={7.8} display={"flex"} flexDirection={"column"}>
              {savingError && (
                <Grid2 sx={{ pt: 2, pb: 1.5, pl: 2.5, pr: 3, width: "100%" }}>
                  <AlertItem error={savingError} />
                </Grid2>
              )}
              {deletingError && (
                <Grid2 sx={{ pt: 2, pb: 1.5, pl: 2.5, pr: 3, width: "100%" }}>
                  <AlertItem error={deletingError} />
                </Grid2>
              )}
              <FormulaContainer
                state={state}
                measure={measure}
                editable
                onValidated={setFormulaValid}
                sx={{ py: 2, pl: 2.5, pr: 3 }}
                formulaStructureError={formulaStructureError}
              />
              <Divider flexItem />
              <ScrollableFlexContainer>
                <Properties state={state} editable={true} />
              </ScrollableFlexContainer>
            </Grid2>
          </Grid2>
        </LoaderBox>
      )}
      <Divider flexItem />
      <Grid2 container sx={{ justifyContent: "end", alignItems: "center", py: 2, px: 3, gap: 1, width: "100%" }}>
        {measure && (
          <LoadingButton
            variant="outlined"
            color="error"
            disabled={saving || state.loading}
            loading={deleting}
            onClick={onDeleteMeasure}
            sx={{ ml: 0, mr: "auto" }}
          >
            Delete
          </LoadingButton>
        )}
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" disabled={!formValid || deleting} onClick={saveMeasure} loading={saving}>
          Save
        </LoadingButton>
      </Grid2>
    </Dialog>
  );
};

export default CustomMeasureDialog;
