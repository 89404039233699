import { Grid2, Typography } from "@mui/material";
import { ValueAxis } from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import ShowLabelItem from "./ShowLabelItem";
import Title from "./Title";

interface Props {
  area: SettingsArea;
  axis: ValueAxis;
}

export default function ValueAxisItem({ area, axis }: Props) {
  return (
    <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
      <Typography variant="subtitle2">{axis.name}</Typography>
      <ShowLabelItem area={area} axis={axis} />
      <Title area={area} axis={axis} />
    </Grid2>
  );
}
