import { JSX, useCallback } from "react";
import {
  BiAggregation,
  DimensionField,
  FieldConfiguration,
  Grouping,
} from "../../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../../shared/types";
import CustomLabel from "../../../common/customLabel/CustomLabel";
import FieldOptionDeleteButton from "../../../common/FieldOptionDeleteButton";
import FieldOptionsDivider from "../../../common/FieldOptionsDivider";
import DimensionAggregation from "../../common/fields/dimension-aggregation/DimensionAggregation";
import { getDefaultFormatByAggregation } from "../../common/fields/utils/getNewDefaultFormatFormat";
import SuppressBlankValues from "../../common/SuppressBlankValues";
import { isAggregation } from "../../utils/fieldsHelper";
import { isNumericField } from "../../utils/isNumericField";
import { referenceDateFields } from "../utilities/referenceDateFields";
import DateGroupingComponent from "./DateGroupingComponent";

export interface Props {
  field: DimensionField;
  AggregationOptions: JSX.Element;
  cancel: () => void;
  onRemove: () => void;
  onUpdateConfiguration: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
  onUpdateCustomLabel: (name: string | undefined) => void;
}

export default function DimensionFieldOptions({
  field,
  AggregationOptions,
  cancel,
  onRemove,
  onUpdateConfiguration,
  onUpdateCustomLabel,
}: Props) {
  const isReferencedDateField = referenceDateFields.includes(field.meta.name);
  const isNumeric = isNumericField(field);
  const aggregation = isAggregation(field);
  const showGroupings = !aggregation && isReferencedDateField;

  const handleRemove = () => {
    onRemove();
    cancel();
  };

  const updateGrouping = useCallback(
    (newGrouping: Grouping | undefined) => {
      if (field.config.grouping !== newGrouping) {
        onUpdateConfiguration({ grouping: newGrouping }, false);
      }
    },
    [field.config.grouping, onUpdateConfiguration]
  );

  const saveChanges = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      onUpdateConfiguration(changes, false);
    },
    [onUpdateConfiguration]
  );

  const onUpdateAggregation = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      if (changes.aggregation === BiAggregation.None) {
        changes.useCustomConditions = false;
        changes.linkedConditions = undefined;
        changes.customConditions = undefined;
      }
      if (changes.aggregation !== undefined && isAggregationChanged(field, changes.aggregation)) {
        changes.format = getDefaultFormatByAggregation(field, changes.aggregation);
      }

      onUpdateConfiguration(changes, false);
    },
    [onUpdateConfiguration, field]
  );

  return (
    <>
      <CustomLabel
        customLabelValue={field.config.customLabel}
        originalName={field.meta.caption}
        updateCustomLabel={onUpdateCustomLabel}
      />
      <FieldOptionsDivider />
      <DimensionAggregation field={field} onChange={onUpdateAggregation} />
      {showGroupings && <DateGroupingComponent field={field} updateGrouping={updateGrouping} />}
      {aggregation && AggregationOptions}
      {(aggregation || !isReferencedDateField) && (
        <SuppressBlankValues
          field={field}
          onChanged={saveChanges}
          showExcludeZeroValues={field.config.hideBlankValues === true && isNumeric}
        />
      )}
      <FieldOptionsDivider />
      <FieldOptionDeleteButton onRemove={handleRemove} />
    </>
  );
}

const isAggregationChanged = (currentField: DimensionField, newAggregation: Maybe<BiAggregation>) => {
  return currentField.config.aggregation !== newAggregation;
};
