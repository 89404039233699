import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectResponseJson } from "../../../../../store/apiReportSlice";
import CodeContainer from "./CodeContainer";
import CopyButton from "./CopyButton";

interface Props {
  isLoading: boolean;
}
const ResponseCode = ({ isLoading }: Props) => {
  const responseJson = useSelector(selectResponseJson);

  return (
    <CodeContainer
      Header={
        <>
          <Typography variant="h6">Response</Typography>
          <CopyButton code={responseJson} />
        </>
      }
      code={responseJson}
      isLoading={isLoading}
    />
  );
};

export default ResponseCode;
