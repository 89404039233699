import { ApiResponse } from "../../shared/api/types";
import {
  ChangeReportGroupRequest,
  DeleteReportsRequest,
  RenameReportRequest,
  Report,
  ReportAuthorizationInfo,
  ReportInfo,
} from "../../shared/reporting/api/biClient.types";
import { BiApiClientBase, useBiApiClientProvider } from "../../shared/reporting/contexts/BiApiClientProviderContext";
import { CancellationToken } from "../api/biApi";
import {
  ApiReportResponse,
  BuildTabularReportRequest,
  RunApiReportRequest,
  RunTabularReportRequest,
  TabularDataResponse,
  TabularGroupTotalsResponse,
} from "../api/biApi.types";

export interface BiApiClient extends BiApiClientBase {
  updateReport: (report: Report) => Promise<ApiResponse<Report>>;
  addReport: (report: Report) => Promise<ApiResponse<Report>>;
  getReport: (reportId: string, mode: "run" | "edit") => Promise<ApiResponse<Report>>;
  runReport: <
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    TBody extends RunTabularReportRequest | RunApiReportRequest,
    TResponse extends TabularDataResponse | ApiReportResponse,
  >(
    body: TBody,
    done: (data: ApiResponse<TResponse>) => void,
    error: (reason: string | undefined) => void
  ) => CancellationToken;
  buildReport: <TBody extends BuildTabularReportRequest, TResponse extends TabularDataResponse | ApiReportResponse>(
    body: TBody,
    done: (data: ApiResponse<TResponse>) => void,
    error: (reason: string | undefined) => void
  ) => CancellationToken;
  calculateTabularGroupTotalsData: (
    body: BuildTabularReportRequest,
    done: (data: ApiResponse<TabularGroupTotalsResponse>) => void,
    error: (reason: string | undefined) => void
  ) => CancellationToken;
  renameReport: (request: RenameReportRequest) => Promise<ApiResponse<Report>>;
  changeReportGroup: (request: ChangeReportGroupRequest) => Promise<ApiResponse<Report>>;
  deleteReports: (request: DeleteReportsRequest) => Promise<ApiResponse<boolean>>;
  getReportAuthorizationInfo: (reportId: string) => Promise<ApiResponse<ReportAuthorizationInfo>>;
  getReports: () => Promise<ApiResponse<ReportInfo[]>>;
  getEditReportUrl: (reportId: string) => string;
  getViewReportUrl: (reportId: string) => string;
  getReportsUrl: (clientCode: string) => string;
}

export const useEnhancedBiApiClientProvider = () => {
  return useBiApiClientProvider<BiApiClient>();
};
