import { ExtendedAxiosError } from "../../shared/api/axiosHelper";
import { setUpAxios } from "../../shared/api/axiosHelperAdvanced";
import { getClientCode } from "../../shared/api/clientApiContext";
import { Pages } from "./Pages";

export default function setUpAxiosForBi() {
  setUpAxios([isAccessForbidden]);
}

function isAccessForbidden(axiosError: ExtendedAxiosError): Promise<never> | undefined {
  if (axiosError.response?.status === 403) {
    const clientCode = getClientCode();
    if (clientCode) {
      redirectToForbiddenPage(clientCode);
      return Promise.reject(new Error("Access forbidden"));
    }
  }
  return undefined;
}

export function redirectToForbiddenPage(clientCode: string) {
  window.location.href = `/${clientCode}/${Pages.Forbidden}`;
}
