import {
  DataGridPremiumProps,
  GridCellParams,
  GridGroupingColDefOverride,
  GridRowModel,
  isAutogeneratedRow,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import DataGridGroupHeader from "../../../../../../shared/components/grid/DataGridGroupHeader";
import { DimensionDictionary } from "../../../../../../shared/reporting/api/biClient.types";
import { selectAccountBookSettings } from "../../../../../store/clientSettingsSlice";
import { ConditionField } from "../../../Types";
import TextFilterGrid from "./TextFilter.Grid";

interface Props extends DataGridPremiumProps {
  field: ConditionField;
  values: DimensionDictionary[];
  selectedValues: unknown[];
  readonly?: boolean;
  onChanged: (selectedValues: string[]) => void;
}
export default function TextFilterMultiBook(props: Props) {
  const apiRef = useGridApiRef();
  const accountingBookSettings = useSelector(selectAccountBookSettings);
  const booksByCode = useMemo(() => {
    return (
      accountingBookSettings?.books.reduce(
        (acc, book) => {
          acc[book["code"]] = book["name"];
          return acc;
        },
        {} as Record<string, string>
      ) ?? {}
    );
  }, [accountingBookSettings?.books]);

  const columns = useMemo(() => {
    const accountBookColumn = props.columns.find((c) => c.field === "Accounting Book");
    if (accountBookColumn) {
      accountBookColumn.groupingValueGetter = (value) => {
        if (value) {
          return booksByCode[value as string] ?? value;
        }
        return value ?? "Memo";
      };
    }
    return props.columns;
  }, [booksByCode, props.columns]);

  const groupColumnDef = useMemo((): GridGroupingColDefOverride | undefined => {
    return {
      hideDescendantCount: true,
      headerName: "No.",
      sortable: true,
      aggregable: false,
      hideable: false,
      pinnable: false,
      filterable: false,
      flex: 1,
      leafField: "No.",
      renderHeader: (params) => <DataGridGroupHeader params={params} />,
      colSpan: (_: GridCellParams, row: GridRowModel) => {
        if (isAutogeneratedRow(row)) {
          return 2;
        }
        return 1;
      },
      groupingValueGetter: (params) => {
        return params ?? "Memo";
      },
    };
  }, []);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: { columnVisibilityModel: { ["No."]: false } },
      sorting: { sortModel: [{ field: props.field.meta.keyFieldName, sort: "asc" }] },
      rowGrouping: { model: ["Accounting Book"] },
    },
  });

  return (
    <TextFilterGrid
      apiRef={apiRef}
      {...props}
      columns={columns}
      initialState={initialState}
      groupingColDef={groupColumnDef}
    />
  );
}
