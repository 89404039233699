import { Box } from "@mui/material";
import React from "react";
import {
  AreaItemType,
  DimensionField,
  FieldConfiguration,
  ItemDataType,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import DisplayFieldsComponent from "../../../common/fields/format/DisplayFieldsComponent";
import ShowLogoIconComponent from "../../../common/fields/format/ShowLogoIconComponent";
import TextField from "../../../common/fields/format/TextField";
import { isDimension } from "../../../utils/fieldsHelper";

const getTextFields = (fields: ReportField[]): DimensionField[] => {
  return fields.filter(isDimension).filter((v) => v.meta.type === ItemDataType.General);
};

interface Props {
  fields: DimensionField[];
  onUpdateConfig: (field: DimensionField, changes: Partial<FieldConfiguration>) => void;
}

export default function TextFieldContainer({ fields, onUpdateConfig }: Props) {
  const textFields = React.useMemo(() => getTextFields(fields), [fields]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      {textFields.map((field) => (
        <TextField key={field.meta.name} field={field}>
          {field.areaItemType === AreaItemType.ROWS && (
            <ShowLogoIconComponent field={field} onUpdateConfig={onUpdateConfig} />
          )}
          <DisplayFieldsComponent field={field} onUpdateConfig={onUpdateConfig} />
        </TextField>
      ))}
    </Box>
  );
}
