import { Popover, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, useCallback, useLayoutEffect, useMemo, useState } from "react";

interface Props {
  open: boolean;
  anchorEl: HTMLElement | undefined;
  sx?: SxProps<Theme>;
  onClose: () => void;
}

export default function FieldPopover({ open, anchorEl, sx, children, onClose }: PropsWithChildren<Props>) {
  const [paperRef, setPaperRef] = useState<HTMLDivElement | null>(null);
  const [paperBounds, setPaperBounds] = useState<DOMRect>();
  const [transitionEnd, setTransitionEnd] = useState(false);

  const anchorBounds = useMemo(() => anchorEl?.getBoundingClientRect(), [anchorEl]);

  const arrowTop = useMemo(() => {
    if (anchorBounds === undefined || paperBounds === undefined) return undefined;
    const top = anchorBounds.top - paperBounds.top;
    if (15 > top) {
      return 15;
    }
    if (anchorBounds.bottom > paperBounds.bottom - 9) return undefined;
    return top + 9;
  }, [anchorBounds, paperBounds]);

  useLayoutEffect(() => {
    if (paperRef === null) return;
    const observer = new MutationObserver(() => {
      setPaperBounds(paperRef.getBoundingClientRect());
    });

    observer.observe(paperRef, { attributeFilter: ["style"] });
    return () => observer.disconnect();
  }, [paperRef]);

  const clearTransitionEnd = useCallback(() => setTransitionEnd(true), []);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onTransitionEnd={clearTransitionEnd}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          ref: setPaperRef,
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
            minWidth: "250px",
            mt: -1,
            ml: 1.5,
            py: 0.5,
            "&:before": {
              content: '""',
              display: "block",
              opacity: transitionEnd && arrowTop !== undefined ? 1 : 0,
              transition: "opacity 0.4s ",
              position: "absolute",
              top: arrowTop,
              left: 0,
              width: 12,
              height: 12,
              bgcolor: "background.paper",
              transform: "translateX(-50%) rotate(45deg)",
              zIndex: 0,
            },
            ...sx,
          },
        },
      }}
      sx={{
        minHeight: "45px",
      }}
    >
      {children}
    </Popover>
  );
}
