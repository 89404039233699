import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { logError } from "../../../shared/logging";
import sharedBiClient from "../../../shared/reporting/api/biClient";
import { ReportTemplate } from "../../../shared/reporting/api/biClient.types";
import { useClientContext } from "../../contexts/ClientContext";
import { useLocalization } from "../../hooks/useLocalization";
import { AppDispatch } from "../../store/store";
import { setReport } from "../../store/thunks";
import useReportTypeChange from "../builder/common/hooks/useReportTypeChange";
import { getEditTemplateUrl } from "../builder/common/utilities/editReportUrl";
import Visualization from "../common/visualization/Visualization";

interface Props {
  template: ReportTemplate;
}

const TemplateTypeSelector = ({ template }: Props) => {
  const { clientInfo } = useClientContext();
  const { report_templates: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { onReportTypeChanged } = useReportTypeChange({ report: template });
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const update = useCallback(async (template: ReportTemplate): Promise<ReportTemplate | undefined> => {
    try {
      const response = await sharedBiClient.updateReportTemplate({ template });
      if (response.success) {
        sendNotification(locale.saved);
        dispatch(setReport(response.data));

        return response.data;
      }
      sendNotificationError(locale.saving_error);

      return undefined;
    } catch (error) {
      sendNotificationError(locale.saving_error);
      logError(error, "[TemplateTypeSelector] update");

      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveChangesSaved = useCallback(
    (template: ReportTemplate) => {
      navigate(
        getEditTemplateUrl(template.dataSource || clientInfo.clientCode, template.reportId, template.organization)
      );
    },
    [clientInfo.clientCode, navigate]
  );

  return (
    <Visualization
      report={template}
      onSelect={onReportTypeChanged}
      update={update}
      onSaveChangesSaved={handleSaveChangesSaved}
    >
      <Visualization.Label />
      <Visualization.ReportTypesContainer />
      <Visualization.UnsavedChangesPopover reportKind="template" />
      <Visualization.SaveTemplateDialog />
    </Visualization>
  );
};

export default TemplateTypeSelector;
