import { cloneElement, JSX, useCallback, useLayoutEffect, useRef } from "react";
import { isForwardRef } from "react-is";

interface Props {
  children: JSX.Element;
  onResize: (rect: DOMRectReadOnly) => void;
}

export function ResizeObserverWrapper({ children, onResize }: Props) {
  const ref = useRef(null);
  if (!isForwardRef(children)) {
    throw new Error("ResizeObserverWrapper children must be a forward ref");
  }

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      if (entries[0]?.contentRect) {
        onResize(entries[0].contentRect);
      }
    },
    [onResize]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const ro = new ResizeObserver(handleResize);
    ro.observe(ref.current);

    return () => {
      ro.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {cloneElement(children, {
        ref: (r: never) => {
          ref.current = r;
        },
      })}
    </>
  );
}
