import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { BuildPivotReportRequest } from "../../../../../api/biApi.types";
import { selectCacheSessionId, selectCurrentReport, selectSessionId } from "../../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../../store/devToolsSlice";
import { BuilderContext } from "../../types";

export default function useRequestConfigBuilder() {
  const { conditionsArea, rowsArea, columnsArea, valuesArea, sortArea, settingsArea } = useContext(BuilderContext);

  const report = useSelector(selectCurrentReport);

  const isQueryLoggingActive = useSelector(selectLogQueries);
  const sessionId = useSelector(selectSessionId);
  const cacheSessionId = useSelector(selectCacheSessionId);

  const requestConfig = useMemo((): BuildPivotReportRequest => {
    const request: BuildPivotReportRequest = {
      conditions: conditionsArea.values.map((c) => ({ ...c.config, invalid: c.invalid })),
      rows: rowsArea.values.map((v) => v.config),
      columns: columnsArea.values.map((v) => v.config),
      values: valuesArea.values.map((v) => v.config),
      sort: sortArea.values.map((s) => s.config),
      settings: cloneDeep(settingsArea.settings),
      reportId: report?.reportId ?? "",
      sessionId,
      cacheSessionId,
      useQueryLogging: isQueryLoggingActive === true,
      withDrilldown: true,
    };

    return request;
  }, [
    conditionsArea.values,
    rowsArea.values,
    columnsArea.values,
    valuesArea.values,
    sortArea.values,
    settingsArea.settings,
    report?.reportId,
    sessionId,
    cacheSessionId,
    isQueryLoggingActive,
  ]);

  return { requestConfig };
}
