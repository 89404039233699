import { Divider, Stack } from "@mui/material";
import { JSX } from "react";
import { useLocalization } from "../../../hooks/useLocalization";
import PartContainer from "../PartContainer";

interface Props {
  parameters: JSX.Element;
  preview: JSX.Element;
  actionPanel: JSX.Element;
}

export default function ViewContainer({ parameters, preview, actionPanel }: Props) {
  const { common: locale } = useLocalization();

  return (
    <Stack flexDirection="row" flex={1}>
      {parameters}
      <Divider orientation="vertical" />
      <Stack flex={1}>
        <PartContainer
          sx={{ borderRight: "unset", p: "1.3rem", pt: 2, height: "100%" }}
          containerSx={{ position: "relative", gap: "1rem" }}
          caption={locale.report_preview_label}
          captionVariant="subtitle2"
          actions={actionPanel}
        >
          {preview}
        </PartContainer>
      </Stack>
    </Stack>
  );
}
