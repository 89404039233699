import { Box, Grid2, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { JSX, PropsWithChildren } from "react";
import HorizontalFill from "../../../shared/components/HorizontalFill";

interface Props {
  caption: string | JSX.Element;
  captionVariant?: Variant;
  actions?: JSX.Element;
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
}

export const PartContainer = (props: PropsWithChildren<Props>) => {
  const { children, caption, captionVariant, actions, sx, containerSx } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        borderRight: "1px solid #E0E0E0",
        py: "1.3rem",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          ...containerSx,
        }}
      >
        <Grid2 container alignItems={"center"} width={"100%"}>
          <Typography variant={captionVariant ?? "h6"}>{caption}</Typography>
          <HorizontalFill />
          <Stack>{actions}</Stack>
        </Grid2>
        {children}
      </Box>
    </Box>
  );
};

export default PartContainer;
