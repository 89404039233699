import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { Report, ReportConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { useEnhancedBiApiClientProvider } from "../../../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { AppDispatch } from "../../../../store/store";
import { setReport } from "../../../../store/thunks";

interface Props {
  report: Report;
  reportConfiguration?: ReportConfiguration;
  onClose: () => void;
}

const useSaveReportDialog = ({ report, reportConfiguration, onClose }: Props) => {
  const { updateReport, addReport, getEditReportUrl } = useEnhancedBiApiClientProvider();

  const navigate = useNavigate();
  const { report: locale } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const dispatch: AppDispatch = useDispatch();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const onSaveReport = useCallback(
    (name: string, groupId: string | undefined) => {
      if (reportConfiguration === undefined) return;
      const isNew = report.reportId === undefined;
      const newReport = cloneDeep(report);
      newReport.name = name;
      newReport.groupId = groupId;
      newReport.configuration = reportConfiguration;
      setSaving(true);
      const promise = isNew ? addReport(newReport) : updateReport(newReport);
      promise
        .then((result) => {
          if (result.success && result.data !== undefined) {
            sendNotification(locale.saved);
            if (isNew) {
              navigate(getEditReportUrl(result.data?.reportId));
            } else {
              dispatch(setReport(result.data));
            }
            onClose();
          } else {
            setError(locale.saving_error);
          }
        })
        .catch(() => {
          setError(locale.saving_error);
        })
        .finally(() => setSaving(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportConfiguration, updateReport, addReport, getEditReportUrl]
  );

  return {
    error,
    saving,
    onSaveReport,
  };
};

export default useSaveReportDialog;
